import AlertModal from 'components/AlertModal';
import { useAlertPlus } from 'hooks';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ApiService } from 'services';
import RemitosForm from './RemitosForm';
import { getErrorMessage } from 'utils/errors';

const initialValues = {
  mesPresentacion: '',
  nroOS: '',
};

function Create() {
  const [data, setData] = useState(initialValues);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, message, alertCallback, alert] = useAlertPlus('');

  const submit = async () => {
    try {
      setIsLoading(true);

      const res = await ApiService.post('remitos', data);

      if (res.status !== 'ok') {
        if (res.message) {
          alert(res.message);
          return;
        }

        alert('Se encontró un problema al intentar generar el remito. Por favor, intente nuevamente.');
        return;
      }

      if (!res.payload.length) {
        alert('No se encontraron solicitudes para procesar. No se ha generado ningún remito.');
        return;
      }

      let alertRes = 'Se ha generado ' + res.payload.length + ' remito(s) con éxito: ';

      let i = 0;
      for (const remito of res.payload) {
        i++;
        alertRes += 'El numero del remito ' + i + ' es: ' + remito.numeroRemito + '. ';
      }

      alert(alertRes, () => {
        if (i > 0) {
          navigate('/admin/remitos');
          return;
        }

        setIsLoading(false);
      });
    } catch (err) {
      alert(`Se encontró un problema desconocido al intentar generar el remito: ${ getErrorMessage(err) }`);
      setIsLoading(false);
    }
  };

  return (
    <>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <RemitosForm
              title="Nuevo remito"
              data={data}
              setData={ setData }
              isLoading={ isLoading }
              onSubmit={ submit }
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Create;
