import React, { useEffect, useState } from 'react'

import { ApiService } from 'services'

import { Col, FormGroup, Input, Row } from 'reactstrap'

import CollapsibleCard from '../../components/CollapsibleCard'

function RemitosFilters(props) {
  const { changeFilter } = props;

  const [insurers, setInsurers] = useState([])

  useEffect(() => {
    ApiService.getAll('insurers')
    .then(res => {
      if (!res.status || res.status !== 'ok') {
        return
      }

      setInsurers(res.payload)
    })
    .catch(err => {
      alert('Ha ocurrido un error al obtener el listado de Obras Sociales, temporalmente no se podrá filtrar por obra social.')
      console.log(err)
    })
  }, [])

  return (
    <CollapsibleCard title="Filtros">
      <Row>
        <Col sm={4}>
          <FormGroup>
            <label>Número de Remito</label>
            <Input type="text" onChange={ (event) => changeFilter('numeroRemito', event.target.value) } />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label>Obra Social</label>
            <Input type="select" onChange={ (event) => changeFilter('ooss_id', event.target.value) } defaultValue="any">
              <option value="any">Todas</option>
              {
                insurers && Array.isArray(insurers)
                ? insurers.map(insurer => <option key={'insurer_' + insurer.id} value={ insurer.id }>{ insurer.codigo }</option>)
                : null
              }
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </CollapsibleCard>
  )
}

export default RemitosFilters
