import React from 'react';
import { useUser } from 'context';
import Admin from 'layouts/Admin';
import Auth from 'layouts/Auth';
import { Navigate, useRoutes } from 'react-router-dom';
import Login from 'views/Login';
import RecoverPassword from 'views/RecoverPassword';
import Password from 'views/Password';
import adminRoutes from 'admin_routes';

export default function Router() {
  const user = useUser();
  const isAuthed = !!user.getUser();

  return useRoutes([
    {
      path: '/admin',
      element: isAuthed ? <Admin /> : <Navigate to="/auth/login" />,
      children: adminRoutes.map(route => {
        const Component = route.component;

        return {
          path: route.path.substring(1),
          element: <Component />,
        };
      }),
    },
    {
      path: '/auth',
      element: <Auth />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'change-password', element: <Password /> },
        { path: 'recover-password', element: <RecoverPassword /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/auth/login" />,
    },
  ]);
}
