/* eslint-disable */
// @ts-nocheck
import { RemitosStats, getRemitosStats } from 'features/reports/reportsAPI';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const colors = ['#FCFC62', '#424242', '#aab34e', '#4A6FA5', '#ffd582', '#F49097', '#f7905c', '#ed6a58', '#de425b',
'#93B7BE', '#D5C7BC', '#785964', '#55D6C2'];

const insurers = {
  '22': 'COMEI',
  '26': 'AMFFA',
  '36': 'Avalian',
  '55': 'Medife',
  '57': 'Swiss',
  '66': 'Sancor',
  '74': 'Federada',
  '77': 'OSPe',
};

export default function DeliveryNoteSummary() {
  const [data, setData] = useState<RemitosStats>([]);

  useEffect(()=>{
    getRemitosStats()
      .then(stats => setData(stats))
      .catch((err: any) => {
        alert('Se produjo un error al intentar recuperar la información de la gráfica. ' +
            'Por favor, intente nuevamente más tarde',
        );
        console.error('Reports', 'Error inesperador al intentar recuperar la gráfica');
        console.info(err);
      });
  }, []);

  return (
    <div className='content' style={{ marginBottom: '1rem' }}>
      <Row>
        <Col>
          <Card className='card-user'>
            <CardBody>
              <h3>Estadísticas remitos</h3>
              <strong>Cantidad de solicitudes por remito por mes</strong>
              <Row className="pt-4 pb-4">
                <Col>
                  <ResponsiveContainer height={600} width="100%">
                    <LineChart data={ data }>
                      <XAxis dataKey="name"/>
                      <YAxis/>
                      <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                      {
                        Array.isArray(data) && data.length && Object.keys(data[0]).map((key, idx) => {
                          if (key != 'name') {
                            const tmp = key.split('-');
                            const name = `${ insurers[tmp[0]] } - Dígito ${ tmp[1] }`;
                            return <Line
                              key={ key }
                              type="monotone"
                              dataKey={ key }
                              stroke={ colors[idx] }
                              label={ name }
                            />;
                          }

                          return null;
                        })
                      }
                      <Legend verticalAlign="bottom" height={36}/>
                      <Tooltip />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
