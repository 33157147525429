import React, { useEffect } from 'react'
import { Button, Col, Form, Input, Row } from 'reactstrap'
import DatePicker from 'components/DatePicker'
import { useForm } from 'hooks'
import { useUser } from 'context/UserContext'

export default function BSOForm (props) {

  const { isLoading, onSubmit, ooss, setData } = props

  const [values, handleChange, setValues] = useForm({})

  const { getDelegation } = useUser();

  useEffect(() => {
    setValues({ delegacion: getDelegation() || 0 })
  }, [])

  useEffect(() => {
    if (setData) {
      setData(values)
    }
  }, [values, setData])

  const submit = () => {
    if (onSubmit) {
      return onSubmit()
    }
  }

  return (
    <>
      <Form>
        <h6 className='heading-small text-muted mb-4'>Complete los datos</h6>
        <div className="pl-lg-4">
          <Row className='form-group mb-4'>
            <Col lg='6'>
              <label className='form-control-label'>Mes de presentación</label>
              <DatePicker
                className={`form-control form-control-alternative`}
                name='mesPresentacion'
                onChange={ (val) => setValues({ mesPresentacion: val }) }
                disabled={isLoading}
              />
            </Col>
            <Col lg='6'>
              <label className='form-control-label'>Obra Social</label>
              <Input
                type='select'
                value={values.nroOS || 'DEFAULT'}
                name='nroOS'
                className={`form-control-alternative`}
                disabled={isLoading}
                onChange={ handleChange }
              >
                <option disabled value='DEFAULT' hidden>Seleccione una obra social</option>
                {
                  ooss.map(item => <option key={item.id} value={item.numero}>{item.codigo}</option>)
                }
              </Input>
            </Col>
          </Row>
        </div>
        <h6 className='heading-small text-muted mb-4'>Información de la obra social</h6>
        <div className="pl-lg-4">
          <Row className='form-group mb-2'>
            <Col lg='6'>
              <label className='form-control-label'>Número obra social</label>
              <Input
                className='form-control-alternative'
                value={values.nroOS || ''}
                disabled
              />
            </Col>
            <Col lg='6'>
              <label className='form-control-label'>Delegación</label>
              <Input
                className='form-control-alternative'
                name='delegacion'
                value={values.delegacion || '0'}
                disabled
              />
            </Col>
          </Row>
        </div>
        <Row>
          <div className="update ml-auto mr-auto pt-4">
            <Button
              className='btn-round'
              color='success'
              title='Gnerar pedidos de presentacion'
              disabled={isLoading}
              onClick={() => submit()}
            >
              Procesar
            </Button>
          </div>
        </Row>
      </Form>
    </>
  )
}