import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, FormGroup, Input, Button } from "reactstrap"
import { ApiService } from 'services'
import { useAlertPlus } from 'hooks'
import AlertModal from 'components/AlertModal'

function Comments(props) {
  const { requestId } = props

  const [ formData, setFormData ] = useState({
    text: ''
  })
  const [ commentsList, setCommentsList ] = useState([])

  const [ isLoading, setLoading ] = useState(true)
  const [ showCreateForm, setShowCreateForm ] = useState(false)
  const [ alertShow, alertMessage, alertCallback, alert ] = useAlertPlus('')

  const formRef = useRef(null)

  const handleInputChange = (text) => {
    setFormData({
      ...formData,
      text
    })
  }

  const submitComment = () => {
    setLoading(true)

    ApiService.post(`requests/${ requestId }/comments`, {
      request_id: requestId,
      text: formData.text
    })
    .then(res => {
      if (!res || res.status !== 'ok' || !res.payload?.id) {
        console.log(res)
        alert('Se produjo un error mientras se intentaba enviar el comentario. Por favor, intente nuevamente más tarde.')
        setLoading(false)
        return
      }

      setShowCreateForm(false)

      setFormData({
        ...formData,
        text: ''
      })

      setCommentsList([
        ...commentsList,
        res.payload
      ])
    })
    .catch(err => {
      console.log(err)
      alert('Se produjo un error mientras se intentaba enviar el comentario. Por favor, intente nuevamente más tarde.')
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const deleteComment = (id) => {
    setLoading(true)

    ApiService.delete(`requests/${ requestId }/comments`, id)
    .then(res => {
      if (!res || res.status !== 'ok') {
        console.log(res)
        setLoading(false)
        alert('Se produjo un error mientras se intentaba eliminar el comentario. Por favor, intente nuevamente más tarde.')
        return
      }

      setCommentsList(commentsList.filter(comment => comment.id !== id))
    })
    .catch(err => {
      console.log(err)
      alert('Se produjo un error mientras se intentaba eliminar el comentario. Por favor, intente nuevamente más tarde.')
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const showComment = () => {
    setShowCreateForm(true)

    setTimeout(() => {
      window.scrollTo({
        top: formRef.current.offsetTop,
        behavior: 'smooth'
      })
    }, 100)
  }

  const cancelComment = () => {
    setFormData({
      ...formData,
      text: ''
    })

    setShowCreateForm(false)
  }

  useEffect(() => {
    ApiService.getAll(`requests/${ requestId }/comments`)
    .then(res => {
      if (!res || res.status !== 'ok') {
        console.log(res)
        return
      }

      setCommentsList(res.payload)
    })
    .catch(console.log)
    .finally(() => {
      setLoading(false)
    })
  }, [requestId])

  return (
    <div ref={ formRef }>
      <Row>
        <Col sm={ 12 }>
          <hr className="my-4" />
        </Col>
        <Col sm={ 9 }>
          <h6 className="heading-small text-muted mb-4">Comentarios internos</h6>
          <div className="pl-lg-4 mb-4">
            {
              commentsList && Array.isArray(commentsList)
              ?  commentsList.map(comment =>
                  <Row className="d-flex mb-4" style={{ lineHeight: '1rem' }} key={ `comment_${ comment.id }` }>
                    <Col style={{ flexGrow: 0 }}>
                      <Button type="button" size="sm" className="btn-round" color="warning" onClick={ () => deleteComment(comment.id) }>
                        <i className="fa fa-trash-alt"></i>
                      </Button>
                    </Col>
                    <Col>
                      { new Date(comment.createdAt).toLocaleString('es')}<br />
                      <small className="text-muted">{ comment.author?.firstName ?? '' } { comment.author?.lastName ?? '' }</small><br />
                    </Col>
                    <Col style={{ whiteSpace: 'pre-line', lineHeight: '1.5rem' }}>{ comment.text }</Col>
                  </Row>
                )
              : null
            }
          </div>
        </Col>
        <Col sm={ 3 }>
          <Button
            className="btn-round"
            color="muted"
            size="md"
            onClick={ () => showComment() }
            disabled={ isLoading }
          >
            Comentar Solicitud
          </Button>
        </Col>
      </Row>
      <Row className={ !showCreateForm ? 'hidden' : '' }>
        <Col>
          <FormGroup>
            <Input
              id="text"
              name="text"
              type="textarea"
              value={ formData.text }
              className="form-control-alternative"
              placeholder="Agregar comentario"
              rows="4"
              onChange={ event => handleInputChange(event.target.value) }
              disabled={ isLoading }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className={ !showCreateForm ? 'hidden' : 'mb-4' }>
        <div className="update ml-auto mr-auto">
          <Button
            className="btn-round"
            color="success"
            size="md"
            onClick={ () => submitComment() }
            disabled={ isLoading }
          >
            Aceptar
          </Button>
          <Button
            className="btn-round"
            color="error"
            size="md"
            onClick={ () => cancelComment() }
            disabled={ isLoading }
          >
            Cancelar
          </Button>
        </div>
      </Row>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </div>
  )
}

export default Comments