import React, { ChangeEvent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { ParsedRequest } from 'views/Requests/Edit';
import DatePicker from 'react-datepicker';
import NewState from './NewState';
import { SetFunction } from 'hooks';
interface MiscEditorProps {
  request: ParsedRequest;
  isLoading: boolean;
  setRequest: (value: ParsedRequest) => void;
  updateData: Partial<ParsedRequest>;
  setUpdateData: (value: Partial<ParsedRequest>) => void;
  alertError: SetFunction;
}

function MiscEditor(props: MiscEditorProps) {
  const { isLoading, request, setRequest, updateData, setUpdateData, alertError } = props;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    const name = target.name;

    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'datepicker':
        value = target.value as unknown as Date;
        break;
      default:
        value = target.value;
    }

    setUpdateData({
      ...updateData,
      [name]: value,
    });

    setRequest({
      ...request,
      [name]: value,
    } as ParsedRequest);
  };

  return (
    <div className="pl-lg-4">
      <FormGroup>
        <NewState request={request}
          updateRequest={setRequest}
          alertError={alertError}/>
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">Fecha de prescripción médica</label>
        <DatePicker
          className="form-control form-control-sm"
          locale='es'
          selected={ request?.fecha_prescripcion_medica || new Date() }
          onChange={ value => handleInputChange({
            target: {
              type: 'datepicker',
              name: 'fecha_prescripcion_medica',
              // @ts-ignore
              value,
            } })
          }
          dateFormat="dd/MM/yyyy"
          disabled={ isLoading }
        />
      </FormGroup>
      <FormGroup>
        <label className='form-control-label'>N° Mat. Profesional solicitante</label>
        <p>{ request?.matricula_profesional_solicitante || '-' }</p>
      </FormGroup>
      <FormGroup>
        <Label className='form-control-label' for="diagnostico">Diagnóstico</Label>
        <Input
          id="diagnostico"
          name="diagnostico"
          type="textarea"
          value={ request?.diagnostico ?? '' }
          onChange={ handleInputChange }
          disabled={ isLoading }
          required
        />
      </FormGroup>
    </div>
  );
}

export default MiscEditor;
