import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap'
import Form from './Form'
import { useAlertPlus } from '../../../hooks'
import AlertModal from '../../../components/AlertModal'
import { ApiService } from 'services'

function View () {
  const [ data, setData ] = useState({})

  const [ isLoading, setIsLoading ] = useState(false)
  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')

  const { id } = useParams()

  useEffect(() => {
    setIsLoading(true)

    ApiService.getOne(`matriculates/profiles`, id)
    .then(res => {
      if (res.status !== 'ok') {
        alert('Ha ocurrido un error al recuperar la solicitud, por favor intente nuevamente.')
        return
      }

      setData(res.payload)
    })
    .catch(err => {
      alert('Ha ocurrido un error al procesar la solicitud, por favor intente nuevamente.')
      console.error(err)
    })
    .finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Solicitud</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/matriculates/requests'>
                <Button color="primary" size="md">
                  Volver
                </Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              data={ data }
              setData={ setData }
              isLoading={ isLoading }
            />
          </CardBody>
        </Card>
      </Container>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
    </>
  )
}

export default View
