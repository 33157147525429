import { useState } from 'react'

export const useAlertPlus = (initialMessage) => {
  const [state, setState] = useState(false)
  const [message, setMessage] = useState(initialMessage)
  const [callback, setCallback] = useState(() => () => setState(false))

  const set = (newMessage, newCallback = null) => {
    setMessage(newMessage)
    setState(true)

    if (newCallback !== null) {
      setCallback(() => () => {
        setState(false)
        newCallback()
      })
    }
  }

  return [
    state,
    message,
    callback,
    set
  ]
}