import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledCollapse } from "reactstrap"

function AlertError (props) {
  const { isOpen, message, details, onClose } = props

  const close = () => {
    return onClose()
  }
  return (
    <>
      <Modal isOpen={isOpen} className='modal-dialog-centered'>
        <ModalHeader>
          Error
        </ModalHeader>
        <ModalBody>
          { message }
          {
            details
              ? <div className="d-flex flex-column">
                  <div className="w-100 text-right">
                    <button
                      id="detailsToggler"
                      className="btn btn-sm btn-link"
                    >
                      Ver detalles
                    </button>
                  </div>
                  <UncontrolledCollapse toggler="#detailsToggler">
                    <pre className="d-block w-100 p-2" style={{ backgroundColor: '#fafafa' }}>
                      {
                        typeof details === 'object'
                        ? JSON.stringify(details)
                        : details
                      }
                    </pre>
                  </UncontrolledCollapse>
                </div>
                : null
          }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => close()}>Cerrar</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AlertError