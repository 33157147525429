import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from 'services'
import OOSSForm from './OOSSForm'

const initialValues = {
  nombre: '',
  codigo: '',
  cuit: '',
  domicilioFiscal: '',
  situacioniva: '',
  razonSocial: '',
  isEnabled: false,
  isEnabledApp: false,
  presentsBill: false
}

const initialFieldClass = initialValues

function Edit () {

  const { id } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState(initialValues)
  const [fieldClass, setFieldClass] = useState(initialFieldClass)
  const [isValidated, setIsValidated] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (id) {
      ApiService.getOne('ooss', id)
        .then(res => {
          setData(res)
          setIsLoading(false)
        })
    }
  }, [id])

  const submit = async () => {
    const isValidated = true // validate()
    if (!isValidated) {
      return setIsValidated(false)
    }

    await ApiService.update('ooss', id, data)

    navigate('/admin/obras-sociales', {
      state: { success: true }
    })
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            {
                !isLoading &&
                <OOSSForm
                  title={data.nombre}
                  action='edit'
                  data={data}
                  setData={ setData }
                  isValidated={isValidated}
                  setFieldClass={ setFieldClass }
                  fieldClass={fieldClass}
                  onSubmit={ submit }
              />
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Edit
