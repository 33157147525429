import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Card, CardHeader, CardFooter } from 'reactstrap'
import { UserContext } from 'context'
import { ApiService } from 'services'
import Header from '../../components/Headers/Header'
import Pagination from '../../components/Pagination'
import Filters from './Filters'
import Search from './Search'
import Table from './Table'
import { useUser } from 'context/UserContext'

function List () {
  const { is, listOptions, setListOptions } = useUser();

  const [isLoading, setIsLoading] = useState(true)

  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)

  const [delegationsFilter, setDelegationsFilter] = useState(false)

  const initialOptions = listOptions?.matriculates ? listOptions.matriculates : {
    sort: {
      field: 'id',
      dir: 1
    },
    page: 1,
    limit: 50,
    search: ''
  }

  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState(initialOptions)

  const fetchData = async (newOptions) => {
    // Merge de las nuevas opciones con las viejas
    const options = Object.assign({}, currentOptions, newOptions)

    setIsLoading(true)

    // Hacemos la petición
    const res = await ApiService.getAll('matriculates', options)

    // Guardamos las opciones
    setCurrentOptions(options)
    setListOptions({
      ...listOptions,
      matriculates: options
    })

    // Recuperamos algunos datos de los resultados
    const { rows, count } = res.payload
    setData(rows)
    setTotal(count)

    // Terminamos la carga
    setIsLoading(false)
  }

  useEffect(() => {
    // Admins, auditores sede y facturación pueden filtrar por delegación
    setDelegationsFilter(is([1, 3, 4]))

    fetchData()
  }, [])

  const changePage = (page) => {
    fetchData({
      page
    })
  }

  const changeOrder = (field, dir) => {
    fetchData({
      sort: {
        field,
        dir
      }
    })
  }

  const changeFilter = (key, value) => {
    fetchData({
      [key]: value
    })
  }

  const search = (query) => {
    fetchData({
      search: query
    })
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Search onSearch={ search } currentQuery={ currentOptions.search } />
            <Filters changeFilter={ changeFilter } delegaciones={ delegationsFilter } currentOptions={ currentOptions } />

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col md='8'>
                    <h3 className="mb-0">Lista de Matriculados</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table id="matriculates-table" items={ data } isLoading={ isLoading } changeOrder={ changeOrder } currentOrder={ currentOptions.sort } />
              <CardFooter className="py-4">
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={6} className="d-flex justify-content-center d-sm-block">
                    <Pagination page={ currentOptions?.page } count={ total } onChangePage={ changePage } limit={ currentOptions?.limit } />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default List
