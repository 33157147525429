import Insurers from 'components/Config/Insurers';
import Practices from 'components/Config/Practices';
import Settings from 'components/Config/Settings';
import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

export default function Config() {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Settings loading={ loading } setLoading={ setLoading } />
            <Practices loading={ loading } setLoading={ setLoading } />
            <Insurers loading={ loading } setLoading={ setLoading } />
          </Col>
        </Row>
      </Container>
    </>
  );
}
