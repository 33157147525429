import React from "react"

import {  Container } from "reactstrap"

function AdminNavbar () {
  return (
    <>
      <div className='navbar-top'>
        <Container className="px-2 py-3 auth-logo__container">
          <img
            className='auth-logo__img'
            alt="..."
            src={require("assets/img/brand/logo.png")}
          />
        </Container>
      </div>
    </>
  )
}

export default AdminNavbar
