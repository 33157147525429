import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Container, Row, Col, CardHeader, UncontrolledAlert } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ApiService } from 'services'
import BSOTable from './BSOTable'
import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import Header from 'components/Headers/Header'
import { useUser } from 'context/UserContext'

export default function BSOList (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [currentOptions, setCurrentOptions] = useState({})
  const [success, setSuccess] = useState(false)
  const replace = useNavigate()

  const location = useLocation()
  const user = useUser()

  const tableFields = [
    { name: 'N°', field: 'id' },
    { name: 'Matriculado', field: 'matriculate' },
    { name: 'Periodo', field: 'monthPeriod' },
    { name: 'Importe', field: 'amount' },
    { name: 'Estado', field: 'status' }
  ]

  const options = {
    pending: 'Pendiente',
    presented: 'Presentado',
    observed: 'Observado',
    approved: 'Aprobado'
  }
  
  const filterFields = [
    { name: 'Estado', field: 'status', type: 'select', selectOptions: options, defaultKey: 'presented' }
  ]

  useEffect(() => {
    fetchData({ page: 1, status: 'presented' })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = (async (options) => {
    setIsLoading(true)
    const currentOpt = { ...currentOptions, ...options }
    const res = await ApiService.getAll('submission-orders', { ...currentOpt })
    const { rows, count } = res
    setCurrentOptions(currentOpt)
    setData(rows)
    setTotal(count)
    setIsLoading(false)
  })

  const filter = async ({ key, value }) => {
    await fetchData({ [key]: value })
  }

  const changePage = async (page) => {
    await fetchData({ page })
    setCurrentPage(page)
  }

  useEffect(() => {
    if (location.state) {
      const { success } = location.state
      if (success !== undefined) {
        replace(location.pathname, {state: {}})
        setSuccess(success)
      } else {
        setSuccess(undefined)
      }
    }
  }, [replace, location.state, location.pathname])

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            {/* Filters */}
            <Filters
              fields={filterFields}
              onFilter={ filter }
            />
            {/* End filters */}
            <Card className='shadow'>
              <CardHeader className='border-0'>
                {
                  success &&
                  <UncontrolledAlert color="success">
                    <strong>Éxito!</strong> La operación se realizó correctamente.
                  </UncontrolledAlert>
                }
                <Row className='align-items-center'>
                  <Col md='8'>
                    <h3>Lista de Pedidos de Presentación de Facturas</h3>
                  </Col>
                  {
                    user.is([1, 4])
                      ? <Col md='4' className='text-right'>
                          <Link to='/admin/pedidos-presentacion/nuevo'>
                            <Button color='primary' className='btn btn-md'>
                              Generar pedidos
                            </Button>
                          </Link>
                        </Col>
                      : null
                  }
                </Row>
              </CardHeader>
              <CardBody className='p-0'>
                <BSOTable
                  fields={tableFields}
                  items={data}
                  isLoading={isLoading}
                />
              </CardBody>
              <CardFooter className='py-4'>
                {/* Pagination */}
                <Pagination
                  page={currentPage}
                  count={total}
                  onChangePage={ changePage }
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}