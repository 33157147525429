import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table } from 'reactstrap';
import {
  InsurerPractices,
  PracticeData,
  PracticePayload,
  Specialty,
  getPracticeConfig,
  getSpecialties,
  removePracticeConfig,
  setPracticeConfig } from 'features/config/configAPI';

interface ModalData extends PracticeData {
  insurer_id: number;
  practices: {
    id: number;
    name: string;
  }[]
  filter: number;
  filterValue: number;
}

interface PracticesProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export default function Practices(props: PracticesProps) {
  const { loading, setLoading } = props;

  const [practices, setPractices] = useState<InsurerPractices>([]);
  const [specialties, setSpecialties] = useState<Specialty[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<ModalData>();

  useEffect(() => {
    getPracticeConfig()
      .then(data => {
        if (!data) {
          return;
        }
        setPractices(data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    getSpecialties()
      .then(data => {
        if (!data) {
          return;
        }

        setSpecialties(data);
      });
  }, []);

  const handleEditPractice = (insurer: number, data: PracticePayload) => {
    // Seteamos el tipo de filtro
    let filter = 0; // Ninguno
    if (data.hasOwnProperty('common') && !data.common) {
      filter = -1; // Oculto
    } else if (!!data?.specialty) { // Especialidad
      filter = 1;
    } else if (!!data?.orientation) { // Orientación
      filter = 2;
    }

    // Seteamos el valor del filtro
    let filterValue = 0;
    if (data?.specialty?.id) {
      filterValue = data.specialty.id;
    } else if (data?.orientation?.id) {
      filterValue = data.orientation.id;
    }

    // Recuperamos las prácticas de la obra social
    const insurerPractices = practices.find(i => i.id === insurer);
    if (!insurerPractices) {
      return;
    }

    // Formateamos el listado de prácticas de la obra social
    let practicesList = insurerPractices.practices.map(ps => ({ id: ps.id, name: ps.name })) ?? [];

    // Quitamos los duplicados
    practicesList = practicesList.filter((p, idx) => practicesList.findIndex(c => c.id === p.id) == idx);

    // Guardamos toda la información necesaria para el modal
    setModalData({
      practice_id: data.id,
      insurer_id: insurer,
      specialty_id: data.specialty?.id,
      orientation_id: data.orientation?.id,
      common: data.common,
      code: data.code,
      practices: practicesList,
      filter,
      filterValue,
    });

    // Abrimos el modal
    setModalOpen(true);
  };

  const handleSavePractice = async () => {
    if (!modalData) {
      return;
    }

    let common: PracticeData['common'];
    if (modalData.filter === -1) { // Prácticas ocultas
      common = false;
    } else if (modalData.filter === 0) { // Prácticas comunies
      common = true;
    }

    const payload: PracticeData = {
      insurer_id: modalData.insurer_id,
      practice_id: modalData.practice_id,
      code: modalData.code,
      specialty_id: modalData.specialty_id,
      orientation_id: modalData.orientation_id,
      common,
    };

    await setPracticeConfig(payload);

    getPracticeConfig()
      .then(data => {
        if (!data) {
          return;
        }
        setPractices(data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    setModalOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFilterTypeChange = (newType: number) => {
    if (!modalData) {
      return;
    }

    const newModalData = Object.assign({}, modalData);

    if (newType === -1 || newType === 0) {
      newModalData['specialty_id'] = undefined;
      newModalData['orientation_id'] = undefined;
      newModalData['common'] = newType === 0;
    }

    newModalData['filter'] = newType;
    newModalData['filterValue'] = 0;

    setModalData(newModalData);
  };

  const handleFilterChange = (newFilter: number) => {
    if (!modalData) {
      return;
    }

    const newModalData = Object.assign({}, modalData);

    switch (modalData.filter) {
      case -1: // Oculta
        newModalData['specialty_id'] = undefined;
        newModalData['orientation_id'] = undefined;
        newModalData['common'] = false;

        newModalData['filterValue'] = 0;
        break;
      case 0: // Sin filtro
        newModalData['specialty_id'] = undefined;
        newModalData['orientation_id'] = undefined;
        newModalData['common'] = true;

        newModalData['filterValue'] = 0;
        break;
      case 1: // Especialidad
        newModalData['specialty_id'] = newFilter;
        newModalData['orientation_id'] = undefined;
        newModalData['common'] = undefined;

        newModalData['filterValue'] = newFilter;
        break;
      case 2:
        newModalData['specialty_id'] = undefined;
        newModalData['orientation_id'] = newFilter;
        newModalData['common'] = undefined;

        newModalData['filterValue'] = newFilter;
        break;
    }

    setModalData(newModalData);
  };

  const handleRemovePractice = async (insurerId: number, practiceId: number) => {
    await removePracticeConfig({
      insurer_id: insurerId,
      practice_id: practiceId,
    });

    getPracticeConfig()
      .then(data => {
        if (!data) {
          return;
        }
        setPractices(data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card className="shadow" style={{ marginBottom: '1rem' }}>
        <CardHeader style={{ paddingRight: '.5rem' }}>
          Prácticas
          <Button style={{ float: 'right' }} size="sm" color="primary">
            Agregar Práctica
          </Button>
        </CardHeader>
        <CardBody style={{ padding: 0 }}>
          <Table size="sm" style={{ maxWidth: '100%' }}>
            <thead>
              <tr>
                <th>Obra Social</th>
                <th>Práctica</th>
                <th>Nomenclador</th>
                <th>Filtro</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                practices.map((i, idx) => (
                  <Fragment key={ `practice_${ i.id }_${ i.practices[0].id }_${ idx }` }>
                    <tr>
                      <th rowSpan={ i.practices.length }>{ i.name }</th>
                      <td style={{ verticalAlign: 'middle' }}>{ i.practices[0].name }</td>
                      <td style={{ verticalAlign: 'middle' }}>{ i.practices[0].code }</td>
                      <td style={{ verticalAlign: 'middle', whiteSpace: 'normal' }}>
                        { i.practices[0].hasOwnProperty('common') && !i.practices[0].common ? 'Práctica Oculta' : '' }
                        { i.practices[0].specialty ? `Especialidad: ${ i.practices[0].specialty.name }` : '' }
                        { i.practices[0].orientation ? `Orientación: ${ i.practices[0].orientation.name }` : '' }
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Button
                          outline
                          color="primary"
                          disabled={ loading }
                          onClick={ () => handleEditPractice(i.id, i.practices[0]) }
                        >
                          <i className="fa fa-edit"></i>
                        </Button>
                        <Button
                          outline
                          color="warning"
                          disabled={ loading }
                          onClick={ () => handleRemovePractice(i.id, i.practices[0].id) }
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                    {
                      i.practices.slice(1).map((p, pdx) => (
                        <tr key={ `practice_${ i.id }_${ p.id }_${ idx + pdx }` }>
                          <td style={{ verticalAlign: 'middle' }}>{ p.name }</td>
                          <td style={{ verticalAlign: 'middle' }}>{ p.code }</td>
                          <td style={{ verticalAlign: 'middle', whiteSpace: 'normal' }}>
                            { p.hasOwnProperty('common') && !p.common ? 'Práctica Oculta' : '' }
                            { p.specialty ? `Especialidad: ${ p.specialty.name }` : '' }
                            { p.orientation ? `Orientación: ${ p.orientation.name }` : '' }
                          </td>
                          <td>
                            <Button
                              outline
                              color="primary"
                              disabled={ loading }
                              onClick={ () => handleEditPractice(i.id, p) }
                            >
                              <i className="fa fa-edit"></i>
                            </Button>
                            <Button
                              outline
                              color="warning"
                              disabled={ loading }
                              onClick={ () => handleRemovePractice(i.id, p.id) }
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </Fragment>
                ))
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal isOpen={ modalOpen } centered toggle={ handleCloseModal }>
        <ModalHeader toggle={ handleCloseModal }>
          Editar Práctica
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Práctica</Label>
              <Input type="select" disabled value={ modalData?.practice_id }>
                {
                  modalData?.practices.map(p => (
                    <option key={ `${ p.id }_${ modalData.specialty_id }` } value={ p.id }>
                      { p.name }
                    </option>
                  ))
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Nomenclador / Código</Label>
              <Input type="text" value={ modalData?.code } onChange={ event => {
                if (!modalData) {
                  return;
                }

                setModalData({ ...modalData, code: event.target.value });
              }}/>
            </FormGroup>
            <FormGroup>
              <Label>Tipo de Filtro</Label>
              <Input
                type="select"
                value={ modalData?.filter ? modalData?.filter : 0 }
                onChange={ event => handleFilterTypeChange(parseInt(event.target.value)) }
              >
                <option value="0">Ninguno</option>
                <option value="-1">Oculto</option>
                <option value="1">Especialidad</option>
                <option value="2">Orientación</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Filtros</Label>
              <Input
                type="select"
                disabled={ modalData?.filter === -1 || modalData?.filter === 0 }
                value={ modalData?.filterValue }
                onChange={ event => handleFilterChange(parseInt(event.target.value)) }
              >
                <option value="0">Ninguno</option>
                {
                  specialties.map(s => (
                    <option key={ s.id } value={ s.id }>
                      { s.descripcion }
                    </option>
                  ))
                }
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={ handleSavePractice }
            disabled={ loading }
            color="primary"
          >
            Guardar
          </Button>
          <Button
            onClick={ handleCloseModal }
            disabled={ loading }
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
