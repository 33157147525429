import React, { useEffect, useRef, useState } from 'react'
import { Input, Button } from "reactstrap"
import config from 'config';

const accept = ['application/pdf']

export default function (props) {
  const { required, disabled, name, value, onChange } = props

  const fileInput = useRef(null)

  const [file, setFile] = useState(null)
  const [url, setURL] = useState(null)
  const [sizeError, setSizeError] = useState(false)

  const select = () => {
    // Verificamos si hay archivos seleccionado
    if (!fileInput.current.files.length) {
      return
    }

    // Controlamos el tamaño del archivo
    if (fileInput.current.files[0].size > config.maxFileSize) {
        fileInput.current.value = ''
        setSizeError(true)
        return
    }

    setSizeError(false)

    // Si ya había una URL creada, la liberamos
    if (url) {
      URL.revokeObjectURL(url)
    }

    // Seleccionamos el primer archivo
    const newFile = fileInput.current.files[0]
    // Generamos una URL para la vista previa
    const newURL = URL.createObjectURL(newFile)

    setFile(newFile)
    setURL(newURL)

    if (!fileInput.current.classList.contains('hidden')) {
      fileInput.current.classList.add('hidden')
    }

    onChange(newFile)
  }

  const replace = () => {
    fileInput.current.click()
  }

  const remove = () => {
    fileInput.current.value = ''
    setFile(null)
    if (url) {
      URL.revokeObjectURL(url)
    }
    setURL(null)
    fileInput.current.classList.remove('hidden')
    onChange(null)
  }

  // Actualiza la url cuando se asigna archivos desde fuera
  // del componente
  useEffect(() => {
    if (!value || !value.path) {
      return
    }

    setURL(`${ config.baseUrl }/${ value.path }`)
    setFile(true)
    if (!fileInput.current.classList.contains('hidden')) {
      fileInput.current.classList.add('hidden')
    }
  }, [value])

  return (
    <div className="d-block">
      <Input
      innerRef={ fileInput }
      type="file"
      accept={ accept.join(', ') }
      onChange={ () => select() }
      required={ required }
      name={ name }
      disabled={ disabled }
      className={ 'overflow-hidden' } />
      {
        file
        ? <div className="mb-2">
            <Button color="primary" disabled={ disabled } onClick={ () => window.open(url, "_blank") }>Ver adjunto</Button>
            <Button color="warning" disabled={ disabled } onClick={ () => replace() }>Reemplazar</Button>
            <Button color="white-50" disabled={ disabled } onClick={ () => remove() }>Eliminar</Button>
          </div>
        : null
      }
      <label className={`required-text ${ !sizeError ? 'hidden' : ''}`} style={{ marginTop: '0.25rem', fontSize: '80%' }}>El peso del archivo no debe superar los 3 MB</label>
    </div>
  )
}