import React from 'react'
import './Loader.sass'

// @component
export default function Loader (props) {

  const { className } = props

  const getClassName = () => {
    if (className) {
      return `loader ${className}`
    }
    return 'loader'
  }

  return (
    <>
      <div className={getClassName()} >
        <div />
        <div />
        <div />
        <div />
      </div>
    </>
  )
}