import React, { useEffect, useState } from "react"
import { Card, CardHeader, CardFooter, Container, Row, Button, UncontrolledAlert } from "reactstrap"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { ApiService } from 'services'

import Filters from "components/Filters"
import Pagination from 'components/Pagination'
import OOSSTable from "./OOSSTable"
import { flags } from "services/enabledFlags"

import { useUser } from "context/UserContext"

function OOSS () {
  const user = useUser()

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentOptions, setCurrentOptions] = useState({})
  const [total, setTotal] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    fetchData({ page: 1 })
  }, [])

  useEffect(() => {
    if (location.state) {
      const { success } = location.state
      if (success !== undefined) {
        setSuccess(success)
        navigate(location.pathname)
      }
    }
  }, [location])

  const fetchData = (async (options) => {
    setIsLoading(true)
    const currentOpt = { ...currentOptions, ...options }
    const res = await ApiService.getAll('ooss', {
      ...currentOpt,
      q: 'all',
      sort: {
        field: 'codigo',
        dir: 1
      }
    })
    const { rows, count } = res
    setCurrentOptions(currentOpt)
    setData(rows)
    setTotal(count)
    setIsLoading(false)
  })

  const filter = async ({ key, value }) => {
    await fetchData({ [key]: value })
  }

  const changePage = async (page) => {
    await fetchData({ page })
    setCurrentPage(page)
  }

  const tableFields = [
    { name: 'Código', field: 'codigo' },
    { name: 'Habilitada', field: 'isEnabled' },
    { name: 'App Móvil', field: 'isEnabledApp' },
    { name: 'CUIT', field: 'cuit' },
    { name: 'Fecha última modificación', field: 'updatedAt' },
  ]

  const filtersFields = [
    { name: 'Estado', field: 'isEnabled', type: 'select', selectOptions: flags },
    { name: 'Código', field: 'codigo', type: 'text' },
  ]

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            {/* Filters */}
            <Filters
              fields={filtersFields}
              onFilter={ filter }
            />
            {/* End filters */}
            <Card className="shadow">
              <CardHeader className="border-0">
                {
                  success &&
                  <UncontrolledAlert color='success'>
                    <strong>Éxito!</strong> La operación se realizó correctamente.
                  </UncontrolledAlert>
                }
                <Row className='align-items-center'>
                  <div className='col-8'>
                    <h3 className="mb-0">Lista de Obras Sociales</h3>
                  </div>
                  <div className='col-4 text-right'>
                    {
                      user.is([1, 4])
                      ? <Link to='/admin/obras-sociales/nueva'>
                          <Button color='primary' className='btn btn-md'>
                            Nueva obra social
                          </Button>
                        </Link>
                      : null
                    }
                  </div>
                </Row>
              </CardHeader>
              <OOSSTable
                service='obras-sociales'
                fields={tableFields}
                items={data}
                isLoading={isLoading}
              />
              <CardFooter className="py-4">
              <Pagination page={currentPage} count={total} onChangePage={ changePage } limit={ 10 } />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default OOSS
