import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import AdminFooter from 'components/Footers/AdminFooter';
import Sidebar from 'components/Sidebar/Sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Admin() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  });

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <AdminNavbar/>
        <Outlet />
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={true}/>
    </>
  );
}
