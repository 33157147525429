import config from 'config';
import React, { useState } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, InputGroup, Label } from 'reactstrap';

interface DeliveryNoteDetailsProps {
  alert: (message: string) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export default function DeliveryNoteDetails(props: DeliveryNoteDetailsProps) {
  const { alert, loading, setLoading } = props;

  const [detalleRemitoNumero, setDetalleRemitoNumero] = useState('');

  const generateDetalleRemito = async () => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      return;
    }

    setLoading(true);

    fetch(`${ config.baseUrl }/reports/detalle-remito/${ detalleRemitoNumero }`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    })
      .then( res => {
        switch (res.status) {
          case 200:
            return res.blob();
          case 403:
            alert('No tiene los permisos necesarios para realizar la operación');
            break;
          case 404:
            alert('No se pudo encontrar el remito indicado');
            break;
          case 501:
            alert('El remito indicado no pertenece a una obra social implementada');
            break;
          default:
            alert('Ocurrió un error impreviso cuando se intentó realizar la operación');
            console.error(res);
        }
      })
      .then( blob => {
        if (!blob) {
          return;
        }

        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      })
      .catch(err => {
        console.error(err);
        alert('Ocurrió un error de conexión cuando se intentó realizar la operación');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card className="shadow">
      <CardBody>
        <h3>Detalle Remito</h3>
        <small>Genera un archivo Excel donde se detalla de cada sesión rendida la fecha de prescripción,
          fecha de sesión, el número de afiliado, el nombre de afiliado, el número de matriculado, nombre
          de matriculado, código de prestacion y número de autorización. Únicamente están soportados <strong>
          SMG</strong> y <strong>OSPe</strong>
        </small>
        <FormGroup row>
          <Col sm={2}>
            <Label for="input-last-name">N° Remito</Label>
          </Col>
          <Col sm={4}>
            <InputGroup size="sm">
              <Input
                type="text"
                placeholder="# de Remito"
                value={ detalleRemitoNumero }
                onChange={ event => setDetalleRemitoNumero(event.target.value) }
                disabled={ loading }
              />
              <Button
                color="primary"
                size="sm"
                outline={ true }
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={ generateDetalleRemito }
                disabled={ loading }
              >
                Generar Detalle
              </Button>
            </InputGroup>
          </Col>
        </FormGroup>
      </CardBody>
    </Card>
  );
}
