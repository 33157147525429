import packageJson from '../package.json'

let env = 'prod'

if (process.env.REACT_APP_WS.indexOf('localhost') !== -1) {
  env = 'local'
} else if (process.env.REACT_APP_WS.indexOf('beta.cokiba.org.ar') !== -1) {
  env = 'beta'
} else if (process.env.REACT_APP_WS.indexOf('test.cokiba.org.ar') !== -1) {
  env = 'test'
}

export default {
  env,
  baseUrl: process.env.REACT_APP_WS,
  version: packageJson.version,
  maxFileSize: process.env.MAX_FILE_SIZE ? parseInt(process.env.MAX_FILE_SIZE) : 3145728
}