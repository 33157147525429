import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardFooter, Container, Row, Col, Button, UncontrolledAlert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApiService } from 'services';
import Filters from './Filters';
import Pagination from 'components/Pagination';
import RemitosTable from './RemitosTable';
import AlertModal from 'components/AlertModal';
import AlertError from 'components/AlertError';
import { useAlertPlus, useAlertError } from 'hooks';
import config from 'config';
import { useUser } from 'context/UserContext';
import { useAppLocation } from 'hooks/useAppLocation';

function Remitos() {
  const user = useUser();
  const location = useAppLocation();

  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState({
    generate: '1', // Información de remitos procesandose
    per_page: 25,
    page: 1,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(false);

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');
  const [showError, messageError, detailsError, callbackError, alertError] = useAlertError();

  const [disableGenerators, setDisableGenerators] = useState(false);

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  useEffect(() => {
    if (location.state) {
      const { success } = location.state;
      if (success !== undefined) {
        setSuccess(success);
      }
    }
  }, [location]);

  const fetchData = async (newOptions?: Record<string, unknown>) => {
    setIsLoading(true);

    // Merge de las nuevas opciones con las viejas
    const options = Object.assign({}, currentOptions, newOptions);

    const res = await ApiService.getAll('remitos', options);
    const { rows, count, generateFiles } = res;
    setData(rows);
    setTotal(count);

    if (!generateFiles) {
      setDisableGenerators(true);
    }

    setCurrentOptions(options);

    setIsLoading(false);
  };

  const changePage = async (page: number) => {
    await fetchData({ page });
    setCurrentPage(page);
  };

  const changeFilter = (key: string, value: unknown) => {
    fetchData({
      [key]: value,
    });
  };

  const handleProcessStart = async (type: string, deliveryNoteId: number) => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    setDisableGenerators(true);
    const res = await fetch(`${ config.baseUrl }/remitos/${ deliveryNoteId }/${ type }`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    const data = await res.json();

    setDisableGenerators(false);

    if (!data || data.status !== 'ok') {
      alert(`Se produjo un error al intentar generar el archivo ${ type }`);
      return;
    }

    alert(`El proceso de generación del archivo inició correctamente.\n` +
      `Total de archivos a procesar: ${ data.payload.total }`);

    await fetchData();
  };

  const handleSendRetry = async (deliveryNoteId: number) => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    setDisableGenerators(true);
    const res = await fetch(`${ config.baseUrl }/remitos/${ deliveryNoteId }/retry`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    const data = await res.json();

    setDisableGenerators(false);

    if (!data || data.status !== 'ok') {
      alert(`Se produjo un error al intentar reenviar el archivo`);
      return;
    }

    alert(`El proceso de reenvio del archivo inició correctamente`);
    await fetchData();
  };

  const handleSendStart = async (deliveryNoteId: number) => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    setDisableGenerators(true);
    const res = await fetch(`${ config.baseUrl }/remitos/${ deliveryNoteId }/send`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    if (res.status !== 200) {
      switch (res.status) {
        case 400:
          alert(`No se pudo enviar el archivo porque hubo un error en la petición`);
          break;
        case 403:
          alert(`No se pudo enviar el archivo porque el usuario no tiene los permisos necesarios`);
          break;
        case 501:
          alert(`No se pudo enviar el archivo porque la OOSS no tiene implementado el proceso`);
          break;
        default:
          console.error(res);
          alertError(`No se pudo enviar el archivo`, {
            status: `${ res.status }: ${ res.statusText }`,
            body: res.bodyUsed ? (await res.json()) : {},
          });
      }
      return;
    }

    const data = await res.json();

    setDisableGenerators(false);

    if (!data || data.status !== 'ok') {
      alertError(`Se produjo un error al intentar enviar el archivo`, data?.payload);
      return;
    }

    alert(`El proceso de envio del archivo inició correctamente`);
    await fetchData();
  };

  const handleSendStop = async (deliveryNoteId: number) => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    setDisableGenerators(true);
    const res = await fetch(`${ config.baseUrl }/remitos/${ deliveryNoteId }/stop`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });

    const data = await res.json();

    setDisableGenerators(false);

    if (!data || data.status !== 'ok') {
      alert(`Se produjo un error al intentar detener el envío`);
      return;
    }

    alert(`El proceso de envio se detuvo correctamente`);
    await fetchData();
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
          </div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Filters changeFilter={ changeFilter } />
            <Card className="shadow">
              <CardHeader className="border-0">
                {
                  success &&
                  <UncontrolledAlert color='success'>
                    <strong>Éxito!</strong> La operación se realizó correctamente.
                  </UncontrolledAlert>
                }
                <Row className='align-items-center'>
                  <Col md='8'>
                    <h3 className="mb-0">Lista de remitos generados</h3>
                  </Col>
                  {
                    user.is([1, 2, 3])
                      ? <Col md='4' className='text-right'>
                          <Link to='/admin/remitos/nuevo'>
                            <Button color='primary' className='btn btn-md'>
                              Generar remitos
                            </Button>
                          </Link>
                        </Col>
                      : null
                  }
                </Row>
              </CardHeader>
              <RemitosTable
                items={ data }
                isLoading={ isLoading }
                onProcessStart={ handleProcessStart }
                onSendRetry={ handleSendRetry }
                onSendStart={ handleSendStart }
                onSendStop={ handleSendStop }
                disableGenerators={ disableGenerators }
              />
              <CardFooter className="py-4">
                <Pagination page={ currentPage } count={ total } onChangePage={ changePage } limit={ 10 } />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
      <AlertError isOpen={ showError } message={ messageError } details={ detailsError } onClose={ callbackError } />
    </>
  );
}

export default Remitos;
