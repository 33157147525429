import React from 'react'
import { Table } from 'reactstrap'
import TableLoader from 'components/TableLoader'
import { Link } from 'react-router-dom'

export default function BSOTable (props) {
  const { fields, items, isLoading } = props

  return (
    <>
      <Table className='align-items-center table-flush' responsive>
        <thead className="thead-light">
          <tr>
            { fields.map(({ name, field }) => <th key={ field }>{ name }</th> ) }
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {
            !isLoading
            ? items.length
              ? items.map((row) => (
                <tr key={row.id}>
                  <td>#{row[fields[0].field]}</td>
                  <td>{row[fields[1].field] ? row[fields[1].field].matricula : '-'}</td>
                  <td>{`${row.monthPeriod}/${row.yearPeriod}`}</td>
                  <td>{row[fields[3].field]}</td>
                  <td>{row[fields[4].field]}</td>
                  <td>
                  {
                    row.status === 'Presentado'
                    ? <Link to={ `/admin/pedidos-presentacion/${row.id}/audit` } className="btn btn-round btn-primary btn-sm">
                        <i className="fa fa-edit"></i>
                      </Link>
                    : <Link to={ `/admin/pedidos-presentacion/${row.id}` } className="btn btn-round btn-primary btn-sm">
                        <i className="fa fa-eye"></i>
                      </Link>
                  }
                  </td>
                </tr>
              ))
              : <tr>
                  <td colSpan={ fields.length + 1 } className='text-center'>
                    <span>No se encontraron pedidos de presentación</span>
                  </td>
                </tr>
            : <tr>
                <td colSpan={fields.length + 1} className='text-center'>
                  <TableLoader />
                </td>
              </tr>
          }
        </tbody>
      </Table>
    </>
  )
}