import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { ParsedRequest } from 'views/Requests/Editor';
import config from 'config';
import { SetFunction } from 'hooks';
import { RawRequest } from '@cokiba/types';

interface NewSessionProps {
  request: ParsedRequest;
  updateRequest: (request: RawRequest) => void;
  alertError: SetFunction;
}

interface NewSessionData {
  request_id: number;
  date: Date;
  parsedDate: string;
  online: boolean;
  authorization_id?: string;
  preauthorization_id?: string;
  coinsurance: string | null;
  taxed: boolean | null;
  plan: string | null;
}

function NewSession(props: NewSessionProps) {
  const { request, updateRequest, alertError: setError } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [newSessionData, setNewSessionData] = useState<NewSessionData>({
    request_id: props.request.id,
    date: new Date(),
    parsedDate: new Date(Date.now()).toLocaleString().split(',')[0],
    online: false,
    coinsurance: '',
    taxed: false,
    plan: '',
  });

  useEffect(() => {
    if (request.sessionDates.length) {
      setNewSessionData({
        ...newSessionData,
        online: !!request.sessionDates[0].online_authorization,
        coinsurance: request.sessionDates[0].coinsurance ? request.sessionDates[0].coinsurance.toString() : null,
        taxed: request.sessionDates[0].gravado,
        plan: request.sessionDates[0].plan,
      });
    }
  }, [request.sessionDates]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.type === 'checkbox') {
      setNewSessionData({
        ...newSessionData,
        [event.target.name]: event.target.checked,
      });
    } else if (event.target.type === 'text') {
      setNewSessionData({
        ...newSessionData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeDate = (date: Date | null) => {
    const newDate = date ?? new Date();

    let parsedDate = newDate.getFullYear().toString();
    parsedDate += '-';
    parsedDate += newDate.getMonth() < 9
      ? (newDate.getMonth() + 1).toString().padStart(2, '0')
      : (newDate.getMonth() + 1).toString();
    parsedDate += '-';
    parsedDate += newDate.getDate() < 10
      ? newDate.getDate().toString().padStart(2, '0')
      : newDate.getDate().toString();

    setNewSessionData({
      ...newSessionData,
      date: newDate,
      parsedDate: parsedDate,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('accessToken');

      if (!token) {
        return;
      }

      if (newSessionData.online && !newSessionData.authorization_id?.replace(/\D+/g, '').trim()) {
        setError('Las sesiones con autorización online requieren un número de autorización');
      }

      if ((!newSessionData.online && request.ooss_id === 66) &&
        !newSessionData.authorization_id?.replace(/\D+/g, '').trim()) {
        setError('Las sesiones de Sancor sin autorización online únicamente pueden ser Formulario 4 y requieren' +
        'número de preautorización o formulario');
      }

      if (request.ooss_id === 57 && !newSessionData.coinsurance?.replace(/\D+/g, '').trim()) {
        setError('Las sesiones de Swiss requieren ingresar el monto del coseguro');
      }

      if (request.ooss_id === 66 && !newSessionData.plan?.trim()) {
        setError('Las sesiones de Sancor requieren ingresar el plan del afiliado');
      }

      const { parsedDate, ...data } = newSessionData;

      const response = await fetch(`${ config.baseUrl }/requests/${ request.id }/add_session`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          date: parsedDate,
        }),
      });

      if (response.status === 401 || response.status === 403) {
        setError('No se tienen los permisos necesarios para realizar la operación');
        return;
      }

      const body = await response.json();

      if (body.status !== 'ok') {
        if (body.message && body.error) {
          setError(body.message, body.error);
          return;
        } else if (body.message) {
          setError(body.message);
          return;
        }

        setError('Error desconocido intentando realizar la operación', response);
        return;
      }

      if (body.data) {
        updateRequest(body.data as RawRequest);
      } else {
        window.location.reload();
      }
    } catch (err) {

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form style={{ border: 'solid 1px #dadada', padding: '1rem', borderRadius: '.5rem' }}>
      <h3>Agregar Sesión</h3>
      <small>Únicamente obras sociales que autorización por sesión. Todas menos Avalian</small>
      <FormGroup>
        <Label>Fecha de Sesión</Label>
        <DatePicker
          className="form-control form-control-sm"
          locale='es'
          selected={ newSessionData.date }
          onChange={ handleChangeDate }
          dateFormat="dd/MM/yyyy"
          disabled={ isLoading || request.ooss_id === 36 }
        />
      </FormGroup>
      <FormGroup check>
        <Input
          type="checkbox"
          name="online"
          checked={ newSessionData.online }
          onChange={ handleChange }
          disabled
        />
        <Label>Autorización Online</Label>
      </FormGroup>
      <FormGroup>
        <Label>N° de Autorización</Label>
        <Input
          type="text"
          name="authorization_id"
          bsSize="sm"
          placeholder="N° de Autorización"
          onChange={ handleChange }
          disabled={ !newSessionData.online || isLoading || request.ooss_id === 36 }
          required={ newSessionData.online }
        />
      </FormGroup>
      <FormGroup>
        <Label>N° de Preautorización</Label>
        <Input
          type="text"
          name="preauthorization_id"
          bsSize="sm"
          placeholder="N° de preautorización"
          onChange={ handleChange }
          disabled={ newSessionData.online || request.ooss_id !== 66 || isLoading }
          required={ !newSessionData.online && request.ooss_id === 66 }
        />
        <FormText>Sólo formulario 4 de Sancor</FormText>
      </FormGroup>
      <FormGroup>
        <Label>Coseguro</Label>
        <Input
          type="text"
          name="coinsurance"
          bsSize="sm"
          placeholder="Coseguro"
          onChange={ handleChange }
          defaultValue="0.00"
          disabled={ request.ooss_id !== 57 || isLoading }
          required={ request.ooss_id === 57 }
        />
        <FormText>Sólo Swiss: Utilizar punto (.) como separador de decimales y no poner separador de miles</FormText>
      </FormGroup>
      <FormGroup check>
        <Input
          type="checkbox"
          name="taxed"
          onChange={ handleChange }
          checked={ !!newSessionData.taxed }
          disabled={ request.ooss_id !== 66 || isLoading }
        />
        <Label>Gravado</Label>
        <FormText>Sólo Sancor</FormText>
      </FormGroup>
      <FormGroup>
        <Label>Plan</Label>
        <Input
          type="text"
          name="plan"
          bsSize="sm"
          value={ newSessionData.plan ?? '' }
          disabled={ request.ooss_id !== 66 || isLoading || request.ooss_id === 66 }
          placeholder="Plan"
          onChange={ handleChange }
        />
        <FormText>Sólo Sancor: B300, S800V, D3500, étc</FormText>
      </FormGroup>
      <Button
        color="primary"
        disabled={ ![1, 2, 3, 4].includes(request.estado_id) || isLoading || request.ooss_id === 36 }
        size="sm"
        outline={ true }
        onClick={ handleSubmit }
        style={{ width: '100%' }}
      >
        Agregar Sesión
      </Button>
    </Form>
  );
}

export default NewSession;
