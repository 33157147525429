import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardFooter, Button } from 'reactstrap'
import { ApiService } from 'services'
import Header from '../../components/Headers/Header'
import Pagination from '../../components/Pagination'
import Filters from './Filters'
import Table from './Table'

function List () {

  const [isLoading, setIsLoading] = useState(true)

  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)

  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState({
    sort: {
      field: 'created_at',
      dir: 1
    },
    page: 1,
    search: ''
  })

  const fetchData = async (newOptions) => {
    // Merge de las nuevas opciones con las viejas
    const options = Object.assign({}, currentOptions, newOptions)

    setIsLoading(true)

    // Hacemos la petición
    const res = await ApiService.getAll('presentations', options)

    // Guardamos las opciones
    setCurrentOptions(options)

    // Recuperamos algunos datos de los resultados
    const { rows, count } = res.payload
    setData(rows)
    setTotal(count)

    // Terminamos la carga
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changePage = (page) => {
    fetchData({
      page
    })
  }

  const changeOrder = (field, dir) => {
    fetchData({
      sort: {
        field,
        dir
      }
    })
  }

  const changeFilter = (key, value) => {
    fetchData({
      [key]: value
    })
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Filters changeFilter={ changeFilter } />

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col md='8'>
                    <h3 className="mb-0">Lista de Presentaciones</h3>
                  </Col>
                  <Col md='4' className='text-right'>
                    <Link to='/admin/presentations/create'>
                      <Button color='primary' className='btn btn-md'>
                        Crear Presentación
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Table id="requests-table" items={ data } isLoading={ isLoading } changeOrder={ changeOrder } currentOrder={ currentOptions.sort } />
              <CardFooter className="py-4">
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={6} className="d-flex justify-content-center d-sm-block">
                    <Pagination page={ currentOptions.page } count={ total } onChangePage={ changePage } limit={ 10 } />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default List
