import React from 'react';
import { useForm } from 'hooks';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Col,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';

import { ApiService } from 'services';
import { useAlert } from 'hooks';
import AlertModal from 'components/AlertModal';
import { useUser } from 'context/UserContext';

function Password() {
  const navigate = useNavigate();

  const { getId } = useUser();

  const [values, handleChange] = useForm({
    currentPassword: '',
    newPassword: '',
    rePassword: '',
  });
  const [showAlert, message, alert] = useAlert(false, '');
  const { currentPassword, newPassword, rePassword } = values;

  const submit = async () => {
    try {
      const id = getId();
      if (!id) {
        alert(true, 'No se pudo obtener su usuario. Actualice la página e intente nuevamente');
        return;
      }

      if (newPassword !== rePassword) {
        alert(true, 'Las contraseñas nuevas no coinciden.');
        return;
      }

      const data = { action: 'changePassword' };
      Object.assign(data, { currentPassword, newPassword });
      const res = await ApiService.update('users', getId(), data);

      if (res.status !== 'ok') {
        if (res.message) {
          alert(true, res.message);
          return;
        }

        alert(true, 'Se produjo un error mientras se intentaba cambiar la contraseña. ' +
          'Por favor, intente nuevamente más tarde.');
        return;
      }

      // Redirigimos al login e indicamos que se recuperó correctamente la contraseña
      // para que muestre mensaje
      navigate('/auth/login', {
        state: {
          didChange: true,
        },
      });
    } catch (err) {
      alert(true, 'Se produjo un error mientras se intentaba cambiar la contraseña. ' +
      'Por favor, intente nuevamente más tarde.');
    }
  };

  return (
    <>
      <AlertModal
        isOpen={showAlert}
        message={message}
        onClose={() => alert(false)}
      />
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-5">
              <h3>Cambiar contraseña</h3>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                  <Input
                    placeholder="Contraseña actual"
                    name='currentPassword'
                    type="password"
                    value={currentPassword}
                    onChange={ handleChange }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                  <Input
                    placeholder="Nueva contraseña"
                    name="newPassword"
                    type="password"
                    value={ newPassword }
                    onChange={ handleChange }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                  <Input
                    placeholder="Repita nueva contraseña"
                    name="rePassword"
                    type="password"
                    value={ rePassword }
                    onChange={ handleChange }
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={ submit }
                >
                  Cambiar contraseña
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default Password;
