import config from 'config';
import { APIResponse } from '@cokiba/types';

interface Expiration {
  vencimiento: number;
  solicitudes: number;
};

export async function getExpirations(): Promise<Expiration[] | undefined> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/expirations`);

    type Response = APIResponse<Expiration[]>

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as Response;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las gráficas. ');
  }
}
