import React, { useEffect, useState } from 'react';
import { Insurer, getInsurers, setInsurersConfig } from 'features/config/configAPI';
import { Card, CardBody, CardHeader, FormGroup, Input, Table } from 'reactstrap';

interface InsurersProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export default function Insurers(props: InsurersProps) {
  const { loading, setLoading } = props;

  const [insurers, setInsurers] = useState<Insurer[]>([]);

  useEffect(() => {
    getInsurers().then(data => {
      if (!data) {
        return;
      }
      setInsurers(data);
    })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleInsurerChange = async (insurer: number, target: 'web' | 'app', value: boolean) => {
    try {
      setLoading(true);

      await setInsurersConfig(insurer, target, value);

      const newInsurers = [...insurers] as typeof insurers;
      const idx = insurers.findIndex(i => i.id == insurer);

      if (target === 'web') {
        newInsurers[idx].isEnabled = value;
      } else {
        newInsurers[idx].isEnabledApp = value;
      }

      setInsurers(newInsurers);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="shadow" style={{ marginBottom: '1rem' }}>
      <CardHeader>Obras Sociales</CardHeader>
      <CardBody style={{ padding: 0 }}>
        <Table>
          <thead>
            <tr>
              <th>Obra Social</th>
              <th style={{ textAlign: 'center' }}>Activa Web</th>
              <th style={{ textAlign: 'center' }}>Activa App</th>
            </tr>
          </thead>
          <tbody>
            {
              insurers.map(insurer => (
                <tr key={ insurer.id }>
                  <th>{ insurer.codigo }</th>
                  <td style={{ textAlign: 'center' }}>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={ insurer.isEnabled }
                        onChange={ event => handleInsurerChange(insurer.id, 'web', event.target.checked) }
                        disabled={ loading }
                      />
                    </FormGroup>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={ insurer.isEnabledApp }
                        onChange={ event => handleInsurerChange(insurer.id, 'app', event.target.checked) }
                        disabled={ loading }
                      />
                    </FormGroup>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
