import { Config as IConfig, Setting, getConfig, setConfig } from 'features/config/configAPI';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, FormGroup, Input, InputGroup, Table } from 'reactstrap';

type ConfigUpdate = Record<string, Setting['value']>;

interface SettingsProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export default function Settings(props: SettingsProps) {
  const { loading, setLoading } = props;

  const [settings, setSettings] = useState<IConfig>([]);
  const [update, setUpdate] = useState<ConfigUpdate>({});

  useEffect(() => {
    getConfig().then(data => {
      if (!data) {
        return;
      }
      setSettings(data);
    })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (key: string, type: string, value: string | boolean) => {
    let parsedValue;

    switch (type) {
      // @ts-ignore porque sólo pueede llegar un string
      case 'integer': parsedValue = parseInt(value); break;
      default: parsedValue = value;
    }

    setUpdate({
      ...update,
      [key]: parsedValue,
    });
  };

  const handleSave = async (key: string) => {
    try {
      setLoading(true);

      const newConfig = await setConfig(key, update[key]);
      if (!newConfig) {
        return;
      }

      const newSettings = [...settings] as typeof settings;
      const idx = newSettings.findIndex(d => d.key == key);
      newSettings[idx].value = update[key];

      setSettings(newSettings);

      delete update[key];
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUndo = (key: string) => {
    const newUpdate = Object.assign({}, update);

    delete newUpdate[key];

    setUpdate(newUpdate);
  };

  return (
    <Card className="shadow" style={{ marginBottom: '1rem' }}>
      <CardHeader>Configuración</CardHeader>
      <CardBody style={{ padding: 0 }}>
        <Table>
          <tbody>
            {
              settings.map(r => (
                <tr key={ r.key }>
                  <th>{ r.title }</th>
                  <td>
                    <InputGroup>
                      {
                        r.type !== 'boolean'
                          ? <Input
                              type={ r.type === 'integer' ? 'number' : 'text' }
                              value={ update[r.key] ? `${ update[r.key] }` : `${ r.value }` }
                              onChange={ event => handleChange(r.key, r.type, event.target.value) }
                              disabled={ r.type === 'version' || loading }
                            />
                          : <FormGroup switch style={{ paddingLeft: '5rem' }}>
                              <Input
                                type="switch"
                                role="switch"
                                checked={ typeof update[r.key] !== 'undefined' ? !!update[r.key] : r.value }
                                onChange={ event => handleChange(r.key, r.type, event.target.checked) }
                                disabled={ loading }
                                style={{
                                  height: '2.2rem',
                                  width: '4.4rem',
                                  marginLeft: '-5rem',
                                }}
                              />
                            </FormGroup>
                      }
                      {
                        r.type !== 'version'
                          ? <>
                              <Button
                                outline
                                color="primary"
                                disabled={ !update.hasOwnProperty(r.key) || loading }
                                onClick={ () => handleSave(r.key) }
                              >
                                <i className="fas fa-save"></i>
                              </Button>
                              <Button
                                outline
                                color="warning"
                                disabled={ !update.hasOwnProperty(r.key) || loading }
                                onClick={ () => handleUndo(r.key) }
                              >
                                <i className="fas fa-undo"></i>
                              </Button>
                            </>
                          : null
                      }
                    </InputGroup>
                    {
                      r.description
                        ? <small>{ r.description }</small>
                        : null
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
