import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Form, Input } from 'reactstrap'
import Uploader from './Uploader'
import ApiService from 'services/ApiService'

function PresentationForm (props) {
  const { data: formData, isLoading, onSubmit, setData, update = false } = props

  const [ insurers, setInsurers ] = useState([])

  const submit = () => {
    if (onSubmit) {
      return onSubmit()
    }
  }

  const handleInputChange = (fieldName, value) => {
    setData({
      ...formData,
      [fieldName]: value
    })
  }

  useEffect(() => {
    ApiService.getAll('ooss', { limit: -1 })
    .then(res => {
      if (!res.count) {
        console.log(res)
        return
      }

      setInsurers(res.rows)
    })
    .catch(err => {
      alert(true, 'Ha ocurrido un error al obtener el listado de Obras Sociales, por favor intente nuevamente más tarde.')
      console.log(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form>
      <h6 className="heading-small text-muted mb-4">Complete los datos</h6>
      <Row className="form-group mb-4">
        <Col md="6">
          <Input
            label="Obra Social"
            id="insurer_id"
            name="insurer_id"
            type="select"
            defaultValue="-1"
            value={ formData.insurer_id }
            onChange={ (event) => handleInputChange(event.target.name, event.target.value) }
            placeholder="Obras sociales"
            disabled={ isLoading }
            required>
            <option disabled value="-1" hidden>Seleccione una opción...</option>
            {
              insurers.map(insurer => <option key={ insurer.id } value={ insurer.id }>{ insurer.codigo }</option>)
            }
          </Input>
        </Col>
      </Row>
      <Row className="form-group mb-4">
        <Col md="6">
          <label className="form-control-label">N° de Remito</label>
          <Input
            type="number"
            value={ formData.submission_id }
            name="submission_id"
            disabled={ isLoading }
            onChange={ (event) => handleInputChange(event.target.name, event.target.value) }
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="6">
          <label className="form-control-label">Resumen</label>
          <Uploader
            name='summary_file'
            onChange={ (file) => handleInputChange('summary_file', file) }
            value={ formData.summary_file }
            disabled={ isLoading }
            required
            />
        </Col>
        <Col md="6">
          <label className="form-control-label">Detalle</label>
          <Uploader
            name='detail_file'
            onChange={ (file) => handleInputChange('detail_file', file) }
            value={ formData.detail_file }
            disabled={ isLoading }
            required
            />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <label className="form-control-label">Otros</label>
          <Uploader
            name='files'
            onChange={ (file) => handleInputChange('extra_file', file) }
            value={ formData.extra_file }
            disabled={ isLoading }
            required
            />
        </Col>
      </Row>
      <Row>
        <div className="update ml-auto mr-auto pt-4">
          <Button
            className="btn-round"
            color="success"
            title={ update ? "Modificar Presentación" : "Crear Nueva Presentación"}
            disabled={ isLoading }
            onClick={ () => submit() }
          >
            { update ? 'Modificar' : 'Crear' }
          </Button>
        </div>
      </Row>
    </Form>
  )
}

export default PresentationForm