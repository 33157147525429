import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Row,
  CardBody,
  Input,
  Button,
  Col,
  Table,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';
import config from 'config';
import { Exchange, ExchangesListQuery, Insurer, ListResponse } from '@cokiba/types';
import { getQueryString } from 'services/ApiService';
import { decodeEntities } from 'utils/htmlentitites';
import moment from 'moment';
import Pagination from 'components/Pagination';

let abortController = new AbortController();

interface MessagesListProps {
  alert: (message: string) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  insurers?: Insurer[];
}

export default function MessagesList(props: MessagesListProps) {
  const { setLoading, loading, alert, insurers } = props;

  const exchangeRef = useRef<HTMLHeadingElement>(null);

  const [exchangeMessages, setExchangeMessages] = useState<Exchange[]>([]);
  const [exchangeCount, setExchangeCount] = useState(0);
  const [exchangeOptions, setExchangeOptions] = useState<ExchangesListQuery>({});
  const [exchangeDetailsOpen, setExchangeDetailsOpen] = useState(false);
  const [exchangeDetails, setExchangeDetails] = useState('');

  const fetchExchangeMessages = (newOptions: ExchangesListQuery = {}) => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      return;
    }

    setLoading(true);

    abortController.abort();
    abortController = new AbortController();

    const options = Object.assign({}, exchangeOptions, newOptions);
    const queryString = getQueryString(options);

    fetch(`${config.baseUrl}/reports/exchanges${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
      signal: abortController.signal,
    })
      .then(res => {
        switch (res.status) {
          case 200:
            setExchangeOptions(options);
            return res.json() as Promise<ListResponse<Exchange>>;
          case 403:
            alert('No tiene los permisos necesarios para realizar la operación');
            break;
          default:
            alert('Ocurrió un error impreviso cuando se intentó realizar la operación');
            console.error(res);
        }
      })
      .then(data => {
        if (!data) {
          return;
        }
        setExchangeMessages(data.payload.rows);
        setExchangeCount(data.payload.count);
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          return;
        }

        console.error(err);
        alert('Ocurrió un error de conexión cuando se intentó realizar la operación');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeFilterExchange = (key: string, value: unknown) => {
    fetchExchangeMessages({
      [key]: value && value != 'any' ? value : undefined,
      page: 1,
    });
  };

  const changePageExchange = async (page: number) => {
    await fetchExchangeMessages({ page });
    exchangeRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const showDetails = (exchange: Exchange, type: 'request' | 'response') => {
    let details: string;
    if (type === 'request' && exchange.request_content) {
      details = exchange.request_content;
    } else if (type === 'response' && exchange.response_content) {
      details = exchange.response_content;
    } else {
      details = '-';
    }

    // Normalizamos los saltos de línea
    details = details.replace(/\n?\r+/g, '\n');

    // Reemplazamos los saltos de línea que estén codificados
    details = details.replace(/(?:\\[rn])+/g, '\n');
    switch (true) {
      case isXML(details):
        const parser = new DOMParser();
        const doc = parser.parseFromString(details, 'application/xml');
        const data = doc.querySelector('pSolicitud');
        if (data) {
          details = decodeEntities(data.innerHTML);
        } else if (!data && isJSON(details)) {
          details = JSON.stringify(JSON.parse(details), undefined, 4);
        }
        break;
      case isJSON(details):
        details = JSON.stringify(JSON.parse(details), undefined, 4);
        break;
      default:
        break;
    }
    setExchangeDetails(details);
    setExchangeDetailsOpen(true);
  };

  useEffect(() => {
    fetchExchangeMessages();
  }, []);


  function isXML(str: string): boolean {
    try {
      if (isJSON(str)) {
        return false;
      };
      const parser = new DOMParser();
      parser.parseFromString(str, 'application/xml');
      return true;
    } catch (error) {
      return false;
    }
  }
  function isJSON(str: string): boolean {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }
  return (
    <>
      <Card className="shadow" style={{ marginBottom: '1rem' }}>
        <CardBody>
          <Row style={{ minHeight: '3rem' }}>
            <Col sm={6} style={{ display: 'flex', alignItems: 'center' }}>
              <h3 ref={exchangeRef} style={{ margin: 0 }}>Consulta Mensajes con OOSS</h3>
              {loading && <Spinner size="sm" style={{ marginLeft: '1rem' }} />}
            </Col>
            <Col sm={6}>
              <Input
                type="select"
                onChange={event => changeFilterExchange('insurer_id', event.target.value)}
                defaultValue="any"
              >
                <option value="any">Todas las OOSS</option>
                {
                  insurers && Array.isArray(insurers)
                    ? insurers.map(insurer => (
                      <option key={'insurer_' + insurer.id} value={insurer.id}>
                        {insurer.codigo}
                      </option>
                    ))
                    : null
                }
              </Input>
            </Col>
          </Row>
          <Table style={{ marginBottom: '1rem' }}>
            <thead>
              <tr>
                <th>
                  <Input
                    bsSize="sm"
                    placeholder="Matriculado"
                    style={{ maxWidth: '100px' }}
                    onChange={event => changeFilterExchange('matriculate_id', event.target.value)}
                  />
                </th>
                <th>
                  <Input
                    bsSize="sm"
                    placeholder="Afiliado"
                    style={{ maxWidth: '100px' }}
                    onChange={event => changeFilterExchange('recipient_id', event.target.value)}
                  />
                </th>
                <th>
                  <Input
                    bsSize="sm"
                    type="select"
                    onChange={event => changeFilterExchange('operation', event.target.value)}
                    defaultValue="any"
                  >
                    <option value="any">Operación</option>
                    <option value="authorize">Autorización</option>
                    <option value="annul">Anulación</option>
                    <option value="affiliate">Validación de usuario</option>
                    <option value="validate">Validación de autorizacion</option>
                  </Input>
                </th>
                <th>Fecha Solicitud</th>
                <th>Solicitud</th>
                <th>Fecha Respuesta</th>
                <th>Respuesta</th>
              </tr>
            </thead>
            <tbody>
              {
                exchangeMessages.map(exchange => (
                  <tr key={exchange.id}>
                    <td style={{ verticalAlign: 'middle' }}>
                      {exchange.matriculate_id ?? '-'}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {exchange.recipient_id ?? '-'}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {exchange.operation ?? '-'}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {
                        exchange.request_date && moment(exchange.request_date).isValid()
                          ? moment(exchange.request_date).format('DD/MM/YYYY HH:mm:ss')
                          : '-'
                      }
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Button
                        size="sm"
                        color="link"
                        onClick={() => showDetails(exchange, 'request')}
                        disabled={loading}
                      >
                        Solicitud
                      </Button>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      {
                        exchange.response_date && moment(exchange.response_date).isValid()
                          ? moment(exchange.response_date).format('DD/MM/YYYY HH:mm:ss')
                          : '-'
                      }
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Button
                        size="sm"
                        color="link"
                        onClick={() => showDetails(exchange, 'response')}
                        disabled={loading}
                      >
                        Respuesta
                      </Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <Pagination
            page={exchangeOptions.page ? exchangeOptions.page : 1}
            count={exchangeCount}
            onChangePage={changePageExchange}
            limit={10}
          />
        </CardBody>
      </Card>
      <Modal isOpen={exchangeDetailsOpen} size="lg" toggle={() => setExchangeDetailsOpen(!exchangeDetailsOpen)}>
        <ModalBody>
          <pre>
            {exchangeDetails}
          </pre>
        </ModalBody>
      </Modal>
    </>
  );
}
