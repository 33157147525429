import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Alert } from 'reactstrap'
import Form from './Form'
import { useAlertPlus } from '../../hooks'
import AlertModal from '../../components/AlertModal'
import config from 'config'
import { ApiService } from 'services'

const initialValues = {
  title: '',
  content: ''
}

function Edit () {
  const [ data, setData ] = useState(initialValues)

  const [ isLoading, setIsLoading ] = useState(false)
  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')

  const { id } = useParams()

  const handleSend = async (send = false) => {
    try {
      setIsLoading(true)

      // Preparamos los datos
      const formData = new FormData()
      formData.append('title', data.title)
      formData.append('content', data.content)

      if (data.targets && Array.isArray(data.targets)) {
        formData.append('targets', JSON.stringify(data.targets.map(t => t.value)))
      }

      if (data.delegation) {
        formData.append('delegation', data.delegation)
      }

      if (send) {
        formData.append('send', 1)
      }

      const res = await fetch(`${config.baseUrl}/notifications/${ id }`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('accessToken')
        },
        body: formData
      })

      const response = await res.json()

      if (response?.status !== 'ok') {
        console.info(response)

        if (response.payload?.message) {
          alert(response.payload.message)
          return
        }

        alert('Ha ocurrido un error al enviar al servidor, por favor intente nuevamente.')
        return
      }

      if (send) {
        alert('Se envió correctamente la notificación.', () => {
          window.location = '/admin/notifications'
        })
      } else {
        alert('Se guardaron correctamente los cambios.')
      }
    } catch (err) {
      console.error(err)
      alert('Ha ocurrido un error al crear la notificación, por favor intente nuevamente.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)

      const res = await fetch(`${config.baseUrl}/notifications/${ id }`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      })

      const response = await res.json()

      if (response?.status !== 'ok') {
        console.info(response)

        if (response.payload?.message) {
          alert(response.payload.message)
          return
        }

        alert('Ha ocurrido un error al enviar al servidor, por favor intente nuevamente.')
        return
      }

      alert('Se eliminó correctamente la notificación.', () => {
        window.location = '/admin/notifications'
      })
    } catch (err) {
      console.error(err)
      alert('Ha ocurrido un error al eliminar la notificación, por favor intente nuevamente.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)

    ApiService.getOne('notifications', id)
    .then(res => {
      if (res.status !== 'ok') {
        alert('Ha ocurrido un error al recuperar la notificación, por favor intente nuevamente.')
        return
      }

      if (res.payload.targets) {
        res.payload.targets = res.payload.targets.map(t => ({
          value: t.matriculate_id,
          label: t.matriculate_id
        }))
      }

      setData(res.payload)
    })
    .catch(err => {
      alert('Ha ocurrido un error al procesar la notificación, por favor intente nuevamente.')
      console.error(err)
    })
    .finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Nueva Notificación</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/notifications'>
                <Button color="primary" size="md">
                  Volver
                </Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {
              data.state_id === 2 && data.recipients === 0
              ? <Alert color="warning" className="text-center">Ninguno de los matriculados destinatarios de la notificación se encuentran usando la aplicación.</Alert>
              : null
            }
            
            <Form
              data={ data }
              setData={ setData }
              isLoading={ isLoading }
              onSend={ () => handleSend(true) }
              onDraft={ () => handleSend(false) }
              onDelete={ handleDelete }
            />
          </CardBody>
        </Card>
      </Container>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
    </>
  )
}

export default Edit
