import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardHeader, CardFooter, Button } from 'reactstrap'
import { ApiService } from 'services'
import Header from '../../components/Headers/Header'
import Pagination from '../../components/Pagination'
import Table from './Table'
import MatriculateRequestFilters from './Filters'
import { getQueryString } from 'services/ApiService'
import config from 'config'
import { useUser } from 'context/UserContext'

function List () {
  const { listOptions, setListOptions } = useUser()

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)

  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)

  const initialOptions = listOptions?.matriculates_photos ? listOptions.matriculates_photos : {
    sort: {
      field: 'created_at',
      dir: -1
    },
    page: 1,
    limit: 50,
    search: ''
  }

  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState(initialOptions)

  const fetchData = async (newOptions) => {
    // Merge de las nuevas opciones con las viejas
    const options = Object.assign({}, currentOptions, newOptions)

    setIsLoading(true)

    // Hacemos la petición
    const res = await ApiService.getAll('matriculates/requests', options)

    // Guardamos las opciones
    setCurrentOptions(options)
    setListOptions({
      ...listOptions,
      matriculates_photos: options
    })

    if (res.status !== 'ok') {
      alert('Error recuperando las solicitudes de los matriculados')
      console.error(res)
      setIsLoading(false)
      return
    }

    // Recuperamos algunos datos de los resultados
    const { rows, count } = res.payload
    setData(rows)
    setTotal(count)

    // Terminamos la carga
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changePage = (page) => {
    fetchData({
      page
    })
  }

  const changeOrder = (field, dir) => {
    fetchData({
      sort: {
        field,
        dir
      }
    })
  }

  const changeFilter = (key, value) => {
    fetchData({
      [key]: value
    })
  }

  const downloadExcel = async () => {
    try {
      setIsLoadingExcel(true)

      const queryString = getQueryString(currentOptions)

      const res = await fetch(`${ config.baseUrl }/matriculates/requests/export${ queryString }`, {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      })

      const blob = await res.blob()
    
      const file = window.URL.createObjectURL(blob);

      window.location.assign(file);
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoadingExcel(false)
    }
  }

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>
          <Col>
            <MatriculateRequestFilters changeFilter={ changeFilter } currentOptions={ currentOptions } />

            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col md='8'>
                    <h3 className="mb-0">Lista de Solicitudes de la App</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table id="matriculates-table" items={ data } isLoading={ isLoading } changeOrder={ changeOrder } currentOrder={ currentOptions?.sort } />
              <CardFooter className="py-4">
                <Row>
                  <Col sm={6} className="d-flex justify-content-center d-sm-block mb-4 mb-md-0">
                    <Button color="light" onClick={ downloadExcel }>
                      {
                        isLoadingExcel
                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        : <>Descargar Excel</>
                      }
                    </Button>
                  </Col>
                  <Col sm={6} className="d-flex justify-content-center d-sm-block">
                    <Pagination page={ currentOptions?.page } count={ total } onChangePage={ changePage } limit={ currentOptions?.limit } />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default List
