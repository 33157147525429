import React from 'react'
import { Col, Row, FormGroup, Input } from 'reactstrap'
import CollapsibleCard from '../../components/CollapsibleCard'
import { delegations } from '../../services'

function RequestFilters(props) {
  const { changeFilter, currentOptions } = props

  return (
    <CollapsibleCard title="Filtros" open={ currentOptions?.delegation && currentOptions.delegation !== 'any' }>
      <Row>
        {
          delegations
          ? <Col sm={4}>
              <FormGroup>
                <label>Delegaciones</label>
                <Input
                  type="select"
                  onChange={ (event) => changeFilter('delegation', event.target.value) }
                  value={ currentOptions?.delegation ? currentOptions.delegation : 'any' }
                >
                  <option key="any" value="any">Todas</option>
                  {
                    Object
                    .entries(delegations)
                    .map(([key, value]) => <option key={key} value={key}>{value}</option>)
                  }
                </Input>
              </FormGroup>
            </Col>
          : null
        }
      </Row>
    </CollapsibleCard>
  )
}

export default RequestFilters
