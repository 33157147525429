import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Table } from 'reactstrap'
import AlertModal from '../../components/AlertModal'
import { useAlertPlus } from '../../hooks'
import { ApiService } from 'services'
import moment from 'moment'

function Edit () {
  const [ data, setData ] = useState({})

  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')

  const { id } = useParams()

  useEffect(() => {
    ApiService.getOne('matriculates/ethics', id)
    .then(res => {
      if (res.status !== 'ok') {
        alert('Ha ocurrido un error al recuperar la solicitud, por favor intente nuevamente.')
        return
      }

      setData(res.payload)
    })
    .catch(err => {
      alert('Ha ocurrido un error al recuperar la solicitud, por favor intente nuevamente.')
      console.error(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Solicitud de Certificado de Ética #{ id }</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/matriculates/ethics'>
                <Button color="primary" size="md">
                  Volver
                </Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          <Table className="mb-4">
            <tbody>
              <tr>
                <th>Solicitante</th>
                <td>{ `${ data?.matriculate?.apellido }, ${ data?.matriculate?.nombre }` }</td>
              </tr>
              <tr>
                <th>Destinatario</th>
                <td>{ data?.target }</td>
              </tr>
              <tr>
                <th>Razón</th>
                <td>{ data?.reason }</td>
              </tr>
              <tr>
                <th>Fecha</th>
                <td>{ data?.date ? moment(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : moment(data.resolved_at, 'YYYY-MM-DD').format('DD/MM/YYYY') }</td>
              </tr>
              {
                data?.state_id === 2
                ? <>
                    <tr>
                      <th>Validez</th>
                      <td>{ data?.validity ? data.validity + ' días' : '-' }</td>
                    </tr>
                    <tr>
                      <th>Resolución</th>
                      <td>Aceptada</td>
                    </tr>
                  </>
                : null
              }
              {
                data?.state_id === 3
                ? <tr>
                    <th>Resolución</th>
                    <td>Rechazada{ data?.rejection_text ? ': ' + data.rejection_text : '' }</td>
                  </tr>
                : null
              }
            </tbody>
          </Table>
          </CardBody>
        </Card>
      </Container>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
    </>
  )
}

export default Edit
