/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es'

// ES language for date-picker
registerLocale('es', es)

export default function (props) {

  const { onChange, ...inputProps } = props
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    setDate(new Date())
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange( parseDate(date) )
    }
  }, [date])

  const parseDate = (date) => {
    const auxDate = new Date(date)
    const auxMonth = auxDate.getMonth() < 9
      ? `0${auxDate.getMonth() + 1}`
      : auxDate.getMonth() + 1
    return `${auxMonth}/${auxDate.getFullYear()}`
  }

  return (
    <>
      <br />
      <DatePicker
        { ...inputProps }
        locale='es'
        selected={date}
        onChange={date => setDate(date)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
      />
    </>
  )
}