import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap"

function Collapsible(props) {
  const { title, children, open = false } = props

  const [ status, setStatus ] = useState(open)


  return (
    <Card className="shadow mb-3">
      <CardHeader className="clickable" onClick={ () => setStatus(!status)}>
        <Row>
          <Col xs={8} className="text-left">
            <h3 className="mb-0">{ title }</h3>
          </Col>
          <Col xs={4} className="text-right">
            <h3 className="mb-0">
              <i className={`fa fa-chevron-${ status ? 'up' : 'down' }`} aria-hidden="true"></i>
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className={`${ status ? '' : 'hidden' }`} >
        { children }
      </CardBody>
    </Card>
  )
}

export default Collapsible