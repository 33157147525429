import React from 'react'
import { Col, Row, Input, FormGroup } from 'reactstrap'
import CollapsibleCard from 'components/CollapsibleCard'
import { delegations } from '../../services'

function Filters(props) {
  const { changeFilter, delegaciones = true, currentOptions } = props

  return (
    <CollapsibleCard title="Filtros">
      <Row>
        {
          delegaciones &&
          <Col sm={4}>
            <FormGroup>
              <label>Delegaciones</label>
              <Input
                type="select"
                onChange={ (event) => changeFilter('delegation', event.target.value) }
                value={ currentOptions?.delegation ? currentOptions.delegation : 'any' }
              >
                <option key="any" value="any">Todas</option>
                {
                  Object
                  .entries(delegations)
                  .map(([key, value]) => (
                    <option key={key} value={key}>{value}</option>)
                  )
                }
              </Input>
            </FormGroup>
          </Col>
        }
      </Row>
    </CollapsibleCard>
  )
}

export default Filters
