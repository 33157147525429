import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

import { ApiService } from 'services'
import OOSSForm from "./OOSSForm"

const initialValues = {
  nombre: '',
  codigo: '',
  cuit: '',
  domicilioFiscal: '',
  situacioniva: '',
  razonSocial: '',
  isEnabled: false,
  presentsBill: false
}

const initialFieldClass = initialValues

function Create () {

  const navigate = useNavigate()
  const [data, setData] = useState(initialValues)
  const [fieldClass, setFieldClass] = useState(initialFieldClass)
  const [isValidated, setIsValidated] = useState(true)

  const validate = () => {
    const classes = {}
    let isValidated = true
    for (const key in data) {
      if ((!data[key] || data[key] === '') && Object.keys(initialValues).includes(key)) {
        isValidated = false
        Object.assign(classes, {
          [key]: 'required'
        })
      } else {
        Object.assign(classes, {
          [key]: ''
        })
      }
    }

    if (!isValidated) {
      console.log(classes)
      setFieldClass({ ...fieldClass, ...classes })
    }
    return isValidated
  }

  const submit = async () => {
    console.log(data)
    const isValidated = validate()
    if (!isValidated) {
      return setIsValidated(false)
    }

    await ApiService.post('ooss', data)

    navigate('/admin/obras-sociales', {
      state: { success: true }
    })
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <OOSSForm
              title='Nueva obra social'
              action='create'
              data={data}
              setData={ setData }
              isValidated={isValidated}
              setFieldClass={ setFieldClass }
              fieldClass={fieldClass}
              onSubmit={ submit }
          />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Create
