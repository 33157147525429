import Profile from 'views/Profile';

import ListUsers from 'views/Users/List';
import CreateUsers from 'views/Users/Create';
import EditUsers from 'views/Users/Edit';
import ViewUsers from 'views/Users/View';

import ListRequests from 'views/Requests/List';
import EditRequests from 'views/Requests/Edit';
import ShowRequests from 'views/Requests/Show';
import Editor from 'views/Requests/Editor';

import ListOOSS from 'views/OOSS/List';
import EditOOSS from 'views/OOSS/Edit';
import CreateOOSS from 'views/OOSS/Create';

import ListRemitos from 'views/Remitos/List';
import CreateRemitos from 'views/Remitos/Create';
import ShowRemitos from 'views/Remitos/Show';

import ListBSO from 'views/BSO/List';
import CreateBSO from 'views/BSO/Create';
import AuditBSO from 'views/BSO/Audit';
import ViewBSO from 'views/BSO/View';

import ListPresentation from 'views/Presentation/List';
import CreatePresentation from 'views/Presentation/Create';
import EditPresentation from 'views/Presentation/Edit';

import ListMatriculates from 'views/Matriculates/List';
import EditMatriculates from 'views/Matriculates/Edit';
import NotificationsMatriculates from 'views/Matriculates/Notifications';

import ListMatriculatesRequests from 'views/MatriculatesRequests/List';
import EditMatriculatesPhotos from 'views/MatriculatesRequests/Photos/Edit';
import EditMatriculatesProfile from 'views/MatriculatesRequests/Profile/Edit';
import ViewMatriculatesPhotos from 'views/MatriculatesRequests/Photos/View';
import ViewMatriculatesProfile from 'views/MatriculatesRequests/Profile/View';

import ListMatriculatesEthics from 'views/MatriculatesEthics/List';
import EditMatriculatesEthics from 'views/MatriculatesEthics/Edit';
import ViewMatriculatesEthics from 'views/MatriculatesEthics/View';

import ListNotifications from 'views/Notifications/List';
import CreateNotifications from 'views/Notifications/Create';
import EditNotifications from 'views/Notifications/Edit';

import Help from 'views/Help';
import Reports from 'views/Reports';
import Config from 'views/Config';

interface Route {
  path: string;
  name: string;
  icon: string;
  component: () => JSX.Element;
  layout: string;
  roles: number[];
  show: boolean;
}

const routes: Route[] = [
  {
    path: '/solicitudes',
    name: 'Solicitudes de Autorización',
    icon: 'ni ni-single-copy-04 text-blue',
    component: ListRequests,
    layout: '/admin',
    roles: [1, 2, 3, 4],
    show: true,
  },
  {
    path: '/solicitudes/:id/editor',
    name: 'Ver solicitud',
    icon: 'ni ni-single-copy-04 text-blue',
    component: Editor,
    layout: '/admin',
    show: false,
    roles: [1, 3],
  },
  {
    path: '/solicitudes/:id/edit',
    name: 'Ver solicitud',
    icon: 'ni ni-single-copy-04 text-blue',
    component: EditRequests,
    layout: '/admin',
    show: false,
    roles: [1, 2, 3, 4],
  },
  {
    path: '/solicitudes/:id',
    name: 'Ver solicitud',
    icon: 'ni ni-single-copy-04 text-blue',
    component: ShowRequests,
    layout: '/admin',
    show: false,
    roles: [1, 2, 3, 4],
  },
  {
    path: '/ayuda',
    name: 'Ayuda',
    icon: 'ni ni-support-16 text-blue',
    component: Help,
    layout: '/admin',
    roles: [2, 3, 4, 7, 8],
    show: true,
  },
  {
    path: '/pedidos-presentacion',
    name: 'Facturación Online',
    icon: 'ni ni-collection text-blue',
    component: ListBSO,
    layout: '/admin',
    show: true,
    roles: [1, 2, 4],
  },
  {
    path: '/pedidos-presentacion/nuevo',
    name: 'Nuevo Pedido de Presentación',
    icon: 'ni ni-collection text-blue',
    component: CreateBSO,
    layout: '/admin',
    show: false,
    roles: [1, 4],
  },
  {
    path: '/pedidos-presentacion/:id/audit',
    name: 'Auditar Factura Presendata',
    icon: 'ni ni-collection text-blue',
    component: AuditBSO,
    layout: '/admin',
    show: false,
    roles: [1, 2, 4],
  },
  {
    path: '/pedidos-presentacion/:id',
    name: 'Ver Facturación',
    icon: 'ni ni-collection text-blue',
    component: ViewBSO,
    layout: '/admin',
    show: false,
    roles: [1, 2, 4],
  },
  /* REMITOS */
  {
    path: '/remitos',
    name: 'Remitos de Documentación',
    icon: 'ni ni-ungroup text-blue',
    component: ListRemitos,
    layout: '/admin',
    show: true,
    roles: [1, 3, 4],
  },
  {
    path: '/remitos/nuevo',
    name: 'Generar remitos',
    icon: 'ni ni-ungroup text-blue',
    component: CreateRemitos,
    layout: '/admin',
    show: false,
    roles: [1, 3, 4],
  },
  {
    path: '/remitos/:id',
    name: 'Gestión de Remitos',
    icon: 'ni ni-collection text-blue',
    component: ShowRemitos,
    layout: '/admin',
    show: false,
    roles: [1, 2, 3, 4],
  },
  // PROFILE
  {
    path: '/profile',
    name: 'Editar perfil',
    icon: 'ni ni-single-02 text-yellow',
    component: Profile,
    layout: '/admin',
    show: false,
    roles: [1, 2, 3, 4, 7, 8],
  },
  // Gestión de presentaciones
  {
    path: '/presentations',
    name: 'Gestión de Presentaciones',
    icon: 'ni ni-collection text-blue',
    component: ListPresentation,
    layout: '/admin',
    show: true,
    roles: [1, 3, 4],
  },
  {
    path: '/presentations/create',
    name: 'Gestión de Presentaciones',
    icon: 'ni ni-collection text-blue',
    component: CreatePresentation,
    layout: '/admin',
    show: false,
    roles: [1, 3, 4],
  },
  {
    path: '/presentations/:id/edit',
    name: 'Gestión de Presentaciones',
    icon: 'ni ni-collection text-blue',
    component: EditPresentation,
    layout: '/admin',
    show: false,
    roles: [1, 3, 4],
  },
  // Matriculados
  {
    path: '/matriculates',
    name: 'Matriculados',
    icon: 'fas fa-user-md text-blue',
    component: ListMatriculates,
    layout: '/admin',
    show: true,
    roles: [1, 8],
  },
  {
    path: '/matriculates/:id/edit',
    name: 'Matriculados',
    icon: 'fas fa-user-md text-blue',
    component: EditMatriculates,
    layout: '/admin',
    show: false,
    roles: [1, 8],
  },
  {
    path: '/matriculates/:id/notifications',
    name: 'Matriculados',
    icon: 'fas fa-user-md text-blue',
    component: NotificationsMatriculates,
    layout: '/admin',
    show: false,
    roles: [1, 8],
  },
  {
    path: '/matriculates/requests',
    name: 'Solicitudes App',
    icon: 'fas fa-mobile-alt text-blue',
    component: ListMatriculatesRequests,
    layout: '/admin',
    show: true,
    roles: [1, 8],
  },
  {
    path: '/matriculates/photos/:id/edit',
    name: 'Solicitudes App',
    icon: 'fas fa-mobile-alt text-blue',
    component: EditMatriculatesPhotos,
    layout: '/admin',
    show: false,
    roles: [1, 8],
  },
  {
    path: '/matriculates/profile/:id/edit',
    name: 'Solicitudes App',
    icon: 'fas fa-mobile-alt text-blue',
    component: EditMatriculatesProfile,
    layout: '/admin',
    show: false,
    roles: [1, 8],
  },
  {
    path: '/matriculates/photos/:id',
    name: 'Solicitudes App',
    icon: 'fas fa-mobile-alt text-blue',
    component: ViewMatriculatesPhotos,
    layout: '/admin',
    show: false,
    roles: [1, 8],
  },
  {
    path: '/matriculates/profile/:id',
    name: 'Solicitudes App',
    icon: 'fas fa-mobile-alt text-blue',
    component: ViewMatriculatesProfile,
    layout: '/admin',
    show: false,
    roles: [1, 8],
  },
  {
    path: '/matriculates/ethics',
    name: 'Certificados de Ética',
    icon: 'fas fa-mobile-alt text-blue',
    component: ListMatriculatesEthics,
    layout: '/admin',
    show: true,
    roles: [1, 8],
  },
  {
    path: '/matriculates/ethics/:id/edit',
    name: 'Certificados de Ética',
    icon: 'fas fa-mobile-alt text-blue',
    component: EditMatriculatesEthics,
    layout: '/admin',
    show: false,
    roles: [1, 8],
  },
  {
    path: '/matriculates/ethics/:id',
    name: 'Certificados de Ética',
    icon: 'fas fa-mobile-alt text-blue',
    component: ViewMatriculatesEthics,
    layout: '/admin',
    show: false,
    roles: [1, 8],
  },
  {
    path: '/notifications/create',
    name: 'Notificaciones App',
    icon: 'far fa-bell text-blue',
    component: CreateNotifications,
    layout: '/admin',
    show: false,
    roles: [1, 7],
  },
  {
    path: '/notifications',
    name: 'Notificaciones App',
    icon: 'far fa-bell text-blue',
    component: ListNotifications,
    layout: '/admin',
    show: true,
    roles: [1, 7],
  },
  {
    path: '/notifications/:id',
    name: 'Notificaciones App',
    icon: 'far fa-bell text-blue',
    component: EditNotifications,
    layout: '/admin',
    show: false,
    roles: [1, 7],
  },
  // USUARIOS
  {
    path: '/usuarios',
    name: 'Usuarios',
    icon: 'ni ni-single-02 text-blue',
    component: ListUsers,
    layout: '/admin',
    roles: [1],
    show: true,
  },
  {
    path: '/usuarios/crear',
    name: 'Crear usuario',
    icon: 'ni ni-single-02 text-red',
    component: CreateUsers,
    layout: '/admin',
    show: false,
    roles: [1],
  },
  {
    path: '/usuarios/:id',
    name: 'Ver usuario',
    icon: 'ni ni-single-02 text-red',
    component: ViewUsers,
    layout: '/admin',
    show: false,
    roles: [1],
  },
  {
    path: '/usuarios/:id/edit',
    name: 'Editar usuario',
    icon: 'ni ni-single-02 text-red',
    component: EditUsers,
    layout: '/admin',
    show: false,
    roles: [1],
  },
  // OBRAS SOCIALES
  {
    path: '/obras-sociales',
    name: 'Obras sociales',
    icon: 'ni ni-bullet-list-67 text-blue',
    component: ListOOSS,
    layout: '/admin',
    roles: [1, 4],
    show: true,
  },
  {
    path: '/obras-sociales/nueva',
    name: 'Obras sociales',
    icon: 'ni ni-bullet-list-67 text-blue',
    component: CreateOOSS,
    layout: '/admin',
    show: false,
    roles: [1],
  },
  {
    path: '/obras-sociales/:id',
    name: 'Obras sociales',
    icon: 'ni ni-bullet-list-67 text-blue',
    component: EditOOSS,
    layout: '/admin',
    show: false,
    roles: [1, 4],
  },
  {
    path: '/reports',
    name: 'Reportes',
    icon: 'ni ni-chart-pie-35 text-blue',
    component: Reports,
    layout: '/admin',
    show: true,
    roles: [1, 2, 3],
  },
  {
    path: '/config',
    name: 'Configuración',
    icon: 'ni ni-ui-04 text-red',
    component: Config,
    layout: '/admin',
    show: true,
    roles: [1, 2, 3],
  },
];

export default routes;
