import React, { createContext, ReactNode, useContext, useState } from 'react';

interface User {
  id: number;
  firstName: string;
  lastName: string;
  roles: number[];
  delegation: number;
};

export interface UserOptions {
  requests?: {
    estado_id: string;
    sort: {
      field: string;
      dir: number;
    }
    page: number;
    search: string;
    delegation?: string;
    ooss_id?: string;
    vencimiento?: string;
  };
}

interface ContextData {
  getUser: () => User | null;
  setUser: (user: User | null) => void;

  getId: () => number | undefined;
  getDelegation: () => number | undefined;

  listOptions: UserOptions;
  setListOptions: (options: UserOptions) => void;

  is: (query: number | number[]) => boolean;
  isNot: (query: number | number[]) => boolean;
}

export const UserContext = createContext<ContextData | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
}

const localUserData = localStorage.getItem('userData');
let initialUserData: User | null = null;
if (localUserData) {
  try {
    initialUserData = JSON.parse(localUserData) as User;
  } catch (err) {
    console.error(err);
    localStorage.removeItem('userData');
  }
}

export default function Provider({ children }: ProviderProps) {
  const [userData, setUserData] = useState<User | null>(initialUserData);
  const [listOptions, setListOptions] = useState<UserOptions>({});

  const getUser = () => {
    return userData;
  };

  const setUser = (user: User | null) => {
    setUserData(user);
  };

  const is = (query: number | number[]) => {
    // Si no hay usuario, por defecto falso
    if (!userData) {
      return false;
    }

    // Si hay algún problema con la información de los roles, por defecto falso
    if (!userData.roles || !Array.isArray(userData.roles) || !userData.roles.length) {
      return false;
    }

    // Para simplificar el tratamiento nos aseguramos que la query siempre sea un arreglo de ids de roles
    if (!Array.isArray(query)) {
      query = [query];
    }

    // Devolvemos true o false dependiendo de si alguno de los roles pasados en la query está incluido en
    // los roles del usuario
    return query.some(roleId => userData.roles.includes(roleId));
  };

  const isNot = (query: number | number[]) => !is(query);

  const getId = () => userData?.id;
  const getDelegation = () => userData?.delegation;

  const value = {
    getUser,
    setUser,
    getId,
    getDelegation,
    listOptions,
    setListOptions,
    is,
    isNot,
  };

  return (
    <UserContext.Provider value={ value }>
      { children }
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within the User Provider');
  }

  return context;
};
