import React from 'react'
import { Button, Row, Col, Table } from 'reactstrap'

const SinCambios = <span className="text-muted">Sin cambios</span>
const SinDato = <span className="text-muted">Sin dato</span>

function ProfileForm (props) {
  const { data, isLoading, onAccept, onReject } = props

  const accept = () => {
    if (!onAccept) {
      return
    }

    onAccept()
  }

  const reject = () => {
    if (!onReject) {
      return
    }

    onReject()
  }

  return (
    <>
      <Table className="mb-4">
        <thead>
          <tr>
            <th></th>
            <th>Valor original</th>
            <th>Nuevo valor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Correo Electrónico</th>
            <td>{ data.original?.email !== null ? data.original?.email : SinDato }</td>
            <td>{ data.email !== data.original?.email ? data.email : SinCambios }</td>
          </tr>
          <tr>
            <th>Calle</th>
            <td>{ data.original?.direccion_calle !== null ? data.original?.direccion_calle : SinDato }</td>
            <td>{ data.direccion_calle !== data.original?.direccion_calle ? data.direccion_calle : SinCambios }</td>
          </tr>
          <tr>
            <th>Número</th>
            <td>{ data.original?.direccion_numero !== null ? data.original?.direccion_numero : SinDato }</td>
            <td>{ data.direccion_numero !== parseInt(data.original?.direccion_numero) ? data.direccion_numero : SinCambios }</td>
          </tr>
          <tr>
            <th>Piso</th>
            <td>{ data.original?.direccion_piso !== null ? data.original?.direccion_piso : SinDato }</td>
            <td>{ data.direccion_piso !== data.original?.direccion_piso ? data.direccion_piso : SinCambios }</td>
          </tr>
          <tr>
            <th>Departamento</th>
            <td>{ data.original?.direccion_depto !== null ? data.original?.direccion_depto : SinDato }</td>
            <td>{ data.direccion_depto !== data.original?.direccion_depto ? data.direccion_depto : SinCambios }</td>
          </tr>
          <tr>
            <th>Código Postal</th>
            <td>{ data.original?.direccion_cp !== null ? data.original?.direccion_cp : SinDato }</td>
            <td>{ data.direccion_cp !== data.original?.direccion_cp ? data.direccion_cp : SinCambios }</td>
          </tr>
          <tr>
            <th>Localidad</th>
            <td>{ data.original?.direccion_localidad !== null ? data.original?.direccion_localidad : SinDato }</td>
            <td>{ data.direccion_localidad !== data.original?.direccion_localidad ? data.direccion_localidad : SinCambios }</td>
          </tr>
          <tr>
            <th>Partido</th>
            <td>{ data.original?.direccion_partido }</td>
            <td>{ data.direccion_partido !== data.original?.direccion_partido ? data.direccion_partido : SinCambios }</td>
          </tr>
          <tr>
            <th>Cód. Área Teléfono</th>
            <td>{ data.original?.telefono_codigo !== null ? data.original?.telefono_codigo : SinDato }</td>
            <td>{ data.telefono_codigo !== data.original?.telefono_codigo ? data.telefono_codigo : SinCambios }</td>
          </tr>
          <tr>
            <th>Nro. Teléfono</th>
            <td>{ data.original?.telefono_numero !== null ? data.original?.telefono_numero : SinDato }</td>
            <td>{ data.telefono_numero !== data.original?.telefono_numero ? data.telefono_numero : SinCambios }</td>
          </tr>
          <tr>
            <th>Cód. Área Celular</th>
            <td>{ data.original?.celular_codigo !== null ? data.original?.celular_codigo : SinDato }</td>
            <td>{ data.celular_codigo !== data.original?.celular_codigo ? data.celular_codigo : SinCambios }</td>
          </tr>
          <tr>
            <th>Nro. Celular</th>
            <td>{ data.original?.celular_numero !== null ? data.original?.celular_numero : SinDato }</td>
            <td>{ data.celular_numero !== data.original?.celular_numero ? data.celular_numero : SinCambios }</td>
          </tr>
        </tbody>
      </Table>
      {
        onAccept || onReject
          ? <Row>
              <Col className="text-center">
                <Button color="danger" onClick={ reject } disabled={ isLoading || !onReject }>Rechazar</Button>
                <Button color="success" onClick={ accept } disabled={ isLoading || !onAccept }>Aceptar</Button>
              </Col>
            </Row>
          : null
      }
    </>
  )
}

export default ProfileForm