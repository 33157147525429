import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap'
import Form from './Form'
import { useAlert } from '../../hooks'
import AlertModal from '../../components/AlertModal'
import config from 'config'
import { ApiService } from '../../services'

const initialValues = {
  submission_id: '',
  summary_file: '',
  detail_file: '',
  extra_file: ''
}

function Edit () {
  const { id } = useParams()

  const [data, setData] = useState(initialValues)

  const [isLoading, setIsLoading] = useState(true)
  const [showAlert, message, alert] = useAlert(false, '')

  const submit = async () => {
    try {
      setIsLoading(true)

      // Preparamos los datos
      const formData = new FormData()
      formData.append('submission_id', data.submission_id)

      if (data.summary_file instanceof File || data.summary_file === null) {
        formData.append('summary_file',  data.summary_file)
      }
      if (data.detail_file instanceof File || data.detail_file === null) {
        formData.append('detail_file',  data.detail_file)
      }
      if (data.extra_file instanceof File || data.extra_file === null) {
        formData.append('extra_file',  data.extra_file)
      }

      // Los enviamos
      const res = await fetch(`${config.baseUrl}/presentations/${ id }`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('accessToken')
        },
        body: formData
      })

      if (res.error) {
        console.log(res.error)
        return alert(true, 'Ha ocurrido un error, por favor intente nuevamente.')
      }

      return alert(true, 'Se actualizó correctamente la presentación.')
    } catch (err) {
      alert(true, 'Ha ocurrido un error, por favor intente nuevamente.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    ApiService.getOne('presentations', id, { simple: '1' })
    .then(({ payload }) => {
      setData({
        insurer_id: payload.insurer_id,
        submission_id: payload.submission_id,
        summary_file: payload.files.find(f => f.type === 'summary_file') || '',
        detail_file: payload.files.find(f => f.type === 'detail_file') || '',
        extra_file: payload.files.find(f => f.type === 'extra_file') || ''
      })
    })
    .finally(() =>{
      setIsLoading(false)
    })
  }, [id])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Modificar Presentación #{ id }</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/presentations'>
                <Button color="primary" size="md">
                  Volver
                </Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              data={ data }
              setData={ setData }
              isLoading={ isLoading }
              onSubmit={ submit }
              update
            />
          </CardBody>
        </Card>
      </Container>
      <AlertModal
        isOpen={ showAlert }
        message={ message }
        onClose={() => alert(false)}
      />
    </>
  )
}

export default Edit
