import config from 'config';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, Row } from 'reactstrap';
import { ParsedRequest } from 'views/Requests/Editor';
import AlertError from 'components/AlertError';
import { getErrorMessage } from 'utils/errors';
import { useAlertError } from 'hooks';
import { RawRequest } from '@cokiba/types';

interface SessionsEditorProps {
  request: ParsedRequest;
  setLoading: (loading: boolean) => void;
  updateRequest: (request: RawRequest) => void;
}

interface SessionMoveStatus {
  status: string;
  message?: string;
}

export default function SessionsEditor(props: SessionsEditorProps) {
  const { request, updateRequest, setLoading } = props;

  const [selectedSessions, setSelectedSessions] = useState(new Set<number>());
  const [sessionsMoveTarget, setSessionsMoveTarget] = useState('');
  const [sessionsMoveStatus, setSessionsMoveStatus] = useState<SessionMoveStatus>({
    status: 'idle',
  });

  const [showError, messageError, detailsError, callbackError, alertError] = useAlertError();

  const handleSessionsSelection = (checked: boolean, id: number) => {
    const newSelection = new Set<number>(selectedSessions);

    if (checked && !newSelection.has(id)) {
      newSelection.add(id);
    } else if (!checked && newSelection.has(id)) {
      newSelection.delete(id);
    } else {
      return;
    }

    setSelectedSessions(newSelection);
  };

  const handleSessionsMove = async () => {
    try {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        return;
      }

      setLoading(true);

      if (selectedSessions.size === 0) {
        setSessionsMoveStatus({
          status: 'error',
          message: 'No se seleccionaron sesiones para mover',
        });
        return;
      }

      const response = await fetch(`${ config.baseUrl }/requests/move_sessions`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source_id: request.id,
          target_id: sessionsMoveTarget,
          sessions_id: Array.from(selectedSessions),
        }),
      });

      const body = await response.json();

      if (body.status !== 'ok') {
        if (body.message && body.error) {
          alertError(body.message, body.error);
          return;
        }

        if (body.message) {
          setSessionsMoveStatus(body);
          return;
        }

        alertError('Error desconocido intentando realizar la operación', response);
        return;
      }

      if (body.data) {
        updateRequest(body.data);
      } else {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
      alertError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col>
        <FormGroup>
          <ul>
            {
              request.sessionDates && Array.isArray(request.sessionDates)
                ? request.sessionDates
                  .sort((a, b) => a.date.valueOf() - b.date.valueOf())
                  .map((session, i) => (
                    <li key={i}>
                      <Input
                        type="checkbox"
                        name="sessionsSelected"
                        id={ `session_${ session.id }` }
                        value={ session.id }
                        onChange={ event => handleSessionsSelection(event.target.checked, session.id) }
                      />
                      <label htmlFor={ `session_${ session.id }` }>
                      Sesión { i + 1}:&nbsp;
                        { moment(session.date).format('DD/MM/YYYY') }&nbsp;
                        { session.approvalNumber ? ' - Autorización n° ' + session.approvalNumber : ''}
                        { session.preAuth
                          ? request.ooss_id === 66
                            ? ' - Formulario 4: ' + session.preAuth
                            : ' - Preautorización: ' + session.preAuth
                          : ''
                        }
                      </label>
                    </li>
                  ))
                : '-'
            }
          </ul>
          <div>
            <InputGroup size="sm">
              <Input
                type="text"
                disabled={ ![1, 2, 3, 4].includes(request.estado_id) || selectedSessions.size === 0 }
                placeholder="# de Solicitud"
                value={ sessionsMoveTarget }
                onChange={ event => setSessionsMoveTarget(event.target.value) }
              />
              <Button
                color="warning"
                disabled={ ![1, 2, 3, 4].includes(request.estado_id) || selectedSessions.size === 0 }
                size="sm"
                outline={ true }
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={ handleSessionsMove }
              >
                Mover sesiones seleccionadas
              </Button>
            </InputGroup>
            {
              sessionsMoveStatus.status === 'error'
                ? <small className="form-text text-red">{ sessionsMoveStatus.message }</small>
                : null
            }
            <br />
          </div>
        </FormGroup>
        <AlertError isOpen={ showError } message={ messageError } details={ detailsError } onClose={ callbackError } />
      </Col>
    </Row>
  );
}
