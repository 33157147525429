import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody,
  Form,
  FormGroup,
  Input,
  FormText,
  Label } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ApiService, getDelegationObject } from '../../services';
import { useUser } from 'context';
import Select, { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { Insurer, RawRole, RawUser } from '@cokiba/types';
import { delegationsArray as delegations, Delegation } from 'services';

type Errors = Partial<Record<keyof RawUser, boolean>>;

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
// eslint-disable-next-line max-len
const mailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

function Edit() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [data, setData] = useState<Partial<RawUser>>();
  const [roles, setRoles] = useState<RawRole[]>([]);
  const [insurers, setInsurers] = useState<Insurer[]>([]);

  const [errors, setErrors] = useState<Errors>({});

  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState(true);

  const user = useUser();

  useEffect(() => {
    if (!id) {
      return;
    }

    if (user.getId() !== parseInt(id) && user.isNot(1)) {
      navigate('/admin/usuarios');
      return;
    }

    Promise.all([
      ApiService.getAll('users/roles').then(res => setRoles(res.payload)),
      ApiService.getAll('insurers').then(res => setInsurers(res.payload)),
      ApiService.getOne('users', id).then(res => setData(res)),
    ])
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const validate = () => {
    let fails = 0;

    const newErrors = { ...errors };

    // Nombre
    if (!data?.firstName || !data.firstName.trim()) {
      newErrors.firstName = true;
      fails++;
    }

    if (!data?.lastName || !data.lastName.trim()) {
      newErrors.lastName = true;
      fails++;
    }

    // Correo electrónico
    if (!data?.email || !data.email.trim()) {
      newErrors.email = true;
      fails++;
    } else if (!mailPattern.test(data.email)) {
      newErrors.email = true;
      fails++;
    }

    // Cambio de contraseña
    if (passwordChanged) {
      if (!data?.password || !passwordConfirmation) {
        newErrors.password = true;
        fails++;
      }

      if (data?.password !== passwordConfirmation) {
        newErrors.password = true;
        fails++;
      }
    }

    // Roles
    if (data?.roles?.length === 0) {
      newErrors.roles = true;
      fails++;
    }

    // Delegación
    if (data?.roles && data.roles.some(role => role.id == 2) && !data?.delegation) {
      newErrors.delegation = true;
      fails++;
    }

    // Obra social
    if (data?.roles && data.roles.some(role => role.id == 6) && !data?.insurer_id) {
      newErrors.insurer = true;
      fails++;
    }

    setErrors(newErrors);

    return fails == 0;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    await ApiService.update('users', id, {
      ...data,
      action: 'updateUser',
    });

    navigate('/admin/usuarios', {
      state: { success: true },
    });
  };

  const handleSelector = (
    value: MultiValue<RawRole> | SingleValue<Delegation | Insurer>,
    action?: ActionMeta<RawRole | Delegation | Insurer>,
  ) => {
    if (!action?.name) {
      return;
    }

    switch (action.name) {
      case 'delegation_id':
        setData({
          ...data,
          // @ts-ignore
          delegation: value ? value.id : null,
        });
        break;
      case 'role_id':
        setData({
          ...data,
          // @ts-ignore
          roles: value,
        });
        break;
      case 'insurer_id':
        if (!value) {
          setData({
            ...data,
            insurer_id: null,
            insurer: undefined,
          });
          break;
        }

        setData({
          ...data,
          // @ts-ignore
          insurer_id: value.id,
          // @ts-ignore
          insurer: value,
        });
        break;
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'password':
        setData({
          ...data,
          password: event.target.value,
        });
        setPasswordChanged(true);
        break;
      case 'confirmation':
        setPasswordConfirmation(event.target.value);
        break;
      case 'firstName':
      case 'lastName':
      case 'email':
        setData({
          ...data,
          [event.target.name]: event.target.value,
        });
    }
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            {
              !isLoading &&
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Editar Usuario</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link to='/admin/usuarios'>
                        <Button color="primary" size="md">Volver</Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">Información de usuario</h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label className="form-control-label">Nombre</Label>
                            <Input
                              autoComplete="given-name"
                              type="text"
                              name='firstName'
                              value={ data?.firstName ?? '' }
                              placeholder='Nombre'
                              onChange={ handleChange }
                              invalid={ errors.firstName }
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label className="form-control-label">Apellido</Label>
                            <Input
                              autoComplete="family-name"
                              placeholder="Apellido"
                              type="text"
                              name='lastName'
                              value={ data?.lastName ?? ''}
                              onChange={ handleChange }
                              invalid={ errors.lastName }
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label className="form-control-label">Email</Label>
                            <Input
                              autoComplete="email"
                              id="input-email"
                              name='email'
                              value={ data?.email ?? '' }
                              placeholder="example@example.com"
                              type="email"
                              onChange={ handleChange }
                              invalid={ errors.email }
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label className="form-control-label">Contraseña</Label>
                            <Input
                              name='password'
                              autoComplete="new-password"
                              value={ data?.password || '' }
                              type="password"
                              onChange={ handleChange }
                              invalid={ errors.password }
                            />
                            {
                              passwordChanged && data?.password !== passwordConfirmation
                                ? <FormText color="red">Las contraseñas ingresadas no coinciden</FormText>
                                : null
                            }
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label className="form-control-label">Repetir Contraseña</Label>
                            <Input
                              type="password"
                              name='confirmation'
                              autoComplete="new-password"
                              value={ passwordConfirmation || '' }
                              onChange={ handleChange }
                              invalid={ passwordChanged && data?.password !== passwordConfirmation }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {
                        roles.length > 0
                          ? <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <Label className="form-control-label">Roles</Label>
                                  <Select<RawRole, true>
                                    isMulti
                                    className="form-control-alternative"
                                    name="role_id"
                                    options={ roles }
                                    value={
                                      data?.roles
                                        ? data.roles
                                        : []
                                    }
                                    onChange={ handleSelector }
                                    getOptionLabel={ opt => opt.name }
                                    getOptionValue={ opt => opt.id.toString() }
                                    required
                                  />
                                  {
                                    errors.roles
                                      ? <FormText color="red" style={{ fontSize: '.85rem', fontWeight: 600 }}>
                                          Debe seleccionar al menos un rol para el usuario
                                        </FormText>
                                      : null
                                  }
                                </FormGroup>
                              </Col>
                            </Row>
                          : null
                      }
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label className="form-control-label">Delegación</Label>
                            <Select<Delegation>
                              className="form-control-alternative"
                              name="delegation_id"
                              options={ delegations }
                              value={
                                data?.delegation
                                  ? getDelegationObject(data.delegation)
                                  : {
                                      id: '-1',
                                      name: 'Sin delegación',
                                    }
                              }
                              onChange={ handleSelector }
                              getOptionLabel={ opt => opt.name }
                              getOptionValue={ opt => opt.id }
                              isClearable
                            />
                            {
                              errors.delegation
                                ? <FormText color="red" style={{ fontSize: '.85rem', fontWeight: 600 }}>
                                    Debe seleccionar al menos una delegación para el usuario
                                  </FormText>
                                : null
                            }
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label className="form-control-label">Obra Social</Label>
                            <Select<Insurer>
                              className={`form-control-alternative` }
                              name="insurer_id"
                              options={ insurers }
                              value={
                                data?.insurer
                                  ? data.insurer
                                  : {
                                      id: -1,
                                      codigo: '',
                                      numero: -1,
                                      nombre: 'Sin obra social',
                                    }
                              }
                              onChange={ handleSelector }
                              getOptionLabel={ opt => opt.nombre }
                              getOptionValue={ opt => opt.id.toString() }
                              isClearable
                            />
                            {
                              errors.insurer
                                ? <FormText color="red" style={{ fontSize: '.85rem', fontWeight: 600 }}>
                                    Debe seleccionar al menos una obra social para el usuario
                                  </FormText>
                                : null
                            }
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => handleSubmit()}
                            disabled={ isLoading }
                          >
                            Guardar
                          </Button>
                          <Link to='/admin/usuarios'>
                            <Button
                              className="btn-round"
                              color="warning"
                              disabled={ isLoading }
                            >
                            Cancelar
                            </Button>
                          </Link>
                        </div>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            }
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Edit;
