import React from 'react'
import CollapsibleCard from '../../components/CollapsibleCard'
import { Col, Row, FormGroup, Input } from 'reactstrap'

function MatriculateRequestFilters(props) {

  const { changeFilter, currentOptions } = props

  return (
    <CollapsibleCard title="Filtros" open={ currentOptions.state_id && currentOptions.state_id !== 'any' }>
      <Row>
        <Col sm={4}>
          <FormGroup>
            <label>Estado</label>
            <Input
              type="select"
              onChange={ (event) => changeFilter('state_id', event.target.value) }
              value={ currentOptions?.state_id ? currentOptions.state_id : 'any' }
            >
              <option value="any">Todas</option>
              <option value="1">Solicitada</option>
              <option value="2">Aceptada</option>
              <option value="3">Rechazada</option>
            </Input>
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <label>Matrícula</label>
            <Input
              type="number"
              onChange={ (event) => changeFilter('search', event.target.value.trim()) }
              value={ currentOptions?.search ? currentOptions.search : '' }
            />
          </FormGroup>
        </Col>
      </Row>
    </CollapsibleCard>
  )
}

export default MatriculateRequestFilters
