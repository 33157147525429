import { ParsedSession } from 'views/Requests/Edit';

export function getVencimiento(sessionDates: ParsedSession[]) {
  if (!sessionDates.length) {
    return null;
  }

  const today = new Date();

  const orderedSessionDates = sessionDates
    .sort((a, b) => { // Ordenamos las fechas
      return (new Date(a.date).getTime() - new Date(b.date).getTime());
    });

  const firstSessionDate = orderedSessionDates[0].date;

  const diffTime = Math.abs(today.getTime() - firstSessionDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Diferencia en días

  return diffDays;
}

export function getColor(diffDays: number) {
  if (diffDays <= 30) {
    return '#7ac29a'; // Verde
  } else if (diffDays < 60) {
    return '#F5D63D'; // Amarillo
  } else {
    return '#eb5e28'; // Rojo
  }
}
