import React, { useState, useEffect } from 'react'
import { ReactDevicePreview } from '@robertito13/react-device-preview'
import { Button, Row, Col, Form, Input, FormGroup } from 'reactstrap'
import Select from 'react-select/async'
import { ApiService, delegations } from 'services'
import { useAlertPlus } from '../../hooks'
import AlertModal from '../../components/AlertModal'

import './Form.scss'

function NotificationForm (props) {
  const { data, isLoading, onSend, onDraft, onDelete, setData } = props

  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')

  const [ titlePreview, setTitlePreview ] = useState()
  const [ contentPreview, setContentPreview ] = useState()
  const [ writable, setWritable ] = useState(false)

  const [ required, setRequired ] = useState({})

  const send = () => {
    if (!onSend) {
      return
    }

    let errors = false
    const newRequired = {}

    if (data.title.trim() === '') {
      newRequired['title'] = true
      errors = true
    }

    if (data.content.trim() === '') {
      newRequired['content'] = true
      errors = true
    }

    if (!data.delegation && !(data.targets && data.targets.length)) {
      newRequired['target'] = true
      errors = true
    }

    if (errors) {
      setRequired(newRequired)
      alert('Es necesario completar el título, el contenido e indicar un objetivo para poder enviar la notificación')
      return
    }

    onSend()
  }

  const draft = () => {
    if (!onDraft) {
      return
    }

    onDraft()
  }

  const erase = () => {
    if (!onDelete) {
      return
    }

    onDelete()
  }

  const handleInputChange = (fieldName, value) => {
    setData({
      ...data,
      [fieldName]: value
    })
  }

  const loadMatriculate = async (input, callback) => {
    const res = await ApiService.getAll('matriculates', {
      search: input
    })

    if (!res || res.status !== 'ok' || !res.payload || !res.payload.rows) {
      callback([])
    }

    const data = res.payload.rows.map(m => ({
      value: m.matricula,
      label: `${ m.apellido } ${ m.nombre }`
    }))

    callback(data)
  }

  const handleMatriculatesChange = async (value) => {
    setData({
      ...data,
      targets: value
    })
  }

  const targetsSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: `1px solid ${ required.target ? '#fb6340' : '#cad1d7' }`,
      borderRadius: '0.375rem',
      fontSize: '0.875rem'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#8898aa'
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '0.75rem'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    })
  }

  useEffect(() => {
    let newTitle = data.title ? data.title : 'Título'
    if (data.title && data.title.length > 35) {
      newTitle = newTitle.substring(0, 35) + '…'
    }
    setTitlePreview(newTitle)

    let newContent = data.content ? data.content : 'Mensaje'
    if (data.content && data.content.length > 150) {
      newContent = newContent.substring(0, 150) + '…'
    }
    setContentPreview(newContent)
  }, [data.title, data.content])

  useEffect(() => {
    if (data.state_id !== 1) {
      setWritable(false)
      return
    }

    setWritable(true)
  }, [data.state_id])

  return (
    <Form>
      <h6 className="heading-small text-muted mb-4">COMPLETE LOS DATOS</h6>
      <Row>
        <Col sm={6}>
          <Row className="form-group mb-4">
            <Col>
              <FormGroup>
                <label className="form-control-label">Título</label>
                <Input
                  type="text"
                  value={ data.title }
                  maxLength={ 35 }
                  name="title"
                  disabled={ isLoading || !writable }
                  onChange={ (event) => handleInputChange(event.target.name, event.target.value) }
                  className={ required.title ? 'is-invalid' : '' }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="form-group mb-4">
            <Col>
              <FormGroup>
                <label className="form-control-label">Mensaje</label>
                <Input
                  type="textarea"
                  value={ data.content }
                  name="content"
                  rows={5}
                  maxLength={500}
                  disabled={ isLoading || !writable }
                  onChange={ (event) => handleInputChange(event.target.name, event.target.value) }
                  className={ required.content ? 'is-invalid' : '' }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="heading-small text-muted mb-4">OBJETIVOS</h6>
            </Col>
          </Row>
          {
            delegations
            ? <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label">Delegación</label>
                    <Input
                      type="select"
                      name="delegation"
                      value={ data.delegation ?? 'DEFAULT' }
                      onChange={ (event) => handleInputChange(event.target.name, event.target.value) }
                      disabled={ isLoading || !writable }
                      className={ required.target ? 'is-invalid' : '' }
                    >
                      <option disabled value='DEFAULT' hidden>Ninguna</option>
                      {
                        Object
                        .entries(delegations)
                        .map(([key, value]) => (
                          <option key={`delegation_${ key }`} value={ key }>{ value }</option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            : null
          }
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label">Matriculados</label>
                <Select
                  placeholder="Buscar matriculado..."
                  className={ `form-control-alternative ${ required.title ? 'is-invalid' : '' }` }
                  name="targets"
                  loadOptions={ loadMatriculate }
                  isMulti
                  cacheOptions
                  defaultOptions
                  value={ data.targets ?? [] }
                  onChange={ handleMatriculatesChange }
                  loadingMessage={ () => "Buscando..." }
                  noOptionsMessage={ () =>  "No hay matriculados para mostrar" }
                  styles={ targetsSelectStyles }
                  isDisabled={ isLoading || !writable }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="form-group mb-4">
            <Col className="text-center">
              <Button
                className={`btn-round ${ !writable  || !data.id ? 'd-none' : '' }`}
                color="danger"
                title="Eliminar Borrador Notificación"
                disabled={ isLoading || !writable || !data.id }
                onClick={ erase }
              >
                Eliminar
              </Button>
              <Button
                className={`btn-round ${ !writable ? 'd-none' : '' }`}
                color="primary"
                title="Guardar Borrador Notificación"
                disabled={ isLoading || !writable }
                onClick={ draft }
              >
                Guardar
              </Button>
              <Button
                className={`btn-round ${ !writable ? 'd-none' : '' }`}
                color="success"
                title="Enviar Nueva Notificación"
                disabled={ isLoading || !writable }
                onClick={ send }
              >
                Enviar
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <ReactDevicePreview device="galaxys5">
            <div className="notification">
              <div className="header">
                <img src={ require('../../assets/img/logo_cokiba.png') } alt="" />
                <span className="source">Colegio de Kinesiologos</span> <strong>&#183;</strong> <span className="time">ahora</span>
              </div>
              <strong>{ titlePreview }</strong><br />
              <span className="content">{ contentPreview }</span>
            </div>
          </ReactDevicePreview>
        </Col>
      </Row>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
    </Form>
  )
}

export default NotificationForm