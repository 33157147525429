import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom"
import UserForm from "./UserForm";
import { ApiService } from "services"
import { useAlertPlus } from 'hooks'
import AlertModal from 'components/AlertModal'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  rePassword: '',
  roles: [],
  delegation: ''
}

const initialFieldClass = initialValues

function Create () {
  const navigate = useNavigate() 

  const [ data, setData ] = useState(initialValues)
  const [ fieldClass, setFieldClass ] = useState(initialFieldClass)
  const [ isValidated, setIsValidated ] = useState(true)

  const [ alertShow, alertMessage, alertCallback, alert ] = useAlertPlus('')

  const validate = () => {
    const classes = {}
    let isValidated = true
    for (const key in data) {
      if (!data[key] || data[key] === '') {
        if (key === 'delegation' && !data.roles.includes(2)) {
          continue
        }

        isValidated = false
        Object.assign(classes, {
          [key]: 'required'
        })
      } else {
        Object.assign(classes, {
          [key]: ''
        })
      }
    }

    if (!isValidated) {
      setFieldClass({ ...fieldClass, ...classes })
    }
    return isValidated
  }

  const submit = async () => {
    try {
      if (!validate()) {
        setIsValidated(false)
        return
      }

      const res = await ApiService.post('users', data)

      if (res.status !== 'ok') {
        if (res.message) {
          alert(res.message)
          return
        }

        alert('Se produjo un error al intentar crear el usuario. Por favor, intente nuevamente más tarde')
        return
      }

      navigate('/admin/usuarios', {
        state: { success: true }
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <UserForm
              action='create'
              title='Crear usuario'
              data={data}
              isValidated={isValidated}
              setFieldClass={ setFieldClass }
              fieldClass={fieldClass}
              setData={ setData }
              onSubmit={ submit }
            />
          </Col>
        </Row>
      </Container>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </>
  )
}

export default Create
