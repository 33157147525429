import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap'
import Form from './Form'
import { useAlertPlus } from '../../../hooks'
import AlertModal from '../../../components/AlertModal'
import config from 'config'
import { ApiService } from 'services'

function Edit () {
  const [ data, setData ] = useState({})

  const [ isLoading, setIsLoading ] = useState(false)
  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')

  const { id } = useParams()

  const handleSend = async (accept = false) => {
    try {
      setIsLoading(true)

      // Preparamos los datos
      const formData = new FormData()

      const res = await fetch(`${config.baseUrl}/matriculates/photos/${ id }/${ accept ? 'accept' : 'reject' }`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('accessToken')
        },
        body: formData
      })

      const response = await res.json()

      if (response?.status !== 'ok') {
        console.info(response)

        if (response.payload?.message) {
          alert(response.payload.message)
          return
        }

        alert('Ha ocurrido un error al enviar al servidor, por favor intente nuevamente.')
        return
      }

      alert('Se guardó correctamente la resolución.', () => {
        window.location = '/admin/matriculates/requests'
      })
    } catch (err) {
      console.error(err)
      alert('Ha ocurrido un error al guardar la resolución, por favor intente nuevamente.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)

    ApiService.getOne('matriculates/photos', id)
    .then(res => {
      if (res.status !== 'ok') {
        alert('Ha ocurrido un error al recuperar la solicitud, por favor intente nuevamente.')
        return
      }

      setData(res.payload)
    })
    .catch(err => {
      alert('Ha ocurrido un error al procesar la solicitud, por favor intente nuevamente.')
      console.error(err)
    })
    .finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Resolver Solicitud</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/matriculates/requests'>
                <Button color="primary" size="md">
                  Volver
                </Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              data={ data }
              setData={ setData }
              isLoading={ isLoading }
              onAccept={ () => handleSend(true) }
              onReject={ () => handleSend(false) }
            />
          </CardBody>
        </Card>
      </Container>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
    </>
  )
}

export default Edit
