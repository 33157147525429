import React from 'react'
import { Link } from "react-router-dom"

import { Button, Table } from "reactstrap"
import TableLoader from 'components/TableLoader'
import config from 'config'
import { useUser } from 'context/UserContext'

export default function (props) {
  const { items, isLoading, currentOrder, changeOrder, id } = props

  const user = useUser();

  const getIconClassBySorted = (field) => {
    if (currentOrder.field === field) {
      if (currentOrder.dir === -1) {
        return 'fa fa-caret-down'
      } else {
        return 'fa fa-caret-up'
      }
    }

    return 'fas fa-sort'
  }

  const setNewOrder = (field) => {
    // Si el campo elegido ya está seleccionado, sólo cambiamos el orden
    if (currentOrder && currentOrder.field === field) {
      changeOrder(
        currentOrder.field,
        currentOrder.dir === 1 ? -1 : 1
      )
    // Si es un nuevo campo, lo indicamos como seleccionado y reseteamos el orden
    // a ascendente
    } else {
      changeOrder(field, 1)
    }
  }

  const impersonate = async (matriculate_id) => {
    const response = await fetch(`${ config.baseUrl }/matriculates/admin/impersonate/${ matriculate_id }`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      }
    })

    // Chequeamos errores en la conexión
    if (!response || response.status !== 200) {
      alert('Se produjo un problema cuando se intentaba realizar la petición al servidor')
      return
    }

    const data = await response.json()

    // Chequeamos errores en el servidor
    if (data.status !== 'ok') {
      // Si viene un mensaje de error desde el servidor, lo mostramos
      if (data.payload?.message) {
        alert(data.payload.message)
        return
      }

      alert('Se produjo un problema cuando se intentaba generar las credenciales')
      return
    }

    window.open(data.payload, '_blank')
  }

  /**
   * Configuración de la cabecera de las columnas
   */
  const columns = [
    {
      name: 'id',
      label: 'N° Matrícula',
      sort: true,
      value: row => row.matricula
    },
    {
      name: 'delegation',
      label: 'Delegación',
      sort: true,
      value: row => row.delegacion
    },
    {
      name: 'name',
      label: 'Nombre',
      sort: true,
      value: row => `${ row.apellido }, ${ row.nombre }`
    },
    {
      name: 'actions',
      label: 'Acciones',
      value: row => {
        return (
          <>
            <Link to={`/admin/matriculates/${ row.matricula }/edit`} className="btn btn-round btn-primary btn-sm">
              <i className="fa fa-edit"></i>
            </Link>
            {
              user.is([1, 7])
              ? <Link to={`/admin/matriculates/${ row.matricula }/notifications`} className="btn btn-round btn-primary btn-sm">
                  <i className="far fa-bell"></i>
                </Link>
              : null
            }
            {
              user.is(1)
              ? <Button className="btn btn-round btn-warning btn-sm" onClick={ () => impersonate(row.matricula) }>
                  <i className="far fa-user"></i>
                </Button>
              : null
            }
          </>
        )
      }
    }
  ]

  return (
    <Table className="align-items-center table-flush" responsive id={ id }>
      <thead className="thead-light">
        <tr>
          {
            // Cabecera
            columns.map((column) => {
              if (!column.sort) {
                return <th key={column.name} scope='col' className={column.classes ?? ''}>{ column.label }</th>
              }

              return (
                <th key={column.name} scope='col' className={ `clickable ${column.classes ?? ''}` } onClick={ () => setNewOrder(column.name) }>
                  { column.label }
                  { <i className={ getIconClassBySorted(column.name) }></i> }
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          // Datos
          !isLoading
          ? Array.isArray(items) && items.length
            ? items.map(row =>
              <tr key={row.matricula}>
                { columns.map(column => <td key={ column.name } className={ column.classes ?? '' } >{ column.value(row) }</td>) }
              </tr>
            )
            : <tr>
                <td colSpan={columns.length + 1} className='text-center'>
                  <span>No se encontraron matriculados</span>
                </td>
              </tr>
          : <tr>
              <td colSpan={columns.length + 1} className='text-center'>
                <TableLoader />
              </td>
            </tr>
        }
      </tbody>
    </Table>
  )
}