import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Table } from 'reactstrap'
import TableLoader from 'components/TableLoader'

function OOSSTable (props) {
  const { service, fields, items, isLoading } = props

  const getValues = (item) => (
    fields.map(({ field }) => (
      <td key={field}>
        {
          field === 'createdAt' || field === 'updatedAt' || field.includes('fecha')
          ? new Date(item[field]).toLocaleString('es')
          : field === 'isEnabled' || field === 'isEnabledApp'
            ? item[field] ? 'Sí' : 'No'
            : item[field] || '-'
        }
      </td>
    ))
  )
  
  const sort = (field, obj) => {
    //
  }

 
  return (
    <>
      <Table className='align-items-center table-flush' responsive>
        <thead className="thead-light">
          <tr>
            {
              fields.map(({ name, field, sortObj }) => (
                <th
                  key={name}
                  scope='col'
                  onClick={ () => sort(field, sortObj) }
                  className={ 'clickable' }
                >
                  { name }&nbsp;
                  {/* <i className={ getIconClass(name) }></i> */}
                </th>
              ))
            }
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {
            !isLoading ?
            items.length ?
            items.map(row => {
              return (
                <tr key={row.id}>
                  { getValues(row) }
                  <td className="">
                    <Link to={`/admin/${service}/${row.id}`}>
                      <Button size='sm' className='btn-round' color='primary'>
                        <i className="fa fa-edit"></i>
                      </Button>
                    </Link>
                  </td>
                </tr>
              )
            })
            :
            // content empty
            <tr>
              <td
                colSpan={fields.length + 1}
                className='text-center'
              >
                <span>No se encontraron obras sociales</span>
              </td>
            </tr>
            :
            // loading state
            <tr>
              <td
                colSpan={fields.length + 1}
                className='text-center'
              >
                <TableLoader />
              </td>
            </tr>
          }
        </tbody>
      </Table>
    </>
  )
}

export default OOSSTable