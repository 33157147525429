import React from 'react'
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Container } from 'reactstrap'

function Help () {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className='card-user'>
              <CardHeader>
                <Row>
                  <Col sm={8}>
                    <CardTitle tag='h4'>Ayuda</CardTitle>
                  </Col>
                  <Col sm={4}></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="pt-4 pb-4">
                  <Col sm={6}>
                    <h5>Manual Interno para Usuario</h5>
                    <div className="mb-4 font-weight-bold">
                      <a href="/pdf/manual_auditoria.pdf" target="_blank" rel="noopener noreferrer">Ver Manual</a>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Help
