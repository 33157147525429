import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap'
import Form from './Form'
import { useAlert } from '../../hooks'
import AlertModal from '../../components/AlertModal'
import config from 'config'

const initialValues = {
  submission_id: '',
  summary_file: '',
  detail_file: '',
  extra_file: ''
}

function Create () {
  const [data, setData] = useState(initialValues)

  const [isLoading, setIsLoading] = useState(false)
  const [showAlert, message, alert] = useAlert(false, '')

  const submit = async () => {
    try {
      setIsLoading(true)

      if (!data.insurer_id || data.insurer_id === '-1') {
        alert(true, 'Tiene que ingresar seleccionar la obra social para poder crear una nueva presentación.')
        return
      }

      if (!data.submission_id) {
        alert(true, 'Tiene que ingresar el número de remito para poder crear una nueva presentación.')
        return
      }

      // Preparamos los datos
      const formData = new FormData()
      formData.append('submission_id', data.submission_id)
      formData.append('insurer_id', data.insurer_id)

      if (data.summary_file instanceof File || data.summary_file === null) {
        formData.append('summary_file',  data.summary_file)
      }
      if (data.detail_file instanceof File || data.detail_file === null) {
        formData.append('detail_file',  data.detail_file)
      }
      if (data.extra_file instanceof File || data.extra_file === null) {
        formData.append('extra_file',  data.extra_file)
      }

      // Los enviamos
      const res = await fetch(`${config.baseUrl}/presentations`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('accessToken')
        },
        body: formData
      })

      const response = await res.json()

      if (response?.status !== 'ok') {
        console.info(response)

        if (response.payload?.message) {
          alert(true, response.payload.message)
          return
        }

        alert(true, 'Ha ocurrido un error, por favor intente nuevamente.')
        return
      }

      window.location = '/admin/presentations'
    } catch (err) {
      console.error(err)
      alert(true, 'Ha ocurrido un error, por favor intente nuevamente.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Nueva Presentación</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/presentations'>
                <Button color="primary" size="md">
                  Volver
                </Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              data={ data }
              setData={ setData }
              isLoading={ isLoading }
              onSubmit={ submit }
            />
          </CardBody>
        </Card>
      </Container>
      <AlertModal
        isOpen={ showAlert }
        message={ message }
        onClose={() => alert(false)}
      />
    </>
  )
}

export default Create
