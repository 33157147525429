import React, { useState, useEffect } from 'react';
import { Container, Row, Form, Col, Card, CardHeader, CardBody, Input, FormGroup, Button } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '../../services';
import Select from 'react-select';
import { useUser } from 'context';
import { RawUser } from '@cokiba/types';

function Edit() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [data, setData] = useState<RawUser>();

  const user = useUser();

  useEffect(() => {
    // Si no hay id volvemos a la pantalla de administración
    if (!id) {
      navigate('/admin/usuarios');
      return;
    }

    // Si el id no es del usuario actual o no es administrador volvemos a la pantalla de administración
    if (user.getId() !== parseInt(id) && user.isNot(1)) {
      navigate('/admin/usuarios');
      return;
    }

    ApiService
      .getOne('users', id)
      .then(res => {
        setData(res);
      })
      .catch(console.error);
  }, [id]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Información Usuario</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to='/admin/usuarios'>
                      <Button color="primary" size="md">Volver</Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">Información de usuario</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">Nombre</label>
                          <Input
                            className="form-control-alternative"
                            value={ data?.firstName ?? '' }
                            disabled={ true }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-last-name">Apellido</label>
                          <Input
                            className="form-control-alternative"
                            value={ data?.lastName ?? ''}
                            disabled={ true }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-email">Email</label>
                          <Input
                            className="form-control-alternative"
                            value={ data?.email ?? '' }
                            disabled={ true }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">Roles</label>
                          <Select
                            className="form-control-alternative"
                            isMulti
                            isDisabled={ true }
                            value={ data?.roles?.map((r: any) => ({ value: r.id, label: r.name })) ?? [] }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {
                      data?.insurer?.nombre
                        ? <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-first-name">Obra Social</label>
                                <Input
                                  className="form-control-alternative"
                                  value={ data.insurer.nombre }
                                  disabled={ true }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        : null
                    }
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Edit;
