import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup } from 'reactstrap';
import { ParsedRequest } from 'views/Requests/Editor';
import { SetFunction } from 'hooks';
import { ApiService, getStatusName } from 'services';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface NewStateProps {
  request: ParsedRequest;
  updateRequest: (request: ParsedRequest) => void;
  alertError: SetFunction;
}

function NewState(props: NewStateProps) {
  const { request, alertError, updateRequest } = props;
  const [options, setOptions] = useState<number[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState(request.estado_id);
  const { id: rawId } = useParams();

  useEffect(()=>{
    const newOptions = getOptions(request);
    setOptions(newOptions);
  }, []);

  if (!rawId) {
    window.location.href = '/solicitudes';
    return <></>;
  }

  const id = parseInt(rawId);

  const getOptions = (request: ParsedRequest): number[] => {
    switch (request.estado_id) {
      case 1:
        if (request.sessionDates.length > 0) {
          return [1, 2, 3, 10];
        }
        return [1, 10];
      case 2:
        return [1, 2, 3, 10];
      case 3:
        return [1, 2, 3, 10];
      case 4:
        return [2, 3, 4];
      case 10:
        if (request.sessionDates.length > 0) {
          return [1, 2, 3, 10];
        }
        return [1, 10];
      default:
        return [];
    }
  };
  const submitState = async () => {
    try {
      setLoading(true);

      const data = {
        estado_id: state,
      };
      const res = await ApiService.updateState(id, data);
      if (res.status != 'ok' ) {
        console.error(res);
        alertError('Se produjo un error al intentar actualizar el estado');
        return;
      }
      updateRequest({ ...request, estado_id: res.payload.estado_id });
      toast('Estado de solicitud actualizado');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <label className="form-control-label">Estado de solicitud </label>
      {
        options.length > 0
          ? <InputGroup>
              <Input
                width={250}
                type='select'
                onChange={event => setState(parseInt(event.target.value))}
                defaultValue={request.estado_id}
                disabled={isLoading}>
                {options.map( opt =>
                  <option value={opt} key={opt}>{ getStatusName(opt) }</option>)}
              </Input>
              <Button
                className="btn-round"
                color="warning"
                size="md"
                outline={true}
                onClick={() => submitState()}
                disabled={isLoading || request.estado_id == state}
              >
                Cambiar
              </Button>
            </InputGroup>
          :<p>{ getStatusName(request.estado_id)}</p>
      }
    </div>
  );
}

export default NewState;
