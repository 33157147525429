import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { ApiService } from 'services'
import UserForm from '../views/Users/UserForm'

import { useAlert } from 'hooks'
import AlertModal from 'components/AlertModal'
import { useUser } from 'context/UserContext'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
}

const initialFieldClass = initialValues

function Edit () {
  const user = useUser()

  const [showAlert, message, alert] = useAlert(false, '')

  const [data, setData] = useState(initialValues)
  const [isLoading, setIsLoading] = useState(true)
  const [fieldClass, setFieldClass] = useState(initialFieldClass)
  const [isValidated, setIsValidated] = useState(true)

  useEffect(() => {
    ApiService.getOne('users', user.getId())
    .then(res => {
      setData({
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email
      })

      setIsLoading(false)
    })
  }, [user])

  const validate = () => {
    const classes = {}
    let isValidated = true
    for (const key in data) {
      if (!data[key] || data[key] === '') {
        isValidated = false
        Object.assign(classes, {
          [key]: 'required'
        })
      } else {
        Object.assign(classes, {
          [key]: ''
        })
      }
    }

    if (!isValidated) {
      setFieldClass({ ...fieldClass, ...classes })
    }
    return isValidated
  }

  const submit = async () => {
    const isValidated = validate()
    if (!isValidated) {
      setIsValidated(false)
      return
    }

    try {
      setIsLoading(true)

      const res = await ApiService.update('users', user.id, data)

      setIsLoading(false)

      setData({
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email
      })

      alert(true, 'Perfil actualizado correctamente')
    } catch(err) {
      console.log(err)
      alert(true, 'Ocurrió un error al intentar actualizar el perfil. Por favor, intente nuevamente más tarde')
    }
  }

  return (
    <>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ () => alert(false) } />
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            {
                !isLoading &&
                <UserForm
                title='Editar perfil'
                action='profile'
                data={data}
                setData={ setData }
                isValidated={ isValidated }
                setFieldClass={ setFieldClass }
                fieldClass={fieldClass}
                onSubmit={ submit }
              />
            }
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Edit
