import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Form, FormGroup, Container, Row, Col, Card, CardHeader, CardBody, Button, Input } from 'reactstrap'
import { useAlertPlus } from '../../hooks'
import AlertModal from '../../components/AlertModal'
import config from 'config'
import { ApiService } from '../../services'

import './Edit.scss'

function Edit () {
  const { id } = useParams()

  const [ data, setData ] = useState({
    matriculate_id: '',
    files: []
  })

  // Tipos de archivo soportados
  const validMimeTypes = [
    'image/jpg',
    'image/jpeg',
    'image/png'
  ]

  const validLabelTypes = [
    'JPEG',
    'PNG'
  ]
  const categoryOptions = [
    { id: 1, name: 'A'},
    { id: 2, name: 'B'},
    { id: 3, name: 'C'},
    { id: 5, name: 'I'},
  ]
  const [ avatar, setAvatar ] = useState(null)
  const fileInput = useRef(null)

  const [ isLoading, setIsLoading ] = useState(true)
  const [ alertShow, alertMessage, alertCallback, alert ] = useAlertPlus('')
  const [comeiCensus, setComeiCensus] = useState(null)

  useEffect(() => {
    ApiService.getOne('matriculates', id)
    .then(({ payload }) => {
      setData(payload)
      const direccion = payload.comeiCensus?.direccion || `${payload.direccion.calle} ${payload.direccion.numero}`
      const comeiData = {
        matricula: payload.comeiCensus?.matricula || payload.matricula,
        sucursal: payload.sucursal || payload.comeiCensus?.sucursal || 1, 
        prestador: payload.comeiCensus?.prestador || null,
        delegacion: payload.delegacion || payload.comeiCensus?.delegacion, 
        categoria: payload.categoria || payload.comeiCensus?.categoria,
        direccion,
      };
      setComeiCensus(comeiData);
    })
    .finally(() =>{
      setIsLoading(false)
    })
  }, [id])

  useEffect(() => {
    ApiService.getAll(`matriculates/${ id }/photos`)
    .then(({ payload }) => {
      setAvatar(payload)
    })
    .finally(() =>{
      setIsLoading(false)
    })
  }, [id])

  const uploadPhoto = async () => {
    setIsLoading(true)

    try {
      // Verificamos si hay archivos seleccionado
      if (!fileInput.current.files.length) {
        return
      }

      const newFile = fileInput.current.files[0]

      // Controlamos el tamaño del archivo
      if (newFile.size > config.maxFileSize) {
        alert(`El tamaño del archivo seleccionado es demasiado grande. Límite: ${ Math.round(config.maxFileSize / 1024 / 1024 * 100) / 100 } MB`)
        return
      }

      // Controlamos el tipo de archivo
      if (!validMimeTypes.includes(newFile.type)) {
        alert(`El formato de archivo seleccionado no está soportardo. Tipos aceptados: ${ validLabelTypes.join(', ') }`)
        return
      }

      // Enviamos el archivo al servidor
      const formData = new FormData()
      formData.append('avatar', newFile);
      const res = await fetch(`${ config.baseUrl }/matriculates/${ id }/photos`, {
        method: 'post',
        headers: {
          Authorization: localStorage.getItem('accessToken')
        },
        body: formData
      })

      // Chequeamos errores en la conexión
      if (!res || res.status !== 200) {
        alert('Se produjo un problema cuando se intentaba subir la foto. Por favor, intente nuevamente más tarde')
        return
      }

      const response = await res.json()

      // Chequeamos errores en el servidor
      if (response.status !== 'ok') {
        // Si viene un mensaje de error desde el servidor, lo mostramos
        if (response.payload?.message) {
          alert(response.payload.message)
          return
        }

        alert('Se produjo un problema cuando se procesar la foto subida. Por favor, intente nuevamente más tarde')
        return
      }

      // Seteamos la nueva foto
      setAvatar(response.payload)
    } catch (err) {
      alert('Se produjo un problema cuando se intentaba subir la foto. Por favor, intente nuevamente más tarde')
      console.error(err)
    } finally {
      // Borramos la selección del file input
      fileInput.current.value = ''

      setIsLoading(false)
    }
  }

  const changePhoto = () => {
    fileInput.current.click()
  }

  const removePhoto = () => {
    setIsLoading(true)

    ApiService.delete('matriculates', `${ id }/photos`)
    .then(({ status }) => {
      if (status !== 'ok') {
        alert('Hubo un problema al intentar eliminar la foto. Por favor intente nuevamente más tarde')
        return
      }

      setAvatar(null)
    })
    .catch(err => {
      alert('Hubo un problema al intentar eliminar la foto. Por favor intente nuevamente más tarde')
      console.error(err)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }
  const submitComeiCensus = async () => {
    console.log('Submiot',comeiCensus);
    if(!comeiCensus.prestador){
      alert('Error: Se necesita un numero de prestador');
      return;
    }
    try {
      await ApiService.updateComeiCensus('matriculates',id,comeiCensus);
    } catch (error) {
      console.log(error)
      alert('Hubo un problema al intentar actualizar el padrón COMEI. Por favor intente de nuevo mas tarde')
    }
  }
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Modificar Matriculado #{ id }</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/matriculates'>
                <Button color="primary" size="md">Volver</Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col sm={8}>
                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <label className='form-control-label'>N° de Matricula</label>
                        <p>{ data.matricula || '-' }</p>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <label className='form-control-label'>Delegación</label>
                        <p>{ data.delegacion || '-' }</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <FormGroup>
                        <label className='form-control-label'>DNI</label>
                        <p>{ data.DNI || '-' }</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <label className='form-control-label'>Apellido</label>
                        <p>{ data.apellido || '-' }</p>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <label className='form-control-label'>Nombre</label>
                        <p>{ data.nombre || '-' }</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <FormGroup>
                        <label className='form-control-label'>Estado</label>
                        <p>{ data.estado || '-' }</p>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <label className='form-control-label'>Foto</label><br />
                    <input
                      type="file"
                      className="d-none"
                      ref={ fileInput }
                      accept={ validMimeTypes.join(', ') }
                      onChange={ () => uploadPhoto() }
                    />
                    <div className="w-100 text-center">
                      <img
                        src={ avatar ? avatar : require('../../assets/img/photo-placeholder.png') }
                        alt="Matriculado"
                        id="matriculate_photo"
                        onClick={ changePhoto }
                      />
                      <div className="btn-toolbar justify-content-end" role="toolbar">
                        <div className="btn-group" role="group">
                          {
                            avatar
                            ? <Button
                                color="secondary"
                                title="Quitar Foto"
                                onClick={ removePhoto }
                                disabled={ isLoading }
                              >
                                <i className="fas fa-user-slash text-red"></i>
                              </Button>
                            : null
                          }
                          <Button
                            color="secondary"
                            title="Subir Foto"
                            onClick={ changePhoto }
                            disabled={ isLoading }
                          >
                            <i className="fas fa-cloud-upload-alt"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <Card>
        <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Padrón COMEI</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
              <Col sm={6}>
                <Row>
                <FormGroup>
                  <label className='form-control-label'>Prestador</label>
                  <Input
                  onChange={(e) => setComeiCensus({...comeiCensus, prestador: e.target.value})} 
                  defaultValue={data.comeiCensus?.prestador}/>
                </FormGroup>
                </Row> 
              </Col>
              </Row>
              <Col sm={6}>
                <Row>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={() => submitComeiCensus()}
                      disabled={ isLoading }
                    >
                      Guardar
                    </Button>
                  </div>
                </Row>
              </Col>
            </Form>
          </CardBody>
        </Card>
      </Container>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </>
  )
}

export default Edit
