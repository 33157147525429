import TableLoader from 'components/TableLoader'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Card, CardHeader, CardBody, Row, Col, Button, Input } from 'reactstrap'
import { ApiService } from 'services'
import './Show.scss'

function Show () {
  const { id } = useParams()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ formData, setFormData ] = useState({})

  useEffect(() => {
    setIsLoading(true)

    ApiService.getOne('remitos', id)
    .then(res => {
      if (res.status !== 'ok') {
        console.log(res.payload)
        return
      }

      setFormData(res.payload)
    })
    .catch(console.log)
    .finally(() => {
      setIsLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Remito #{ id }</h3>
              </Col>
              <Col md="4" className="text-right">
              <Link to='/admin/remitos'>
                <Button color="primary" size="md">
                  Volver
                </Button>
              </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          {
            isLoading
            ? <Row>
                <Col className="text-center">
                  <TableLoader />
                </Col>
              </Row>
            : formData.remito
              ? <>
                  <Row className="form-group mb-4">
                    <Col md="6">
                      <label className="form-control-label">Obra Social</label>
                      <Input
                        type="text"
                        defaultValue={ formData.remito.obraSocial }
                        name="obraSocial"
                        disabled={ true }
                      />
                    </Col>
                    <Col md="6">
                      <label className="form-control-label">Cantidad de Prestaciones</label>
                      <Input
                        type="number"
                        defaultValue={ formData.remito.cantidadPrestaciones }
                        name="cantidadPrestaciones"
                        disabled={ true }
                      />
                    </Col>
                  </Row>
                  <Row className="form-group mb-4">
                    <Col>
                      <label className="form-control-label">Prestaciones</label>
                      <table className="align-items-center table-flush table">
                        <thead>
                          <tr>
                            <th>Matricula</th>
                            <th>Nombre y Apellido</th>
                            <th>Beneficiario</th>
                            <th>Práctica</th>
                            <th>N° Autorización</th>
                            <th>Sesiones</th>
                            <th>ACCIONES</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            formData.benefits && Array.isArray(formData.benefits)
                            ? formData.benefits.map(benefit => (
                                <tr key={`benefit_${ benefit.id }`}>
                                  <td>{ benefit.matriculate.matricula }</td>
                                  <td>{ benefit.matriculate.nombre } { benefit.matriculate.apellido }</td>
                                  <td>{ benefit.numero_afiliado }</td>
                                  <td>{ benefit.tipo_practica }</td>
                                  <td>{ benefit.numero_aprobacion }</td>
                                  <td>{ benefit.sessionDates.length }</td>
                                  <td>
                                    <Link to={`/admin/solicitudes/${ benefit.id }`} className="btn btn-primary btn-round btn-sm">
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            : <tr>
                                <td colSpan={5} className="text-center">No hay datos para mostrar...</td>
                              </tr>
                          }
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </>
              : null
          }
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Show
