import React from 'react';
import { FormGroup } from 'reactstrap';
import { ParsedRequest } from 'views/Requests/Edit';

interface MiscDataProps {
  request: ParsedRequest;
}

function MiscData(props: MiscDataProps) {
  const { request } = props;

  return (
    <div className="pl-lg-4">
      <FormGroup>
        <label className="form-control-label">Fecha de prescripción médica</label>
        <p>
          {
            request?.fecha_prescripcion_medica
              ? new Date(request?.fecha_prescripcion_medica).toLocaleDateString('es')
              : '-'
          }
        </p>
      </FormGroup>
      <FormGroup>
        <label className='form-control-label'>N° Mat. Profesional solicitante</label>
        <p>{request?.matricula_profesional_solicitante || '-'}</p>
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">Diagnóstico</label>
        <p>{request?.diagnostico || '-'}</p>
      </FormGroup>
    </div>
  );
}

export default MiscData;
