export const delegations = {
  1: 'Delegación 1',
  2: 'Delegación 2',
  3: 'Delegación 3',
  4: 'Delegación 4',
  5: 'Delegación 5',
  6: 'Delegación 6',
  7: 'Delegación 7',
  8: 'Delegación 8',
  9: 'Delegación 9',
  10: 'Delegación 10',
};

export const getDelegationName = (i: number | string) => {
  return delegations[i as keyof typeof delegations];
};

export interface Delegation {
  id: string;
  name: string;
}

export const delegationsArray = Object.keys(delegations).map(key => {
  return {
    id: key,
    name: getDelegationName(key),
  };
}) as Delegation[];

export const getDelegationObject = (id: number | string ) => {
  return {
    id: id,
    name: getDelegationName(id),
  } as Delegation;
};
