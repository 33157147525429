const status = {
  1: 'Borrador',
  2: 'Solicitada',
  3: 'Auditada',
  4: 'Observada',
  5: 'Procesada',
  6: 'PedidoFactura',
  7: 'PendienteRecibo',
  8: 'Presentada',
  9: 'Anulada',
  10: 'Fuera de Termino',
}

const getStatusName = id => (
  status[id] || null
)

const getStatusId = name => (
  Object.keys(status).find(key => status[key] === name)
)

export { status, getStatusName, getStatusId }