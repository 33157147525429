import React from 'react'
import { Table } from "reactstrap"
import { Link } from "react-router-dom"
import TableLoader from 'components/TableLoader'

export default function (props) {
  const { items, isLoading, currentOrder, changeOrder, id } = props

  const getIconClassBySorted = (field) => {
    if (currentOrder.field === field) {
      if (currentOrder.dir === -1) {
        return 'fa fa-caret-down'
      } else {
        return 'fa fa-caret-up'
      }
    }

    return 'fas fa-sort'
  }

  const setNewOrder = (field) => {
    // Si el campo elegido ya está seleccionado, sólo cambiamos el orden
    if (currentOrder && currentOrder.field === field) {
      changeOrder(
        currentOrder.field,
        currentOrder.dir === 1 ? -1 : 1
      )
    // Si es un nuevo campo, lo indicamos como seleccionado y reseteamos el orden
    // a ascendente
    } else {
      changeOrder(field, 1)
    }
  }

  /**
   * Configuración de la cabecera de las columnas
   */
  const columns = [
    {
      name: 'id',
      label: 'N°',
      sort: true,
      value: row => `#${ row.id }`
    },
    {
      name: 'insurer_text',
      label: 'Obra Social',
      sort: false,
      value: row => row.insurer?.codigo
    },
    {
      name: 'submission_id',
      label: 'N° de remito',
      sort: true,
      value: row => row.submission_id ?? '-'
    },
    {
      name: 'created_at',
      label: 'Fecha de Creación',
      sort: true,
      value: row => new Date(row.created_at).toLocaleString('es')
    },
    {
      name: 'actions',
      label: 'Acciones',
      value: row => {
        return (
          <>
            <Link to={`/admin/remitos/${ row.submission_id }`} className="btn btn-round btn-primary btn-sm">
              <i className="fa fa-eye"></i>
            </Link>
            <Link to={`/admin/presentations/${ row.id }/edit`} className="btn btn-round btn-primary btn-sm">
              <i className="fa fa-edit"></i>
            </Link>
          </>
        )
      }
    }
  ]

  return (
    <Table className="align-items-center table-flush" responsive id={ id }>
      <thead className="thead-light">
        <tr>
          {
            // Cabecera
            columns.map((column) => {
              if (!column.sort) {
                return <th key={column.name} scope='col' className={column.classes ?? ''}>{ column.label }</th>
              }

              return (
                <th key={column.name} scope='col' className={ `clickable ${column.classes ?? ''}` } onClick={ () => setNewOrder(column.name) }>
                  { column.label }
                  { <i className={ getIconClassBySorted(column.name) }></i> }
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          // Datos
          !isLoading
          ? Array.isArray(items) && items.length
            ? items.map(row =>
              <tr key={row.id}>
                { columns.map(column => <td key={ column.name } className={ column.classes ?? '' } >{ column.value(row) }</td>) }
              </tr>
            )
            : <tr>
                <td colSpan={columns.length + 1} className='text-center'>
                  <span>No se encontraron presentaciones</span>
                </td>
              </tr>
          : <tr>
              <td colSpan={columns.length + 1} className='text-center'>
                <TableLoader />
              </td>
            </tr>
        }
      </tbody>
    </Table>
  )
}