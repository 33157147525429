import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Button, Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap'
import { ApiService } from 'services'
import Header from 'components/Headers/Header'
import Loader from 'components/Loader'
import { titleCase } from 'helpers'
import config from 'config'
import Logs from 'components/Logs'

export default function View() {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ data, setData ] = useState({})
  
  const { id } = useParams()

  useEffect(() => {
    if (!id) return

    setIsLoading(true)

    ApiService.getOne('submission-orders', id)
    .then(res => {
      setData(res)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [id])

  return(
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  <span className='mr-2'>Ver Pedidos de Presentación</span>
                </h3>
              </Col>
              <Col className="text-right" xs="4">
                <Link to='/admin/pedidos-presentacion'>
                  <Button color="primary" size="md">
                    Volver
                  </Button>
                </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {
              isLoading
              ? <Loader />
              : <>
                  <Row className='mb-4'>
                    <Col lg='3'>Matriculado</Col>
                    <Col lg='9'>{ data.matriculate ? titleCase(`${data.matriculate.apellido} ${data.matriculate.nombre} (Mat. ${ data.matriculate.matricula })`) : '-' }</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg='3'>Delegación</Col>
                    <Col lg='9'>{ data.matriculate ? data.matriculate.delegacion : '-' }</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg='3'>Obra Social</Col>
                    <Col lg='9'>{ data.ooss ? data.ooss.nombre : '-' }</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg='3'>Periodo</Col>
                    <Col lg='9'>{ data.monthPeriod }/{ data.yearPeriod }</Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col lg='3'>Fecha de solicitud</Col>
                    <Col lg='9'>{ new Date(data.createdAt).toLocaleDateString('es-AR', { dateStyle: 'short' }) }</Col>
                  </Row>
                  {
                    data.attachs && Array.isArray(data.attachs) && data.attachs.length
                    ? data.attachs.map(({ type, url }, idx) =>
                        <Row className="mb-4" key={`attach_${idx}`}>
                          <Col md='3'>{ type === 'factura' ? 'Factura' : 'Nota de Crédito' }</Col>
                          <Col md='9'>
                            <Button color="primary" onClick={ () => window.open(`${config.baseUrl}/${url}`, "_blank") }>Ver adjunto</Button>
                          </Col>
                        </Row>
                      )
                    : null
                  }
                  {
                    data.observations && Array.isArray(data.observations) && data.observations.length
                    ? <Row>
                        <Col lg="12">
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">Observaciones</h6>
                          <div className="pl-lg-4 mb-4">
                            {
                              data.observations.map(obs =>
                                <p className='mt-2' key={obs.id}>
                                  <span><strong>{new Date(obs.createdAt).toLocaleString('es')}</strong> - </span> { obs.text }
                                </p>
                              )
                            }
                          </div>
                        </Col>
                      </Row>
                    : null
                  } 
                  <Logs resource="bso" id={ id } />
                </>
            }
          </CardBody>
        </Card>
      </Container>
    </>
  )
}
