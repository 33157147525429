import React, { useContext, useState } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import TableLoader from 'components/TableLoader';
import { useUser } from 'context/UserContext';

const MAX_LENGTH_NAME = 15;

export default function (props) {
  const user = useUser();

  const { items, isLoading, currentOrder, changeOrder, id } = props;

  const getIconClassBySorted = (field) => {
    if (currentOrder.field === field) {
      if (currentOrder.dir === -1) {
        return 'fa fa-caret-down';
      } else {
        return 'fa fa-caret-up';
      }
    }

    return 'fas fa-sort';
  };

  const setNewOrder = (field) => {
    // Si el campo elegido ya está seleccionado, sólo cambiamos el orden
    if (currentOrder && currentOrder.field === field) {
      changeOrder(currentOrder.field, currentOrder.dir === 1 ? -1 : 1);
      // Si es un nuevo campo, lo indicamos como seleccionado y reseteamos el orden
      // a ascendente
    } else {
      changeOrder(field, 1);
    }
  };

  /**
   * Configuración de la cabecera de las columnas
   */
  const columns = [
    {
      name: 'id',
      label: 'N°',
      sort: true,
      value: (row) => `#${row.id}`,
    },
    {
      name: 'matricula_profesional_solicitante',
      label: 'N° Mat.',
      sort: true,
      value: (row) => (row.matriculate ? row.matriculate.matricula : '-'),
    },
    {
      name: 'matriculado',
      label: 'Matriculado',
      sort: true,
      value: (row) =>
        row.matriculate ? formatMatriculateName(row.matriculate) : '-',
    },
    {
      name: 'afiliado',
      label: 'Afiliado',
      sort: true,
      value: (row) =>
        row.affiliate ? formatAffiliateName(row.affiliate) : '-',
    },
    {
      name: 'prestacion_discapacidad',
      label: 'Discapacidad',
      sort: true,
      value: (row) => !!row.prestacion_discapacidad ? 'Si': 'No'
    },
    {
      name: 'extended_quota',
      label: 'Cupo',
      sort: true,
      value: (row) => !!row.extended_quota ? 'Si': 'No'
    },
    {
      name: 'ooss',
      label: 'Obra Social',
      sort: true,
      value: (row) => (row.ooss ? row.ooss.codigo : ' - '),
    },
    {
      name: 'updatedAt',
      label: 'Última Actualización',
      sort: true,
      value: (row) => new Date(row.updatedAt).toLocaleString('es'),
    },
    {
      name: 'status',
      label: 'Estado',
      sort: true,
      value: (row) => row.status.nombre,
    },
    {
      name: 'observation',
      label: 'Observaciones',
      sort: false,
      value: (row) => {
        if (!Array.isArray(row.observations) || row.observations.length == 0) {
          return 'No';
        }

        if (
          row.observations[row.observations.length - 1].source !== 'matriculado'
        ) {
          return 'Si';
        }

        return 'Si*';
      },
    },
    {
      name: 'actions',
      label: 'Acciones',
      value: (row) => {
        const actions = [];

        if (!row.ooss) {
          return;
        }

        // Edición estandard según estado
        if ([2, 3, 4].includes(row.estado_id)) {
          actions.push(
            <Link
              key="edit"
              to={`/admin/solicitudes/${row.id}/edit`}
              state={{ diffDays: row.diffDays }}
              className="btn-round btn btn-primary btn-sm"
            >
              <i className="fa fa-edit"></i>
            </Link>,
          );
        }

        // Edición extra para admins
        if (user.is([1, 3]) && [1, 2, 3, 4, 6, 7, 8, 10].includes(row.estado_id)) {
          actions.push(
            <Link
              key="view"
              to={`/admin/solicitudes/${row.id}/editor`}
              state={{ diffDays: row.diffDays }}
              className="btn-round btn btn-danger btn-sm"
            >
              <i className="fa fa-wrench"></i>
            </Link>,
          );
        }

        // Acción por defecto
        if (!actions.length) {
          actions.push(
            <Link
              key="view"
              to={`/admin/solicitudes/${row.id}`}
              state={{ diffDays: row.diffDays }}
              className="btn-round btn btn-primary btn-sm"
            >
              <i className="fa fa-eye"></i>
            </Link>,
          );
        }

        return actions;
      },
    },
  ];

  const formatMatriculateName = (matriculate) => {
    let output = '';

    if (matriculate.apellido) output += matriculate.apellido;
    if (
      matriculate.apellido.length + matriculate.nombre.length + 1 <
      MAX_LENGTH_NAME
    ) {
      output += ' ' + matriculate.nombre;
    } else {
      output += ' ' + matriculate.nombre[0] + '.';
    }

    return titleCase(output);
  };

  const formatAffiliateName = (affiliate) => {
    let output = affiliate.nombre_apellido ?? '';
    if (output.length > MAX_LENGTH_NAME) {
      output = affiliate.nombre_apellido.slice(0, MAX_LENGTH_NAME) + '...';
    }

    return titleCase(output);
  };

  const titleCase = (text) => {
    const splitText = text.split(' ');

    for (let i = 0; i < splitText.length; i++) {
      splitText[i] =
        splitText[i].charAt(0).toUpperCase() +
        splitText[i].substring(1).toLowerCase();
    }

    return splitText.join(' ');
  };

  const [borderHovered, setBorderHovered] = useState({});

  function handleBorderHover(id) {
    setBorderHovered((prevState) => ({ ...prevState, [id]: true }));
  }

  function handleBorderLeave(id) {
    setBorderHovered((prevState) => ({ ...prevState, [id]: false }));
  }

  return (
    <Table className="align-items-center table-flush" responsive id={id}>
      <thead className="thead-light">
        <tr
          style={{
            borderLeftWidth: '6px',
            borderLeftColor: '#f6f9fc',
          }}
        >
          {
            // Cabecera
            columns.map((column) => {
              if (!column.sort) {
                return (
                  <th
                    key={column.name}
                    scope="col"
                    className={column.classes ?? ''}
                    style={{
                      borderLeftWidth: '6px',
                      borderLeftColor: 'lightgrey',
                    }}
                  >
                    {column.label}
                  </th>
                );
              }

              return (
                <th
                  key={column.name}
                  scope="col"
                  className={`clickable ${column.classes ?? ''}`}
                  onClick={() => setNewOrder(column.name)}
                >
                  {column.label}
                  {<i className={getIconClassBySorted(column.name)}></i>}
                </th>
              );
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          // Datos
          !isLoading ? (
            Array.isArray(items) && items.length ? (
              items.map((row) => (
                <tr
                  key={row.id}
                  style={{
                    borderLeftWidth: '6px',
                    borderLeftColor: row.colors ? row.colors : 'lightgrey',
                    position: 'relative',
                  }}
                >
                  {columns.map((column) => (
                    <td key={column.name} className={column.classes ?? ''}>
                      {column.value(row)}
                    </td>
                  ))}

                  <td
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: '-6px', // El ancho del borde
                      height: '100%',
                      border: 0,
                    }}
                    onMouseOver={() => handleBorderHover(row.id)}
                    onMouseLeave={() => handleBorderLeave(row.id)}
                  />

                  {borderHovered[row.id] && row.diffDays && (
                    <div
                      style={{
                        position: 'absolute',
                        left: '0px',
                        top: '0px',
                        border: '1px solid #5e72e4',
                        borderRadius: 10,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      {row.diffDays >= 60 ? (
                        <p>Solicitud vencida</p>
                      ) : (
                        <p>
                          Restan {60 - row.diffDays} días para que se venza la
                          solicitud
                        </p>
                      )}
                    </div>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length + 1} className="text-center">
                  <span>No se encontraron solicitudes</span>
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={columns.length + 1} className="text-center">
                <TableLoader />
              </td>
            </tr>
          )
        }
      </tbody>
    </Table>
  );
}
