import React from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import { ParsedRequest } from 'views/Requests/Edit';

interface AffiliateDataProps {
  request: ParsedRequest;
}

function AffiliateData(props: AffiliateDataProps) {
  const { request } = props;

  return (
    <div className="pl-lg-4">
      <Row>
        <Col sm={6}>
          <FormGroup>
            <label className='form-control-label'>Nombre</label>
            <p>{ request?.affiliate?.nombre_apellido || '-' }</p>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <label className='form-control-label'>Edad</label>
            <p>{ request?.affiliate?.edad || '-' }</p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <FormGroup>
            <label className='form-control-label'>N° de Beneficiario</label>
            <p>{ request?.affiliate?.numero_afiliado || '-' }</p>
            {
              request?.ooss_id === 36 &&
              request?.affiliate?.numero_afiliado &&
              request.affiliate.numero_afiliado.length > 10
                ? <small className="form-text text-red">
                    El número de afiliado excede el máximo permitido por la OOSS
                  </small>
                : null
            }
          </FormGroup>
        </Col>
        {
          request?.sessionDates && request.sessionDates.length > 0 && request.sessionDates[0].plan
            ? <Col sm={6}>
                <label className='form-control-label'>Plan</label>
                <p>{ request.sessionDates[0].plan }</p>
              </Col>
            : null
        }
      </Row>
    </div>
  );
}

export default AffiliateData;
