import moment from 'moment';
import React from 'react';

import { FormGroup, Row, Col } from 'reactstrap';
import { ParsedRequest } from 'views/Requests/Edit';

interface SessionsDataProps {
  request: ParsedRequest;
}

export default function SessionsData(props: SessionsDataProps) {
  const { request } = props;

  return (
    <Row>
      <Col>
        <FormGroup>
          <div className='sessions'>
            <ul>
              {
                request.sessionDates && Array.isArray(request.sessionDates)
                  ? request.sessionDates
                    .sort((a, b) => a.date.valueOf() - b.date.valueOf())
                    .map(({ date, approvalNumber, preAuth }, i) => (
                      <li key={i}>
                        - Sesión { i + 1}: { moment(date).format('DD/MM/YYYY') }&nbsp;
                        { approvalNumber ? ' - Autorización n° ' + approvalNumber : ''}&nbsp;
                        { preAuth || request.numero_aprobacion
                          ? request.ooss_id === 66
                            ? ' - Formulario 4: ' + preAuth ? preAuth : request.numero_aprobacion
                            : ' - Preautorización: ' + preAuth ? preAuth : request.numero_aprobacion
                          : ''
                        }
                      </li>
                    ))
                  : '-'
              }
            </ul>
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
}
