import { useState } from 'react'

export const useAlert = (initialValue, initialMessage) => {
  const [show, setShow] = useState(initialValue)
  const [message, setMessage] = useState(initialMessage)
  
  const set = (value, message) => {
    if (value && message) {
      return (
        setShow(value),
        setMessage(message)
      )
    }
    return setShow(value)
  }

  return [
    show,
    message,
    set
  ]
}