import { useState } from 'react'

export const useConfirm = (initialValue, initialTitle, initialAction) => {
  const [confirm, setConfirm] = useState(initialValue)
  const [title, setTitle] = useState(initialTitle)
  const [action, setAction] = useState(initialAction)
  
  const set = (value, title, action) => {
    if (title && !action) {
      return (
        setConfirm(value),
        setTitle(title)
      )
    }
    if (title && action) {
      return (
        setConfirm(value),
        setTitle(title),
        setAction(action)
      )
    }
    return setConfirm(value)
  }

  return [
    confirm,
    title,
    action,
    set
  ]
}