import React from 'react'
import { Table } from "reactstrap"
import { Link } from "react-router-dom"
import TableLoader from 'components/TableLoader'
import moment from 'moment'

export default function (props) {
  const { items, isLoading, currentOrder, changeOrder, id } = props

  const getIconClassBySorted = (field) => {
    if (currentOrder.field === field) {
      if (currentOrder.dir === -1) {
        return 'fa fa-caret-down'
      } else {
        return 'fa fa-caret-up'
      }
    }

    return 'fas fa-sort'
  }

  const getStateName = (id) => {
    switch(id) {
      case 1: return 'Solicitada'
      case 2: return 'Aceptada'
      case 3: return 'Rechazada'
      default: return '-'
    }
  }

  const setNewOrder = (field) => {
    // Si el campo elegido ya está seleccionado, sólo cambiamos el orden
    if (currentOrder && currentOrder.field === field) {
      changeOrder(
        currentOrder.field,
        currentOrder.dir === 1 ? -1 : 1
      )
    // Si es un nuevo campo, lo indicamos como seleccionado y reseteamos el orden
    // a ascendente
    } else {
      changeOrder(field, 1)
    }
  }

  /**
   * Configuración de la cabecera de las columnas
   */
  const columns = [
    {
      name: 'id',
      label: 'Solicitud',
      sort: true,
      value: row => `${ row.type === 'photo' ? 'Foto' : 'Datos' } #${ row.id }`
    },
    {
      name: 'delegation',
      label: 'Delegación',
      sort: true,
      value: row => row.delegacion
    },
    {
      name: 'matriculate_id',
      label: 'Matrícula',
      sort: true,
      value: row => row.matriculate_id
    },
    {
      name: 'name',
      label: 'Nombre',
      sort: true,
      value: row => `${ row.apellido }, ${ row.nombre }`
    },
    {
      name: 'created_at',
      label: 'Fecha de Solicitud',
      sort: true,
      value: row => row.created_at ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : '-'
    },
    {
      name: 'state_id',
      label: 'Estado',
      sort: false,
      value: row => row.state_id ? getStateName(row.state_id) : ' - '
    },
    {
      name: 'user_id',
      label: 'Usuario',
      sort: false,
      value: row => row.user ? `${ row.user.firstName } ${ row.user.lastName }` : ' - '
    },
    {
      name: 'actions',
      label: 'Acciones',
      value: row => {
        return (
          row.state_id === 1
          ? <Link to={`/admin/matriculates/${ row.type === 'photo' ? 'photos' : 'profile' }/${ row.id }/edit`} className="btn btn-round btn-primary btn-sm">
              <i className="fa fa-edit"></i>
            </Link>
          : <Link to={`/admin/matriculates/${ row.type === 'photo' ? 'photos' : 'profile' }/${ row.id }`} className="btn btn-round btn-primary btn-sm">
              <i className="fa fa-eye"></i>
            </Link>
        )
      }
    }
  ]

  return (
    <Table className="align-items-center table-flush" responsive id={ id }>
      <thead className="thead-light">
        <tr>
          {
            // Cabecera
            columns.map((column) => {
              if (!column.sort) {
                return <th key={column.name} scope='col' className={column.classes ?? ''}>{ column.label }</th>
              }

              return (
                <th key={column.name} scope='col' className={ `clickable ${column.classes ?? ''}` } onClick={ () => setNewOrder(column.name) }>
                  { column.label }
                  { <i className={ getIconClassBySorted(column.name) }></i> }
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          // Datos
          !isLoading
          ? Array.isArray(items) && items.length
            ? items.map(row =>
              <tr key={ `request_${ row.type }_${ row.id }` }>
                { columns.map(column => <td key={ column.name } className={ column.classes ?? '' } >{ column.value(row) }</td>) }
              </tr>
            )
            : <tr>
                <td colSpan={columns.length + 1} className='text-center'>
                  <span>No se encontraron solicitudes</span>
                </td>
              </tr>
          : <tr>
              <td colSpan={columns.length + 1} className='text-center'>
                <TableLoader />
              </td>
            </tr>
        }
      </tbody>
    </Table>
  )
}