import React from 'react'
import CollapsibleCard from '../../components/CollapsibleCard'
import { Col, Row, FormGroup, Input } from 'reactstrap'

function Filters(props) {
  const { changeFilter, currentOptions } = props

  return (
    <CollapsibleCard title="Filtros" open={ currentOptions?.state_id && currentOptions?.state_id !== 'any' }>
      <Row>
        <Col sm={4}>
          <FormGroup>
            <label>Estado</label>
            <Input
              type="select"
              onChange={ (event) => changeFilter('state_id', event.target.value) }
              value={ currentOptions?.state_id ? currentOptions.state_id : '1' }
            >
              <option value="any">Todos</option>
              <option value="1">Solicitadas</option>
              <option value="2">Aprobadas</option>
              <option value="3">Rechazadas</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </CollapsibleCard>
  )
}

export default Filters
