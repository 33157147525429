import { RequestsStats as IRequestsStats, getRequestsStats } from 'features/reports/reportsAPI';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, ResponsiveContainer, Tooltip } from 'recharts';

export default function RequestsStats() {
  const [data, setData] = useState<IRequestsStats>([]);

  useEffect(()=>{
    getRequestsStats()
      .then(stats => {
        if (!stats) {
          return null;
        }

        setData(stats);
      })
      .catch((err: any) => {
        alert('Se produjo un error al intentar recuperar la información de la gráfica. ' +
            'Por favor, intente nuevamente más tarde',
        );
        console.error('Reports', 'Error inesperador al intentar recuperar la gráfica');
        console.info(err);
      });
  }, []);

  return (
    <div className='content' style={{ marginBottom: '1rem' }}>
      <Row>
        <Col>
          <Card className='card-user'>
            <CardBody>
              <h3>Estadísticas solicitudes</h3>
              <strong>Cantidad de solicitudes creadas por día</strong>
              <Row className="pt-4 pb-4">
                <Col>
                  <ResponsiveContainer height={450} width="100%">
                    <LineChart data={ data }>
                      <XAxis dataKey="date"/>
                      <YAxis/>
                      <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                      <Line type="monotone" dataKey="Total" stroke="#8884d8" label="Total" />
                      <Tooltip />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
