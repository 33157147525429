import { useState } from 'react'

export const useForm = initialValues => {
  const [values, setValues] = useState(initialValues)

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
    })
  }

  const externalSetValues = (obj) => {
    setValues({ ...values, ...obj })
  }

  // use the prop 'name' from input to make the changes
  return [
    values,
    handleChange,
    externalSetValues
  ]
}