// responses from API calls (check ApiService.js for implementation)
export const responses = {
  withError () {
    return { error: true }
  },
  notFound () {
    return { notFound: true }
  },
  unauthorized () {
    return { unauthorized: true }
  }
}