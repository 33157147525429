import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';

import { useUser } from 'context';

function AdminNavbar() {
  const navigate = useNavigate();

  const user = useUser();

  const [fullName, setFullName] = useState('Invitado');

  useEffect(() => {
    const userData = user.getUser();
    if (!userData) {
      return;
    }

    setFullName(`${ userData.firstName } ${ userData.lastName }`);
  }, [user]);

  const logout = () => {
    setFullName('Invitado');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userData');
    navigate('/auth/login');
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid style={{ display: 'flex', alignItems: 'center' }}>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            style={{ flexGrow: 1 }}
            to="/"
          >

          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      { fullName } <i className="fas fa-angle-down align-middle" />
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow">
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bienvenido!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Editar perfil</span>
                </DropdownItem>
                <DropdownItem to="/auth/change-password" tag={Link}>
                  <i className="ni ni-key-25" />
                  <span>Cambiar contraseña</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={ logout }>
                  <i className="ni ni-user-run" />
                  <span>Cerrar sesión</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
