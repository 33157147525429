import config from 'config';
import { APIResponse } from '@cokiba/types';

interface ManagerDateStat {
  date: string;
  Total: number;
  Fallidas: number;
}

interface RequestDateStat {
  date: string;
  Total: number;
}

interface RemitosStat {
  name: string;
  [key: string]: unknown;
}

export type ManagerStats = ManagerDateStat[];

export type ManagersStats = Record<string, ManagerStats>;

export type RequestsStats = RequestDateStat[];

export type RemitosStats = RemitosStat[];

export async function getManagersStats(): Promise<ManagersStats | undefined> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/reports/stats/managers`);

    type Response = APIResponse<ManagersStats>

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as Response;
    const { payload } = content;
    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las estadísticas. ');
  }
}

export async function getRequestsStats(): Promise<RequestsStats | undefined> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/reports/stats/requests`);

    type Response = APIResponse<RequestsStats>

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as Response;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las estadísticas.');
  }
}

export async function getRemitosStats(): Promise<RemitosStats> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('Token no seteado');
    }

    const url = new URL(`${ config.baseUrl }/reports/stats/notes`);

    type Response = APIResponse<RemitosStats>

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    const content = await response.json() as Response;
    const { payload } = content;

    if (!payload) {
      return [] as RemitosStats;
    }

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las estadísticas');
  }
}
