import React from 'react'
import { Button, Row, Col, Form, Input, FormGroup } from 'reactstrap'
import config from 'config'

import './Form.scss'

function PhotosForm (props) {
  const { data, isLoading, onAccept, onReject } = props

  const accept = () => {
    if (!onAccept) {
      return
    }

    onAccept()
  }

  const reject = () => {
    if (!onReject) {
      return
    }

    onReject()
  }

  return (
    <Form>
      <Row className="form-group mb-4">
        <Col>
          <FormGroup>
            <label className="form-control-label">Matriculado</label>
            <Input
              plaintext
              readOnly
              value={ `${ data.matriculate?.apellido } ${ data.matriculate?.nombre }` }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <FormGroup className="text-center">
            <label className="form-control-label">Foto Actual</label><br />
            {
              data.matriculate?.avatar
              ? <img className="matriculate-avatar" src={ data.matriculate?.avatar } alt="Foto Actual" />
              : <img className="matriculate-avatar" src={ require('assets/img/photo-placeholder.png') } alt="Sin Foto Actual" />
            }
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup className="text-center">
            <label className="form-control-label">Documento</label><br />
            {
              data.doc_url
              ? <img className="matriculate-avatar" src={ config.baseUrl + data.doc_url } alt="Documento" />
              : 'Sin Adjunto'
            }
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup className="text-center">
            <label className="form-control-label">Nueva Foto</label><br />
            <img className="matriculate-avatar" src={ data.url } alt="Nueva Foto" />
          </FormGroup>
        </Col>
      </Row>
      {
        onAccept || onReject
          ? <Row>
              <Col className="text-center">
                <Button color="danger" onClick={ reject } disabled={ isLoading || !onReject }>Rechazar</Button>
                <Button color="success" onClick={ accept } disabled={ isLoading || !onAccept }>Aceptar</Button>
              </Col>
            </Row>
          : null
      }
    </Form>
  )
}

export default PhotosForm