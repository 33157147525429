import React, { useState, useEffect, useRef } from 'react';
import Viewer from 'react-viewer';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ApiService, attachs as attachNames } from 'services';
import MatriculateData from 'components/Requests/MatriculateData';
import PracticeData from 'components/Requests/PracticeData';
import ConfirmModal from 'components/ConfirmModal';
import { useConfirm, useAlertPlus } from 'hooks';
import { getStatusId } from 'services';
import config from 'config.js';
import Comments from './Comments';
import Logs from 'components/Logs';
import AlertModal from 'components/AlertModal';
import './Edit.scss';
import { RawRequest, RawSession, Observation } from '@cokiba/types';
import AffiliateData from 'components/Requests/AffiliateData';
import MiscData from 'components/Requests/MiscData';
import SessionsData from 'components/Requests/SessionsData';
import moment from 'moment';
import { getVencimiento } from 'helpers/vencimientos';

type RequestReplacements =
  | 'sessionDates'
  | 'authorization_date'
  | 'fecha_prescripcion_medica';

export interface ParsedSession extends Omit<RawSession, 'date'> {
  date: Date;
}

export interface ParsedRequest extends Omit<RawRequest, RequestReplacements> {
  sessionDates: ParsedSession[];
  authorization_date: Date | null;
  fecha_prescripcion_medica: Date | null;
  extended_quota: boolean | null;
}

export interface GalleryItem {
  src: string;
  downloadUrl: string;
  alt: string;
}

function Edit() {
  const { id: rawId } = useParams();

  if (!rawId) {
    window.location.href = '/solicitudes';
    return <></>;
  }

  const id = parseInt(rawId);

  const navigate = useNavigate();
  const [observation, setObservation] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [isExpiredForObserbation, setIsExpiredForObserbation] = useState(false);
  const [request, setRequest] = useState<ParsedRequest>();
  const [observations, setObservations] = useState<Observation[]>([]);

  const [confirm, confirmTitle, confirmAction, setConfirm] = useConfirm(false);
  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');

  const [gallery, setGallery] = useState<GalleryItem[]>([]);
  const galleryContainer = useRef(null);

  const [isLoading, setLoading] = useState(false);

  const [showObservationForm, setShowObservationForm] = useState(false);

  const [infoVencimiento, setInfoVencimiento] = useState('');
  const [vencida, setVencida] = useState(false);

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoading(true);
    ApiService.getOne('requests', id)
      .then((res: RawRequest) => {
        const newRequest: ParsedRequest = Object.assign(
          {},
          {
            sessionDates: [],
            id: 0,
            createdAt: '',
            updatedAt: '',
            estado_id: 0,
            ooss_id: null,
            matriculado_id: null,
            numero_afiliado: null,
            recipient_validated: null,
            bsoId: null,
            remito_id: null,
            diagnostico: null,
            prestacion_discapacidad: false,
            es_refacturacion: false,
            idTipoPractica: null,
            tipo_practica: null,
            practice_code: null,
            idPracticaAdicional: null,
            practica_adicional: null,
            additional_practice_code: null,
            quirurgical: null,
            numero_aprobacion: '',
            authorization_transaction_id: null,
            fecha_prescripcion_medica: null,
            cantidad_sesiones: 0,
            recipient_token: null,
            exceptional: null,
            exceptional_text: null,
            fecha_aprobacion: null,
            fecha_ultima_observacion: null,
            fecha_procesamiento: null,
            aprobacion_previa: null,
            aprobacion_validada: null,
            matricula_profesional_solicitante: null,
            createdRegister: '',
            updatedRegister: '',
            authorization_date: null,
            deletedAt: null,
            observations: [],
            attachs: [],
            observation: null,
            extended_quota: null,
          },
          res,
        );

        newRequest.sessionDates = res.sessionDates.map(session => ({
          ...session,
          date: moment(session.date, 'YYYY-MM-DD').toDate(),
        }));

        if (res.authorization_date) {
          newRequest.authorization_date = moment(res.authorization_date, 'YYYY-MM-DD').toDate();
        }

        if (res.fecha_prescripcion_medica) {
          newRequest.fecha_prescripcion_medica = moment(res.fecha_prescripcion_medica, 'YYYY-MM-DD').toDate();
        }

        setRequest(newRequest);
        const { attachs, observations } = res;

        if (attachs) {
          setGallery(
            attachs.map(attach => {
              const parts = attach.url.split('/');
              parts[parts.length - 1] = encodeURIComponent(
                parts[parts.length - 1],
              );
              attach.url = parts.join('/');

              return {
                src:
                  attach.tipo_archivo !== 'pdf'
                    ? `${config.baseUrl}/${attach.url}`
                    : require('../../assets/img/pdficon.png'),
                downloadUrl: `${config.baseUrl}/${attach.url}`,
                // @ts-ignore
                alt: attachNames[attach.tipo],
              };
            }),
          );
        }

        if (observations) {
          setObservations(observations);
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (observation) {
      setIsEmpty(false);
    }
  }, [observation]);

  // Cálculo de días para el vencimiento
  useEffect(() => {
    if (request?.estado_id !== 1 && request?.estado_id !== 4) {
      setVencida(false);
      setInfoVencimiento('');
      return;
    }

    if (!request?.sessionDates) {
      setVencida(false);
      setInfoVencimiento('');
      return;
    }

    const diffDays = getVencimiento(request.sessionDates);
    if (!diffDays) {
      setVencida(false);
      setInfoVencimiento('');
      return;
    }

    if (diffDays > 60 ) {
      setVencida(true);
      setInfoVencimiento('Solicitud vencida');
    } else {
      setVencida(false);
      setInfoVencimiento(`Restan ${ 60 - diffDays } días para que se venza la solicitud`);
    }
  }, [request?.sessionDates.length]);

  const onConfirm = async (action: 'accept' | 'observe') => {
    setConfirm(false);
    if (action === 'observe') {
      return submitObservation();
    }
    if (action === 'accept') {
      return submitApproval(true);
    }
  };

  // ACEPTAR
  const submitApproval = async (confirmed = false) => {
    try {
      if (request?.ooss_id === 36 && request?.numero_aprobacion.length > 10) {
        alert('El número de aprobación excede el máximo permitido por Avalian (10 dígitos)');
        return;
      }

      if (request?.ooss_id === 36 &&
        request?.affiliate?.numero_afiliado &&
        request?.affiliate?.numero_afiliado.length > 10
      ) {
        alert('El número de afiliado excede el máximo permitido por Avalian (10 dígitos)');
        return;
      }

      if (!confirmed) {
        return setConfirm(
          true,
          '¿Estás seguro que deseas aceptar esta solicitud?',
          'accept',
        );
      }

      setLoading(true);

      const data = {
        estado_id: getStatusId('Auditada'),
      };

      await ApiService.update('requests', id, data);

      navigate('/admin/solicitudes', {
        state: { success: true },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // OBSERVAR

  const showObservation = () => {
    setShowObservationForm(true);
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  };

  const submitObservation = async () => {
    try {
      setLoading(true);

      // No debe dejar Observar una solicitud si la misma va a incurrir en un Fuera de Término
      if (!!request?.sessionDates.length) {
        const fechaLimite = new Date();
        fechaLimite.setDate(fechaLimite.getDate() - 60);

        const firstSessionDate = request?.sessionDates.reduce(function(prev, curr) {
          return (prev.date < curr.date) ? prev : curr;
        });

        if (firstSessionDate.date < fechaLimite) {
          setIsExpiredForObserbation(true);
          return;
        }
      }

      if (!observation || observation === '') {
        setIsEmpty(true);
        return;
      }
      setIsEmpty(false);

      const data = {
        estado_id: getStatusId('Observada'),
        observation: observation,
      };

      await ApiService.update('requests', id, data);

      navigate('/admin/solicitudes', {
        state: { success: true },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const cancelObservation = () => {
    setShowObservationForm(false);
    setObservation('');
    setIsEmpty(false);
  };

  const submitExpiration = async () => {
    try {
      setLoading(true);

      const data = {
        estado_id: getStatusId('Fuera de Termino'),
      };

      await ApiService.update('requests', id, data);

      navigate('/admin/solicitudes', {
        state: { success: true },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Solicitud #{id}</h3>
                    <span style={{ fontSize: '.85rem' }}>{ infoVencimiento }</span>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to="/admin/solicitudes">
                      <Button color="primary" size="md">
                        Volver
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col sm={6}>
                      {request?.matriculate ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Datos del matriculado
                          </h6>
                          <MatriculateData matriculate={request.matriculate} />
                          <hr className="my-4" />
                        </>
                      ) : null}

                      {request ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Datos del beneficiario
                          </h6>
                          <AffiliateData request={request} />
                          <hr className="my-4" />
                        </>
                      ) : null}

                      {request ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Información de la prestación
                          </h6>
                          <PracticeData request={request} />
                          <hr className="my-4" />
                        </>
                      ) : null}

                      {request ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Otros datos
                          </h6>
                          <MiscData request={request} />
                          <hr className="my-4" />
                        </>
                      ) : null}

                      {request ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Fechas de sesiones
                          </h6>
                          <SessionsData request={request} />
                          <hr className="my-4" />
                        </>
                      ) : null}
                    </Col>
                    <Col sm={6}>
                      <div className="sticky-top">
                        <label className="form-control-label">Adjuntos</label>
                        <div
                          id="gallery-container"
                          style={{ height: '80vh' }}
                          ref={galleryContainer}
                        >
                          {Array.isArray(gallery) &&
                          gallery.length &&
                          galleryContainer.current ? (
                                <Viewer
                                  container={galleryContainer.current}
                                  noClose={true}
                                  visible={true}
                                  disableMouseZoom={true}
                                  downloadable={true}
                                  disableKeyboardSupport={true}
                                  zoomSpeed={0.5}
                                  downloadInNewWindow={true}
                                  noImgDetails={true}
                                  scalable={false}
                                  showTotal={false}
                                  defaultScale={1.25}
                                  defaultImg={{
                                    src: require('../../assets/img/404.png'),
                                  }}
                                  images={gallery}
                                />
                              ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className={!observations.length ? 'hidden' : ''}>
                    <Col>
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Observaciones
                      </h6>
                      <div className="pl-lg-4 mb-4">
                        {observations.map(obs => (
                          <p className="mt-2" key={obs.id}>
                            {obs.source === 'auditor' ? (
                              <i className="fas fa-angle-double-right"></i>
                            ) : (
                              <i className="fas fa-angle-double-left"></i>
                            )}
                            &nbsp;
                            {obs.text.split(/\r?\n/).map(line => (
                              <>
                                {line}
                                <br />
                              </>
                            ))}
                            &nbsp;
                            <small>
                              {new Date(obs.createdAt).toLocaleString('es')}
                            </small>
                          </p>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <Row className={!showObservationForm ? 'hidden' : ''}>
                    <Col>
                      <hr className="my-4" />
                      <FormGroup>
                        <Input
                          autoComplete="off"
                          className={
                            isEmpty ? 'required' : 'form-control-alternative'
                          }
                          type="textarea"
                          rows="4"
                          name="observation"
                          placeholder="Agregar observación"
                          onChange={e => setObservation(e.target.value)}
                          disabled={isLoading}
                        />
                        {isExpiredForObserbation && (
                          <label className="required-label">
                            No es posible observar la Solicitud ya que incurrirá en un Fuera de Término.
                          </label>
                        )}
                        {isEmpty && (
                          <label className="required-label">
                            Este campo no puede estar vacío.
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className={!showObservationForm ? 'hidden' : ''}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="success"
                        size="md"
                        onClick={() => submitObservation()}
                        disabled={isLoading}
                      >
                        Aceptar
                      </Button>
                      <Button
                        className="btn-round"
                        color="error"
                        size="md"
                        onClick={() => cancelObservation()}
                        disabled={isLoading}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </Row>
                  <Comments requestId={id} />
                  <Logs resource="request" id={id} />
                  <Row className={showObservationForm ? 'hidden' : ''}>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        size="md"
                        onClick={() => showObservation()}
                        disabled={ isLoading }
                      >
                        Observar Solicitud
                      </Button>
                      <Button className={`btn-round${ request?.estado_id === 3 ? ' d-none' : '' }`}
                        color="success"
                        size="md"
                        onClick={() => submitApproval()}
                        disabled={isLoading}
                      >
                        Aprobar Solicitud
                      </Button>
                      {
                        vencida
                          ? <Button
                              className="btn-round"
                              color="warning"
                              size="md"
                              onClick={() => submitExpiration()}
                              disabled={ isLoading }
                            >
                              Marcar como Fuera de Término
                            </Button>
                          : null
                      }
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConfirmModal
        isOpen={confirm}
        title={confirmTitle}
        action={confirmAction}
        onClose={() => setConfirm(false)}
        onConfirm={(action: 'accept' | 'observe') => onConfirm(action)}
      />

      <AlertModal
        isOpen={alertShow}
        message={alertMessage}
        onClose={alertCallback}
      />
    </>
  );
}

export default Edit;
