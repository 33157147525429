import React, { useState, useEffect } from "react"

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap"

import AlertModal from "components/AlertModal"
import { useAlert } from "hooks"
import { Link } from "react-router-dom"
import Header from 'components/Headers/Header'
import Loader from "components/Loader"
import BSOForm from "./BSOForm"
import { ApiService } from "services"

const initialValues = {
  mesPresentacion: '',
  nroOS: ''
}

export default function Create () {

  const [isLoading, setIsLoading] = useState(false)
  const [ooss, setOoss] = useState([])
  const [data, setData] = useState(initialValues)
  const [showAlert, message, alert] = useAlert(false, '')

  useEffect(() => {
    getOOSS()
  }, [])

  const getOOSS = async () => {
    setIsLoading(true)
    try {
      const ooss = await ApiService.getAll('ooss', { presentsBill: true, limit: -1 })
      setOoss(ooss.rows)
    } catch {
      setIsLoading(false)
      return // error
    }
    setIsLoading(false)
  }

  const submit = async () => {
    console.log('submitted', data)
    // TODO we need to validate the data first!
    try {
      setIsLoading(true)
      const res = await ApiService.generateBso(data)
      if (res.error) {
        setIsLoading(false)
        return alert(true, 'Ha ocurrido un error, por favor intente nuevamente.')
      }
      if (res.length) {
        alert(true, `Se ha generado ${res.length} registro(s) con éxito.`)
      } else {
        alert(true, `No había solicitudes para procesar, no se generó ningún registro.`)
      }
    } catch (err) {
      alert(true, 'Ha ocurrido un error, por favor intente nuevamente.')
      setIsLoading(false)
      return //
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <AlertModal
        isOpen={showAlert}
        message={message}
        onClose={() => alert(false)}
      />
      <Header />
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  <span className='mr-2'>Nuevo pedido de presentación</span>
                  <Loader className={isLoading ? '': 'hidden'} />
                </h3>
              </Col>
              <Col className="text-right" xs="4">
                <Link to='/admin/pedidos-presentacion'>
                  <Button
                    color="primary"
                    size="md"
                  >
                    Volver
                  </Button>
                </Link>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <BSOForm
              data={data}
              setData={setData}
              isLoading={isLoading}
              ooss={ooss}
              onSubmit={ submit }
            />
          </CardBody>
        </Card>
      </Container>
    </>
  )
}