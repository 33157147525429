import DatePicker, { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es'
import React, { useState , useEffect} from 'react'
import { Button, Row, Col, Table, Input, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap'

import './Form.scss'
import moment from 'moment'

function Form (props) {
  const { data, isLoading, onAccept, onReject, onEdit } = props

  const defaultDate = (new Date()).toISOString().split('T')[0]

  const [ openDialog, setOpenDialog ] = useState(null)

  const [ validity, setValidity ] = useState(30)
  const [ signature, setSignature ] = useState(true)
  const [ target, setTarget ] = useState('')
  const [ rejectionText, setRejectionText ] = useState(null)
  const [ date, setDate ] = useState(defaultDate)

  useEffect(() => {
    registerLocale('es', es)
  })

  useEffect(() => {
    setTarget(data.target)
  }, [data.target])

  useEffect(() => {
    setDate(data.date)
  }, [data.date])
  

  const accept = () => {
    if (!onAccept) {
      return
    }

    onAccept(validity, signature)
  }

  const reject = () => {
    if (!onReject) {
      return
    }

    setOpenDialog(false)

    onReject(rejectionText)
  }

  const rejectDialog = () => {
    setRejectionText(null)
    setOpenDialog(true)
  }

  const edit = (name) => {
    if (!onEdit) {
      return
    }

    if (name === 'target') {
      onEdit(name, target)
    } else if (name === 'date') {
      onEdit(name, date)
    }
  }

  return (
    <>
      <Table className="mb-4">
        <tbody>
          <tr>
            <th>Solicitante</th>
            <td>{ data?.matriculate?.apellido ? `${ data.matriculate.apellido }, ${ data.matriculate.nombre }` : ' - ' }</td>
          </tr>
          <tr>
            <th>Matrícula</th>
            <td>{ data?.matriculate_id ?? ' - ' }</td>
          </tr>
          <tr>
            <th>Destinatario</th>
            <td>
              <InputGroup>
                <Input
                  id="target"
                  name="target"
                  type="text"
                  maxLength={ 500 }
                  disabled={ isLoading }
                  defaultValue={ target }
                  onChange={ event => setTarget(event.target.value) }
                />
                <Button
                  color="warning"
                  onClick={ () => edit('target') }
                  disabled={ isLoading || !onEdit }
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  Modificar
                </Button>
              </InputGroup>
            </td>
          </tr>
          <tr>
            <th>Razón</th>
            <td>{ data?.reason }</td>
          </tr>
          <tr>
            <th>Deuda</th>
            <td>{
            Array.isArray(data?.debt) && data.debt.length
            ? data.debt.map(d => (
              <span key={ d.concepto } style={{ display: 'block' }}>{ d.concepto }: ${ d.monto.toFixed(2) }</span>
            ))
            : 'No registra deuda'
            }</td>
          </tr>
          <tr>
            <th>Fecha</th>
            <td>
              <InputGroup style={{ display:'flex', flexWrap: 'nowrap' }}>
                <DatePicker
                  id="date"
                  name="date"
                  onChange={ (date) => setDate(date.toISOString().split('T')[0]) }
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Fecha"
                  minDate={ new Date() }
                  selected={ date ? moment(date, 'YYYY-MM-DD').toDate() : new Date() }
                  className="form-control matriculate-ethics"
                  autoComplete="off"
                  locale="es"
                  required
                  disabled={ isLoading }
                />
                <Button
                  color="warning"
                  onClick={ () => edit('date') }
                  disabled={ isLoading || !onEdit }
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  Modificar
                </Button>
              </InputGroup>
            </td>
          </tr>
          <tr>
            <th>Validez</th>
            <td>
              <Input
                type="select"
                name="validity"
                onChange={ event => setValidity(event.target.value) }
                style={{ width: 'auto' }}
                defaultValue={ validity }
              >
                <option value="30">30 días</option>
                <option value="60">60 días</option>
              </Input>
            </td>
          </tr>
          <tr>
            <th>Incluir Firma</th>
            <td>
              <label className="custom-toggle">
                <Input
                  type="checkbox"
                  name="signature"
                  onChange={ event => setSignature(event.target.checked) }
                  defaultChecked={ signature }
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </td>
          </tr>
          <tr>
            <th>Solicitudes Anteriores</th>
            <td>
              {
                data.history && Array.isArray(data.history)
                ? data.history.map(h => {
                  return <React.Fragment key={ h.MTR046_ID_CERTIFICADO_ETICA }>
                    <strong>#{ h.MTR046_ID_CERTIFICADO_ETICA }</strong>.
                    Fecha: <strong>{ h.MTR046_FECHA_PEDIDO ? moment(h.MTR046_FECHA_PEDIDO, 'YYYY-MM-DD').format('DD/MM/YYYY') : ' - ' }</strong>.
                    Interesado <strong>{ h.MTR046_INTERESADO }</strong>
                    { h.MTR046_ACEPTADO ? <i>. ACEPTADA</i> : ''}<br />
                  </React.Fragment>
                })
                : null
              }
            </td>
          </tr>
        </tbody>
      </Table>
      <Row>
        <Col className="text-center">
          <Button color="danger" onClick={ rejectDialog } disabled={ isLoading || !onReject }>Rechazar</Button>
          <Button color="success" onClick={ accept } disabled={ isLoading || !onAccept }>Aceptar</Button>
        </Col>
      </Row>
      <Modal isOpen={ openDialog } className='modal-dialog-centered'>
        <ModalHeader>Rechazar</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Ingrese el motivo de rechazo</Label>
            <Input
              type="text"
              name="reason"
              onChange={ event => setRejectionText(event.target.value) }
              value={ rejectionText ?? '' }
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={ () => setOpenDialog(false) }>Cancelar</Button>
          <Button color="danger" onClick={ reject }>Rechazar</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Form
