import config from 'config';
import { APIResponse } from '@cokiba/types';

interface GenericSetting<T> {
  key: string;
  title: string;
  description: string;
  value: T;
}

interface BooleanSetting extends GenericSetting<boolean> { type: 'boolean' }
interface IntegerSetting extends GenericSetting<number> { type: 'integer' }
interface StringSetting extends GenericSetting<string> { type: 'string' }
interface VersionSetting extends GenericSetting<string> { type: 'version' }

export type Setting = BooleanSetting | IntegerSetting | StringSetting | VersionSetting;

export type Config = Setting[];

export interface Insurer {
  codigo: string;
  id: number;
  isEnabled: boolean;
  isEnabledApp: boolean;
}

export interface PracticePayload {
  id: number;
  name: string;
  code: string;
  orientation?: {
    id: number;
    name: string;
  };
  specialty?: {
    id: number;
    name: string;
  };
  common?: boolean;
}

export interface Specialty {
  id: number;
  descripcion: string;
}

interface InsurerPayload {
  id: number;
  name: string;
  practices: PracticePayload[];
}

export type InsurerPractices = InsurerPayload[];

export interface PracticeData {
  insurer_id: number;
  practice_id: number;
  code: string;
  specialty_id?: number;
  orientation_id?: number;
  common?: boolean;
}

export interface RemovePracticeData {
  insurer_id: number;
  practice_id: number;
}

export async function getConfig(): Promise<Config | void> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/system/config`);

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as APIResponse<Config>;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar la configuración. ');
  }
}

export async function setConfig(key: string, value: number | string | boolean): Promise<Config | void> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/system/config`);

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ key, value }),
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as APIResponse<Config>;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las gráficas. ');
  }
}

export async function getInsurers(): Promise<Insurer[] | void> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/system/insurers`);

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as APIResponse<Insurer[]>;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar la configuración. ');
  }
}

export async function setInsurersConfig(
  insurer: number,
  target: 'web' | 'app',
  value: boolean,
): Promise<Insurer | void> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/system/insurers`);

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ insurer, target, value }),
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as APIResponse<Insurer>;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las gráficas. ');
  }
}

export async function getPracticeConfig(): Promise<InsurerPractices | void> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/system/practices`);

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as APIResponse<InsurerPractices>;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar la configuración. ');
  }
}

export async function setPracticeConfig(data: PracticeData): Promise<void> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/system/practices`);

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (response.status !== 200) {
      return;
    }

    await response.json() as APIResponse<undefined>;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar guardar la configuración. ');
  }
}

export async function removePracticeConfig(data: RemovePracticeData): Promise<void> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/system/practices`);

    const response = await fetch(url.toString(), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (response.status !== 200) {
      return;
    }

    await response.json() as APIResponse<undefined>;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar guardar la configuración. ');
  }
}

export async function getSpecialties(): Promise<Specialty[] | void> {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      return;
    }

    const url = new URL(`${ config.baseUrl }/system/specialties`);

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as APIResponse<Specialty[]>;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar la configuración. ');
  }
}
