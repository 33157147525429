import { Location, useLocation } from 'react-router-dom';

interface LocationWithState extends Location {
  state: {
    success?: boolean;
    didRecover?: boolean;
    didChange?: boolean;
  }
}

export const useAppLocation = (): LocationWithState => useLocation() as LocationWithState;
