import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import UserContextProvider from 'context/UserContext';
import Router from 'routes';

export default function App() {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <Router />
      </UserContextProvider>
    </BrowserRouter>
  );
}
