import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, UncontrolledAlert, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useForm } from 'hooks'
import { useUser } from 'context/UserContext'

function OOSSForm (props) {
  const user = useUser()
  const isAdmin = useState(user.is(1))

  const { title, data, setData, onSubmit, fieldClass, isValidated } = props
  const [ values, handleChange ] = useForm(data)

  useEffect(() => {
    setData(values)
  }, [values, setData])


  const submit = () => {
    return onSubmit()
  }

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">{ title }</h3>
          </Col>
          <Col className="text-right" xs="4">
            <Link to='/admin/obras-sociales'>
              <Button color="primary" size="md">Volver</Button>
            </Link>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {
          !isValidated &&
          <UncontrolledAlert color='danger'>
            Por favor, complete los campos requeridos.
          </UncontrolledAlert>
        }
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Opciones de gestión de la OOSS
          </h6>
          <div className="pl-lg-4">
            <Row>
              {
                isAdmin
                ? <Col lg="12">
                    <FormGroup className='form-check'>
                      <Input
                        type='checkbox'
                        className={`form-control-alternative ${fieldClass['isEnabled']}`}
                        id="check-enabled"
                        name='isEnabled'
                        checked={values.isEnabled || false}
                        onChange={ handleChange }
                      />
                      <label className="form-control-label" htmlFor="check-enabled">
                        Habilitar para solicitudes de facturación
                      </label>
                    </FormGroup>
                  </Col>
                : null
              }
              <Col lg="12">
                <FormGroup className="form-check">
                  <Input
                    type="checkbox"
                    className="form-control-alternative"
                    id="check-enabled-app"
                    name="isEnabledApp"
                    checked={ values.isEnabledApp ?? false }
                    onChange={ handleChange }
                  />
                  <label className="form-control-label" htmlFor="check-enabled-app">
                    Habilitar para mostrar en la aplicación móvil
                  </label>
                </FormGroup>
              </Col>
              {
                isAdmin
                ? <Col lg="12">
                    <FormGroup className='form-check'>
                      <Input
                        type='checkbox'
                        className={`form-control-alternative ${fieldClass['presentsBill']}`}
                        id="check-bill"
                        name='presentsBill'
                        checked={values.presentsBill || false}
                        onChange={ handleChange }
                      />
                      <label className="form-control-label" htmlFor="check-bill">
                        Los matriculados deben presentar facturas
                      </label>
                    </FormGroup>
                  </Col>
                  : null
                }
            </Row>
          </div>
          <h6 className="heading-small text-muted mb-4">
            Información de la obra social
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Nombre
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['nombre']}`}
                    type="text"
                    name='nombre'
                    value={values.nombre || ''}
                    placeholder='Nombre'
                    onChange={ handleChange }
                    disabled={ !isAdmin }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-last-name">
                    Codigo
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['codigo']}`}
                    placeholder="Codigo"
                    type="text"
                    name='codigo'
                    value={values.codigo || ''}
                    onChange={ handleChange }
                    disabled={ !isAdmin }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    CUIT
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['cuit']}`}
                    id="input-email"
                    name='cuit'
                    value={values.cuit || ''}
                    placeholder="Ingrese CUIT"
                    onChange={ handleChange }
                    disabled={ !isAdmin }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Domicilio Fiscal
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['domicilioFiscal']}`}
                    id="input-email"
                    name='domicilioFiscal'
                    value={values.domicilioFiscal || ''}
                    placeholder="Domicilio Fiscal"
                    onChange={ handleChange }
                    disabled={ !isAdmin }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label">
                    Situación frente al IVA
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['situacioniva']}`}
                    name='situacioniva'
                    value={values.situacioniva || ''}
                    placeholder="Situación frente a IVA"
                    onChange={ handleChange }
                    disabled={ !isAdmin }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Razón social
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['razonSocial']}`}
                    id="input-email"
                    name='razonSocial'
                    value={values.razonSocial || ''}
                    placeholder="Razon social"
                    onChange={ handleChange }
                    disabled={ !isAdmin }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div className="update ml-auto mr-auto">
                <Button
                  className="btn-round"
                  color="primary"
                  title='Crear usuario'
                  onClick={ () => submit() }
                  disabled={ false }
                >
                  Guardar
                </Button>
              </div>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default OOSSForm
