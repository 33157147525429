import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, UncontrolledAlert, Row, Col } from 'reactstrap'
import { useForm } from '../../hooks'
import { delegations } from 'services'
import { ApiService } from 'services'
import Select from 'react-select'

function UserForm(props) {
  const {
    action,
    title,
    data,
    setData,
    onSubmit,
    fieldClass,
    isValidated,
    setFieldClass
  } = props

  const [ roles, setRoles ] = useState([])
  const [ insurers, setInsurers ] = useState([])
  const [ values, handleChange ] = useForm(data)
  const [ matchPassword, setMatchPassword ] = useState(undefined)

  useEffect(() => {
    setData(values)
  }, [values, setData])

  useEffect(() => {
    validatePassword(data.password, data.rePassword)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.password, data.rePassword])

  useEffect(() => {
    ApiService.getAll('users/roles')
      .then(res => {
        if (res.status !== 'ok') {
          console.log(res.payload)
          return
        }

        setRoles(res.payload)
      })
      .catch(console.log)

    ApiService.getAll('ooss', { q: 'all', limit: 1000 })
      .then(res => {
        if (!res.count) {
          console.log(res)
          return
        }

        setInsurers(res.rows)
      })
      .catch(console.log)
  }, [])

  const validatePassword = (password, rePassword) => {
    if (password && rePassword) {
      if (password !== rePassword) {
        setFieldClass({
          ...fieldClass,
          password: 'required',
          rePassword: 'required'
        })
        setMatchPassword(false)
        return false
      } else {
        setFieldClass({
          ...fieldClass,
          password: 'is-valid',
          rePassword: 'is-valid'
        })
        setMatchPassword(true)
        return true
      }
    } else {
      setMatchPassword(undefined)
      setFieldClass({
        ...fieldClass,
        password: '',
        rePassword: ''
      })
    }
  }

  const submit = () => {
    return onSubmit()
  }

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">{ title }</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        { !isValidated && <UncontrolledAlert color='danger'>Por favor, complete los campos vacíos.</UncontrolledAlert> }
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Información de usuario
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-first-name">Nombre</label>
                  <Input
                    autoComplete="given-name"
                    className={`form-control-alternative ${fieldClass['firstName']}`}
                    type="text"
                    name='firstName'
                    value={values.firstName}
                    placeholder='Nombre'
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-last-name">Apellido</label>
                  <Input
                    autoComplete="family-name"
                    className={`form-control-alternative ${fieldClass['lastName']}`}
                    placeholder="Apellido"
                    type="text"
                    name='lastName'
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">Email</label>
                  <Input
                    autoComplete="email"
                    className={`form-control-alternative ${fieldClass['email']}`}
                    id="input-email"
                    name='email'
                    value={values.email}
                    placeholder="example@example.com"
                    type="email"
                    disabled={action === 'profile'}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              {
                action !== 'profile' &&
                <>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-pass">Contraseña</label>
                      <Input
                        className={`form-control-alternative ${fieldClass['password']}`}
                        name='password'
                        autoComplete="new-password"
                        value={values.password}
                        placeholder="Contraseña"
                        type="password"
                        disabled={action === 'edit'}
                        onChange={handleChange}
                      />
                      {
                        matchPassword !== undefined &&
                        <label className={`input-tip input-tip--${matchPassword ? 'valid' : 'invalid'}`}>
                          {`Las contraseñas ${matchPassword ? '' : 'no'} coinciden`}
                        </label>
                      }
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-repeat-pass">Repetir contraseña</label>
                      <Input
                        className={`form-control-alternative ${fieldClass['rePassword']}`}
                        placeholder="Repetir contraseña"
                        type="password"
                        name='rePassword'
                        autoComplete="new-password"
                        value={values.rePassword}
                        disabled={action === 'edit'}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </>
              }
            </Row>
            {action !== 'profile' && <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-first-name">Roles</label>
                  <Select
                    className={`form-control-alternative ${fieldClass['role_id']}`}
                    name="role_id"
                    options={ roles && Array.isArray(roles) ? roles.map(r => ({ value: r.id, label: r.name })) : [] }
                    isMulti
                    defaultValue={ values.roles.map(r => ({ value: r.id, label: r.name })) }
                    onChange={ (newValues) => {
                      const newIds = newValues.map(r => r.value)

                      handleChange({
                        target: {
                          name: 'roles',
                          type: 'select',
                          value: roles.filter(r => newIds.includes(r.id))
                        }
                      })
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>}
            {
              values.roles.find(r => r.id === 2) && action !== 'profile' &&
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-first-name">Delegación</label>
                    <Input
                      className={`form-control-alternative ${fieldClass['delegation']}`}
                      name='delegation'
                      value={values.delegation || 'DEFAULT'}
                      type="select"
                      onChange={handleChange}
                    >
                      <option disabled value='DEFAULT' hidden>Seleccione una opción...</option>
                      {
                        Object
                          .entries(delegations)
                          .map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>)
                          )
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            }
            {
              values.roles.find(r => r.id === 6) && action !== 'profile' &&
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-first-name">Obra Social</label>
                    <Input
                      className={`form-control-alternative`}
                      name="insurer_id"
                      value={values.insurer_id || 'DEFAULT'}
                      type="select"
                      onChange={handleChange}
                    >
                      <option disabled value="DEFAULT" hidden>Seleccione una opción...</option>
                      {
                        insurers && Array.isArray(insurers)
                          ? insurers.map(insurer => (
                            <option key={`insurer_${insurer.id}`} value={insurer.id}>{insurer.codigo} - { insurer.nombre}</option>)
                          )
                          : null
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            }
            <Row>
              <div className="update ml-auto mr-auto">
                <Button
                  className="btn-round"
                  color="primary"
                  title='Crear usuario'
                  onClick={() => submit()}
                  disabled={false}
                >
                  Guardar
                </Button>
              </div>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default UserForm
