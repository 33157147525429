import React, { useEffect, useMemo, useState } from 'react'

import { Pagination as ReactPagination, PaginationItem, PaginationLink } from "reactstrap"

function Pagination (props) {
  const DEFAULT_DISPLAY_MAX_PAGES = 5

  const { page, count, onChangePage, limit = 50 } = props
  const [ firstPage, setFirstPage ] = useState()
  const [ lastPage, setLastPage ] = useState()
  const [ total, setTotal ] = useState()

  useEffect(() => {
    if (!count || count <= 0) {
      setFirstPage(1)
      setLastPage(1)
      setTotal(1)
      return
    }

    const delta = Math.floor(DEFAULT_DISPLAY_MAX_PAGES / 2)
    const newTotal = Math.ceil(count / limit)

    let newFirstPage = 1
    if (page - delta > 0) {
      newFirstPage = page - delta
    }

    let newLastPage = page + delta
    if (newLastPage < newFirstPage + DEFAULT_DISPLAY_MAX_PAGES - 1) {
      newLastPage = newFirstPage + DEFAULT_DISPLAY_MAX_PAGES - 1
    }
    if (newLastPage > newTotal) {
      newLastPage = newTotal
    }

    setFirstPage(newFirstPage)
    setLastPage(newLastPage)
    setTotal(newTotal)
  }, [count, page, limit])

  const pagesList = useMemo(() => {
    const tempArray = []
    for (let i = firstPage; i <= lastPage; i++) {
      tempArray.push(i)
    }
    return tempArray
  }, [firstPage, lastPage])

  const changePage = (newPage) => {
    if (!onChangePage) {
      return
    }

    if (newPage === page) {
      return
    }

    return (onChangePage(newPage))
  }

  return (
    <>
      <nav>
        <ReactPagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
          {
            firstPage > 1
            ? <PaginationItem>
                <PaginationLink onClick={ () => changePage(page - 1) } tabIndex="-1">
                  <i className="fas fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
            : null
          }
          {
            pagesList.map(p => (
              <PaginationItem key={p} className={page === p ? "active" : null}>
                <PaginationLink
                  onClick={() => changePage(p)}
                >
                  { p }
                </PaginationLink>
              </PaginationItem>
            ))
          }
          {
            lastPage < total
            ? <PaginationItem>
                <PaginationLink onClick={() => changePage(page + 1) }>
                  <i className="fas fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            : null
          }
        </ReactPagination>
      </nav>
    </>
  )
}

export default Pagination