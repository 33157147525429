import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ApiService, attachs as attachNames } from 'services';
import Viewer from 'react-viewer';
import MatriculateData from 'components/Requests/MatriculateData';
import PracticeData from 'components/Requests/PracticeData';
import config from 'config.js';
import Comments from './Comments';
import Logs from 'components/Logs';
import { Observation, RawRequest } from '@cokiba/types';
import { GalleryItem, ParsedRequest } from './Edit';
import AffiliateData from 'components/Requests/AffiliateData';
import MiscData from 'components/Requests/MiscData';
import SessionsData from 'components/Requests/SessionsData';
import moment from 'moment';

function Show() {
  const location = useLocation();

  const diffDays = location.state?.diffDays ?? null;

  const { id: rawId } = useParams();

  if (!rawId) {
    window.location.href = '/solicitudes';
    return <></>;
  }

  const id = parseInt(rawId);

  const navigate = useNavigate();

  const [request, setRequest] = useState<ParsedRequest>();
  const [gallery, setGallery] = useState<GalleryItem[]>([]);
  const galleryContainer = useRef(null);

  const [observations, setObservations] = useState<Observation[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }

    ApiService.getOne('requests', id)
      .then((res: RawRequest) => {
        const newRequest: ParsedRequest = Object.assign(
          {},
          {
            sessionDates: [],
            id: 0,
            createdAt: '',
            updatedAt: '',
            estado_id: 0,
            ooss_id: null,
            matriculado_id: null,
            numero_afiliado: null,
            recipient_validated: null,
            bsoId: null,
            remito_id: null,
            diagnostico: null,
            prestacion_discapacidad: false,
            es_refacturacion: false,
            idTipoPractica: null,
            tipo_practica: null,
            practice_code: null,
            idPracticaAdicional: null,
            practica_adicional: null,
            additional_practice_code: null,
            quirurgical: null,
            numero_aprobacion: '',
            authorization_transaction_id: null,
            fecha_prescripcion_medica: null,
            cantidad_sesiones: 0,
            recipient_token: null,
            exceptional: null,
            exceptional_text: null,
            fecha_aprobacion: null,
            fecha_ultima_observacion: null,
            fecha_procesamiento: null,
            aprobacion_previa: null,
            aprobacion_validada: null,
            matricula_profesional_solicitante: null,
            createdRegister: '',
            updatedRegister: '',
            authorization_date: null,
            deletedAt: null,
            observations: [],
            gallery: [],
            observation: null,
            extended_quota: null,
          },
          res,
        );

        newRequest.sessionDates = res.sessionDates.map(session => ({
          ...session,
          date: moment(session.date, 'YYYY-MM-DD').toDate(),
        }));

        if (res.authorization_date) {
          newRequest.authorization_date = moment(res.authorization_date, 'YYYY-MM-DD').toDate();
        }

        if (res.fecha_prescripcion_medica) {
          newRequest.fecha_prescripcion_medica = moment(res.fecha_prescripcion_medica, 'YYYY-MM-DD').toDate();
        }

        setRequest(newRequest);
        const { attachs, observations } = res;

        if (attachs) {
          setGallery(
            attachs.map(attach => {
              const parts = attach.url.split('/');
              parts[parts.length - 1] = encodeURIComponent(
                parts[parts.length - 1],
              );
              attach.url = parts.join('/');

              return {
                src:
                  attach.tipo_archivo !== 'pdf'
                    ? `${config.baseUrl}/${attach.url}`
                    : require('../../assets/img/pdficon.png'),
                downloadUrl: `${config.baseUrl}/${attach.url}`,
                // @ts-ignore
                alt: attachNames[attach.tipo],
              };
            }),
          );
        }

        if (observations) {
          setObservations(observations);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [id]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      Solicitud #{id}
                      {diffDays != null &&
                        (diffDays >= 60
                          ? ' - [Solicitud vencida]'
                          : ` - [Restan ${
                            60 - diffDays
                          } días para que se venza la solicitud]`)}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      size="md"
                      onClick={() => navigate(-1)}
                    >
                      Volver
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col sm={6}>
                      {request?.matriculate ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Datos del matriculado
                          </h6>
                          <MatriculateData matriculate={request.matriculate} />
                          <hr className="my-4" />
                        </>
                      ) : null}

                      {request ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Datos del beneficiario
                          </h6>
                          <AffiliateData request={request} />
                          <hr className="my-4" />
                        </>
                      ) : null}

                      {request ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Información de la prestación
                          </h6>
                          <PracticeData request={request} />
                          <hr className="my-4" />
                        </>
                      ) : null}

                      {request ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Otros datos
                          </h6>
                          <MiscData request={request} />
                          <hr className="my-4" />
                        </>
                      ) : null}

                      {request ? (
                        <>
                          <h6 className="heading-small text-muted mb-4">
                            Fechas de sesiones
                          </h6>
                          <SessionsData request={request} />
                          <hr className="my-4" />
                        </>
                      ) : null}
                    </Col>
                    <Col sm={6}>
                      <div className="sticky-top">
                        <label className="form-control-label">Adjuntos</label>
                        <div
                          id="gallery-container"
                          style={{ height: '80vh' }}
                          ref={galleryContainer}
                        >
                          {Array.isArray(gallery) &&
                          gallery.length &&
                          galleryContainer.current ? (
                                <Viewer
                                  container={galleryContainer.current}
                                  noClose={true}
                                  visible={true}
                                  disableMouseZoom={true}
                                  downloadable={true}
                                  disableKeyboardSupport={true}
                                  zoomSpeed={0.5}
                                  downloadInNewWindow={true}
                                  noImgDetails={true}
                                  scalable={false}
                                  showTotal={false}
                                  defaultScale={1.25}
                                  defaultImg={{
                                    src: require('../../assets/img/404.png'),
                                  }}
                                  images={gallery}
                                />
                              ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className={!observations.length ? 'hidden' : ''}>
                    <Col lg="12">
                      <hr className="my-4" />
                      <h6 className="heading-small text-muted mb-4">
                        Observaciones
                      </h6>
                      <div className="pl-lg-4 mb-4">
                        {observations.map(obs => (
                          <p className="mt-2" key={obs.id}>
                            {obs.source === 'auditor' ? (
                              <i className="fas fa-angle-double-right"></i>
                            ) : (
                              <i className="fas fa-angle-double-left"></i>
                            )}
                            &nbsp;
                            {obs.text.split(/\r?\n/).map(line => (
                              <>
                                {line}
                                <br />
                              </>
                            ))}
                            &nbsp;
                            <small>
                              {new Date(obs.createdAt).toLocaleString('es')}
                            </small>
                          </p>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <Comments requestId={id} />
                  <Logs resource="request" id={id} />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Show;
