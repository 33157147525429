import React, { useState } from 'react';
import { NavLink as NavLinkRRD, Link, useLocation } from 'react-router-dom';
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useUser } from 'context';
import config from 'config';
import adminRoutes from 'admin_routes';

import { default as Logo } from 'assets/img/brand/logo.png';

function Sidebar() {
  const location = useLocation();
  const user = useUser();

  const [collapseOpen, setCollapseOpen] = useState(false);

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };


  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
      <Container fluid>
        <button className="navbar-toggler" type="button" onClick={ () => toggleCollapse() }>
          <span className="navbar-toggler-icon" />
        </button>

        <NavbarBrand className="pt-0 pb-0">
          <img className="navbar-brand-img" src={ Logo } width='190px' />
        </NavbarBrand>

        <div className="small text-right text-light font-weight-bold">
            v{ config.version }{ config.env !== 'prod' ? ` - ${ config.env }` : '' }
        </div>

        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span>LG</span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow">
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bienvenido!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Cambiar contraseña</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <Collapse navbar isOpen={ collapseOpen }>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/admin/solicitudes">
                  <img className="navbar-brand-img" src={ Logo } />
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={() => toggleCollapse()} >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            {
              adminRoutes.map((item, key) => {
                if (!item.show) {
                  return;
                }

                if ('roles' in item && !user.is(item.roles)) {
                  return;
                }

                return (
                  <NavItem key={ key } active={ location.pathname === item.layout + item.path }>
                    <NavLink
                      end
                      active={ location.pathname === item.layout + item.path }
                      to={ item.layout + item.path }
                      tag={ NavLinkRRD }
                      onClick={ () => closeCollapse() }
                    >
                      <i className={item.icon} /> { item.name }
                    </NavLink>
                  </NavItem>
                );
              })
            }
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Sidebar;
