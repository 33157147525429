import React, { useState, useEffect, ChangeEvent } from 'react';
import CollapsibleCard from '../../components/CollapsibleCard';
import { Col, Row, FormGroup, Input, Label } from 'reactstrap';
import { delegations } from '../../services';
import ApiService from 'services/ApiService';
import { useAlertPlus } from 'hooks';
import AlertModal from 'components/AlertModal';
import { Insurer } from '@cokiba/types';

interface Rol {
    id: string;
    code: string;
    name: string;
    context: 'autogestion' | 'backoffice' | 'extranet'
}
interface UserFilter {
    sort: {
      field: string;
      dir: number;
    }
    page: number;
    search: string;
    delegation: string | number;
    ooss_id: string | number;
    rol: string | number;
  }
interface FiltersProps {
  changeFilter: (key: string, value: unknown) => void;
  delegaciones?: boolean;
  currentOptions: UserFilter;
}

export default function FiltersUsers(props: FiltersProps) {
  const { changeFilter, delegaciones = true, currentOptions } = props;

  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');
  const [insurers, setInsurers] = useState<Insurer[]>([]);
  const [roles, setRoles] = useState<Rol[]>([]);
  useEffect(() => {
    ApiService.getAll('ooss', {
      sort: {
        field: 'codigo',
        dir: 1,
      },
      limit: -1,
    })
      .then(res => {
        setInsurers(res.rows);
      })
      .catch(err => {
        alert('Ha ocurrido un error al obtener el listado de Obras Sociales, ' +
          'temporalmente no se podrá filtrar por obra social.');
        console.error(err);
      });
  }, []);

  useEffect(() => {
    ApiService.getAll('users/roles', {
      sort: {
        field: 'context',
        dir: 1,
      },
    })
      .then(res => {
        setRoles(res.payload);
      })
      .catch(err => {
        alert('Ha ocurrido un error al obtener el listado de Roles, ' +
          'temporalmente no se podrá filtrar por Rol.');
        console.error(err);
      });
  }, []);

  return (
    <CollapsibleCard
      title="Filtros"
      open={
        (currentOptions?.delegation && currentOptions?.delegation !== 'any') ||
        (currentOptions?.ooss_id && currentOptions?.ooss_id !== 'any')
      }>
      <Row>
        {
          delegaciones &&
          <Col md="3">
            <FormGroup>
              <Label>Delegaciones</Label>
              <Input
                type="select"
                onChange={ (event: ChangeEvent<HTMLInputElement>) => changeFilter('delegation', event.target.value) }
                value={ currentOptions?.delegation ? currentOptions.delegation : 'any' }
              >
                <option key="any" value="any">Todas</option>
                {
                  Object
                    .entries(delegations)
                    .map(([key, value]) => <option key={key} value={key}>{value}</option>)
                }
              </Input>
            </FormGroup>
          </Col>
        }
        <Col md="3">
          <FormGroup>
            <label>Obra Social</label>
            <Input
              type="select"
              onChange={ (event: ChangeEvent<HTMLInputElement>) => changeFilter('ooss_id', event.target.value) }
              value={ currentOptions?.ooss_id ? currentOptions.ooss_id : 'any' }
            >
              <option value="any">Todas</option>
              {
                insurers && Array.isArray(insurers)
                  ? insurers.map(insurer => (
                    <option key={'insurer_' + insurer.id} value={ insurer.id }>{ insurer.codigo }</option>
                  ))
                  : null
              }
            </Input>
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <label>Roles</label>
            <Input
              type="select"
              onChange={ (event: ChangeEvent<HTMLInputElement>) => changeFilter('rol', event.target.value) }
              value={ currentOptions?.rol ? currentOptions.rol : 'any' }
            >
              <option value="any">Todas</option>
              {
                roles && Array.isArray(roles)
                  ? roles.map(rol => (
                    <option key={'rol_' + rol.id} value={ rol.id }>{ rol.name }</option>
                  ))
                  : null
              }
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </CollapsibleCard>
  );
}
