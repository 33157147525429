import React, { useState } from 'react'
import { Card, CardHeader, Col, Input, InputGroup, Row } from 'reactstrap'

function UserSearch(props) {
  const { onSearch, currentQuery } = props

  const [query, setQuery] = useState(currentQuery)

  const buttonStyles = {
    border: '1px solid #cad1d7'
  }

  const resetSearch = () => {
    setQuery('')
    onSearch('')
  }

  return (
    <Card className="shadow mb-3">
      <CardHeader>
        <Row>
          <Col>
            <InputGroup>
              <Input
              type="text"
              placeholder="Buscar..."
              onChange={ event => setQuery(event.target.value) }
              onKeyUp={ event => event.key === 'Enter' && onSearch(query) }
              value={ query } />
              {
                currentQuery !== ''
                ? <button className="btn btn-outline-secondary" style={ buttonStyles } onClick={ () => resetSearch() }>
                    <i className="fas fa-times"></i>
                  </button>
                : null
              }
              <button className="btn btn-outline-secondary" style={ buttonStyles } onClick={ () => onSearch(query) }>
                <i className="fas fa-search"></i>
              </button>
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
    </Card>
  )
}

export default UserSearch
