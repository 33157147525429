import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useAlertPlus } from 'hooks';
import AlertModal from 'components/AlertModal';
import { Insurer } from '@cokiba/types';
import DeliveryNoteDetails from 'components/Reports/DeliveryNoteDetails';
import MessagesList from 'components/Reports/MessagesList';
import ManagerStats from 'components/Reports/ManagerStats';
import RequestsStats from 'components/Reports/RequestsStats';
import { ApiService } from 'services';
import { useUser } from 'context';
import ExpirationsGraph from 'components/Reports/ExpirationsGraph';
import DeliveryNoteSummary from 'components/Reports/DeliveryNoteSummary';

export default function Reports() {
  const user = useUser();

  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');
  const [loading, setLoading] = useState(true);

  const [insurers, setInsurers] = useState<Insurer[]>();

  useEffect(() => {
    ApiService.getAll('insurers', { onlyActive: 1, limit: -1 })
      .then(res => {
        if (!res.status || res.status !== 'ok') {
          return;
        }

        setInsurers(res.payload);
      })
      .catch(err => {
        alert('Ha ocurrido un error al obtener el listado de Obras Sociales, ' +
          'no se podrá filtrar por obra social.');
        console.error(err);
      });
  }, []);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            {
              user.is(1)
                ? <MessagesList
                    alert={ alert }
                    loading={ loading }
                    setLoading={ setLoading }
                    insurers={ insurers }
                  />
                : null
            }
            <ExpirationsGraph />
            {
              user.is(1)
                ? <>
                    <RequestsStats />
                    <ManagerStats />
                    <DeliveryNoteSummary />
                    <DeliveryNoteDetails
                      alert={ alert }
                      loading={ loading }
                      setLoading={ setLoading }
                    />
                  </>
                : null
            }
          </Col>
        </Row>
        <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
      </Container>
    </>
  );
}
