import React from 'react';

import { FormGroup, Row, Col } from 'reactstrap';
import { ParsedRequest } from 'views/Requests/Edit';

interface PracticeDataProps {
  request: ParsedRequest;
}

export default function PracticeData(props: PracticeDataProps) {
  const { request } = props;

  return (
    <>
      <div className="pl-lg-4">
        <Row>
          <Col sm={6}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Obra social
              </label>
              <p>{request.ooss ? request.ooss.codigo : '-'}</p>
            </FormGroup>
          </Col>
          <Col sm={6} className={ request?.numero_aprobacion ? 'd-block' : 'd-none' }>
            <label className="form-control-label">
              { request?.ooss_id == 66 && request?.aprobacion_previa ? 'N° Formulario 4' : 'Número de Aprobación' }
            </label>
            <p>
              { request.numero_aprobacion }
              {
                request.ooss_id === 36 && request?.numero_aprobacion.length > 10
                  ? <small className="form-text text-red">
                      El número de aprobación excede el máximo permitido por la OOSS
                    </small>
                  : null
              }
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Tipo de práctica
              </label>
              <p>{request.tipo_practica || '-'}</p>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Práctica adicional
              </label>
              <p>{request.practica_adicional || '-'}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Prestación es de discapacidad
              </label>
              <p>{request.prestacion_discapacidad ? 'Sí' : 'No'}</p>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Prestación es Cupo Extendido
              </label>
              <p>{request.extended_quota ? 'Sí' : 'No'}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <label className="form-control-label">
                Prestación es para refacturación
              </label>
              <p>{request.es_refacturacion ? 'Sí' : 'No'}</p>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-first-name"
              >
                Cantidad de sesiones
              </label>
              <p>{
                request.sessionDates && Array.isArray(request.sessionDates)
                  ? Array.from(request.sessionDates).length
                  : request.cantidad_sesiones
              }</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
        </Row>
        <Row className={ request.exceptional ? 'd-block' : 'd-none' }>
          <Col>
            <FormGroup>
              <label className="form-control-label">Excepcionalidad</label>
              <p>{ request.exceptional_text }</p>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}
