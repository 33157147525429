import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';

import AuthNavbar from 'components/Navbars/AuthNavbar';
import AuthFooter from 'components/Footers/AuthFooter';

export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();

  const [pathname, setPathname] = useState('');

  useEffect(() => {
    setPathname(location.pathname);
    document.body.classList.add('bg-default');

    return () => {
      document.body.classList.remove('bg-default');
    };
  }, [location.pathname]);

  return (
    <>
      <div className="main-content">
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">
                    {
                      pathname === '/auth/change-password'
                        ? 'Cambio de contraseña'
                        : 'Bienvenido a COKIBA Backoffice!'
                    }
                  </h1>
                  {
                    pathname === '/auth/change-password'
                      ? <h2>
                          <span className='clickable' onClick={ () => navigate(-1) }>
                            <i className='fa fa-arrow-left'></i> Volver
                          </span>
                        </h2>
                      : null
                  }
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Outlet />
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
}
